import React, {Dispatch} from 'react';
import * as FUI from './style';
import {checkFormat} from "./Stories";

interface ISlider {
    images: any,
    current: number,
    setSlider: Dispatch<boolean>,
    setCurrent: Dispatch<number>
}
export const Slider = ({images, current, setSlider, setCurrent}: ISlider) => {
    return  (
        <FUI.SliderBlock>
            <FUI.SliderContent>
                <div>
                    <FUI.RightButtonsBlock>
                        <span style={{marginTop: "10px"}}></span>
                        <img src={"/images/CaretLeftWhite.svg"} alt={"left"}
                             onClick={() => {
                                 current !== 0 ? setCurrent(current-1) : setCurrent(images.length - 1)
                             }}/>
                        <span></span>
                    </FUI.RightButtonsBlock>
                    {checkFormat(images[current]?.url) === 'image' ?
                        <FUI.ImageInSlider src={images[current]?.url} alt={"image"}/>
                        :
                        <video autoPlay={true} controls={true}>
                            <source src={images[current]?.url}/>
                        </video>
                    }
                    <FUI.RightButtonsBlock>
                        <img src={"/images/WhiteClose.svg"} alt={"close"}
                             style={{marginTop: "10px"}}
                             onClick={() => setSlider(false)}/>
                        <img src={"/images/CaretLeftRight.svg"} alt={"right"}

                             onClick={() => {
                                 current === images.length - 1? setCurrent(0) : setCurrent(current+1)
                             }}/>
                        <span></span>
                    </FUI.RightButtonsBlock>
                </div>
            </FUI.SliderContent>
        </FUI.SliderBlock>
    )
}