import React, {useEffect, useState} from 'react';
import * as PUI from "../../components/Profile/ProfileStyles";
import {otherApi} from "../../services/Other";
import {AboutBlock} from "../../components/Profile/ProfileStyles";
import * as UI from "../../components/Profile/ProfileStyles";
import {ReadBlog} from "../../features/ReadBlog";
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import {Spinner} from "../../components/Spinner";

export const BlogItems = ({showBlog, setShowBlog}: any) => {

    const [ size, setSize ] = useState<number>(8)
    const { data: blogList, isLoading } = otherApi.useGetBlogListQuery(size, {
        refetchOnMountOrArgChange: true
    })
    const navigate = useNavigate()
    const [ selectedBlog, setSelectedBlog ] = useState<any>(Cookies.get('blog_slug') || 0)

    useEffect(() => {
        if (window.location.pathname.slice(0, 7) === '/blogs/') {
            setShowBlog(true)
        }
    }, [blogList]);
    if (isLoading) return <Spinner/>
    return (
        <PUI.Content>
            <PUI.Container>
                {showBlog ?
                <PUI.BlogContainer>
                    <ReadBlog id={selectedBlog} setShowBlog={setShowBlog}/>
                </PUI.BlogContainer>
                :
                    <PUI.BlogContainer>
                        <AboutBlock>
                            <h2>Blog</h2>
                        </AboutBlock>
                        <PUI.ListBlog>
                            {blogList?.data?.data.map((blog: any, index: number) => {
                                return (
                                    <PUI.BlogItem key={index}>
                                        <div>
                                            <img src={`https://myloveexpress-backend.ru/storage/${blog?.preview}`} alt={"BlogImage"}/>
                                            <h4 dangerouslySetInnerHTML={{__html: blog.title}}/>
                                            <h6 dangerouslySetInnerHTML={{__html: blog.content}}/>
                                            <button type={"button"}
                                                    onClick={() => {
                                                        console.log(blog.slug)
                                                        Cookies.set('blog_slug', blog.slug)
                                                        setSelectedBlog(blog.slug)
                                                        setShowBlog(!showBlog)
                                                        navigate(`/blogs/${blog.id}`)
                                                    }}
                                            >
                                                More <img src={"/images/YellowRight.svg"} alt={"right"}/>
                                            </button>
                                        </div>
                                    </PUI.BlogItem>
                                )
                            })}
                        </PUI.ListBlog>
                        <UI.LoadMore onClick={() => setSize((prevState) => prevState + 8)}
                                     style={{margin: "0 auto"}}>
                            Load more<img src={"/images/YellowRight.svg"} alt={'right'}/>
                        </UI.LoadMore>
                    </PUI.BlogContainer>}
            </PUI.Container>
        </PUI.Content>
    )
}