import React, {Dispatch, useEffect, useState} from 'react';
import * as UI from "./ProfileStyles";
import {TopPanel} from "./TopPanel";
import {IncreaseBlocks} from "../../features/IncreaseBlocks";
import {GoogleMapAPI} from "../../features/GoogleMapAPI";
import {BlogList} from "../../features/BlogList";
import {userApi} from "../../services/UserService";
import {UserLittle} from "./UserLittle";
import {Spinner} from "../Spinner";

interface ITravelProps {
    size: number,
    profile: any,
    setSelectedTab: any,
    setCurrentActive: any,
    selectedLocation: any,
}
export const Travels = ({size, profile, setCurrentActive, setSelectedTab, selectedLocation}: ITravelProps) => {

    const [myLocation, setMyLocation] = useState<any>('')
    const { data: increaseData, isLoading } = userApi.useGetTravelsQuery(myLocation.finalCountry ? myLocation : undefined, {
        refetchOnMountOrArgChange: true,
    })
    // const increaseData = [
    //     {
    //         name: "Leenia Noria",
    //         img: "increaseDataLeenia.png",
    //         favorites: "Friends, Soulmate, Job",
    //         location: "Seattle, WA, US",
    //         old: "19 Years",
    //         about: "I’m delighted you found me and I’m sure we’ll share an..."
    //     },
    //     {
    //         name: "Alexia Wang",
    //         img: "increaseDataAlex.png",
    //         favorites: "Friends, Soulmate, Job",
    //         location: "Seattle, WA, US",
    //         old: "19 Years",
    //         about: "I’m delighted you found me and I’m sure we’ll share an..."
    //     },
    // ]
    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        // @ts-ignore
        document.scrollingElement.scrollTop = 0;
        // mainContentRef.current.scrollTop = 0;
    }, []);
    useEffect(() => {
        if (selectedLocation) {
            const findCountry = selectedLocation?.full_name?.lastIndexOf(',')
            const finalCountry = selectedLocation?.full_name?.slice(findCountry+2)
            console.log(finalCountry, selectedLocation?.name)
            setMyLocation({finalCountry, city: selectedLocation?.name})
        }
    }, [selectedLocation]);
    if (isLoading) return <Spinner/>
    return (
        <UI.Content>
            <UI.Container>
                <UI.FindTravelImage>
                    <h1>Find a couple and <br/> travel together</h1>
                    <h3>Romantic dates<br/> around the world</h3>
                </UI.FindTravelImage>
                <GoogleMapAPI profile={profile?.data} increaseData={increaseData} setCurrentActive={setCurrentActive} setSelectedTab={setSelectedTab}/>
                <UI.IncreaseBlock>
                    {/*<IncreaseBlocks content={increaseData} type={"profile"} size={size}/>*/}
                </UI.IncreaseBlock>
                {isLoading ?
                    <span>Loading</span>
                    :
                    <>
                        <UI.UserList>
                            {increaseData?.data.map((item: any, index: number) => {
                                return (
                                    <UserLittle user={item} index={index}
                                                key={index} setSelectedTab={setSelectedTab} setCurrentActive={setCurrentActive}/>
                                )
                            })}
                        </UI.UserList>
                        {/*<UI.LoadMore onClick={() => setSize((prevState) => prevState + 8)}*/}
                        {/*             style={{margin: "0 auto"}}>*/}
                        {/*    Load more<img src={"/images/YellowRight.svg"} alt={'right'}/>*/}
                        {/*</UI.LoadMore>*/}
                    </>
                }
                <BlogList/>
            </UI.Container>
        </UI.Content>
    )
}