import React, {Dispatch, useEffect, useState} from 'react'
import * as PUI from "./ProfileStyle"
import * as UI from "../../style";
import Cookies from "js-cookie";
import {SuccessNotify} from "../../Notifications";
import {useNavigate} from "react-router-dom";
import {profileApi} from "../../services/Profile";



export type TMenuItemTitle = "Travels" | "Search" | "My profile" | "Messages" | "Guests" | "Blog" | "Support"
export interface IMenuItem {
    icon: string,
    iconActive: string,
    title: TMenuItemTitle
}

interface IProps {
    setSelectedTab: Dispatch<TMenuItemTitle>,
    setIsAuth: Dispatch<boolean>,
    selectedTab: TMenuItemTitle,
    currentActive: any,
    setCurrentActive: any,
    profile: any,
    setShowBlog: any,
    showBlog: any,
    notificationPool: any,
    countMessages: any
}
export const menuItems: IMenuItem[] = [
    {icon: "/images/TravelsInactive.svg", iconActive: "/images/TravelsActive.svg", title: "Travels"},
    {icon: "/images/Search.svg", iconActive: "/images/SearchActive.svg", title: "Search"},
    {icon: "/images/MyProfile.svg", iconActive: "/images/MyProfileActive.svg", title: "My profile"},
    {icon: "/images/Messages.svg", iconActive: "/images/MessagesActive.svg", title: "Messages"},
    {icon: "/images/Guests.svg", iconActive: "/images/GuestsActive.svg", title: "Guests"},
    {icon: "/images/Blog.svg", iconActive: "/images/BlogActive.svg", title: "Blog"},
    {icon: "/images/Support.svg", iconActive: "/images/BlogActive.svg", title: "Support"}
]
export const SideBar = ({setSelectedTab, setIsAuth, selectedTab, currentActive, setCurrentActive, profile, setShowBlog, showBlog, notificationPool, countMessages}: IProps) => {
    const navigate = useNavigate()

    const userId = Cookies.get("userId")
    const [count, setCount ] = useState(0)

    const styleCounter = {
        filter: "drop-shadow(0px 2px 5px rgba(254, 239, 52, 0.50))",
        fontSize: "16px",
        background: "yellow",
        width: "31px",
        height: "31px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50px"
    }

    const logOut = () => {
        Cookies.remove('token_mle')
        setIsAuth(false)
        SuccessNotify('Bye!')
        navigate('/')
    }

    useEffect(() => {
        setCount(notificationPool?.filter((item: any) => item?.read_at === null).length)
    }, [notificationPool]);
    return (
        <PUI.LeftSideBar>
            <UI.MenuItem to={"/"} style={{zIndex: 10000}}>
                <UI.Logo src={"/images/logo.svg"} alt="logo"/>
            </UI.MenuItem>
            <PUI.SideBarMenu>
                {menuItems.map((item: IMenuItem, index: number) => {
                    return (
                        <PUI.SideBarMenuItem key={index}
                                             onClick={() => {
                                                 setSelectedTab(item.title)
                                                 setCurrentActive(index)
                                                 localStorage.setItem("profile_tab_index", index.toString())
                                                 if (item.title === 'Blog') navigate("/blog")
                                                 else navigate("/profile")
                                                 localStorage.setItem('profile_tab', item.title)
                                             }}
                            active={+currentActive + 1}>
                            <img src={selectedTab === item.title ? item.iconActive : item.icon}
                                 style={{borderRadius: selectedTab === item.title ? "50px" : "0"}}
                                 alt={item.title}/>
                            <span style={{color: selectedTab === item.title ? "black" : "white"}}>{item.title}</span>
                            {item.title === 'Guests' && count > 0 ? <span style={styleCounter}>{count}</span> : ''}
                            {item.title === 'Messages' && countMessages > 0 ? <span style={styleCounter}>{countMessages}</span> : ''}
                        </PUI.SideBarMenuItem>
                    )
                })}
            </PUI.SideBarMenu>
            {profile?.my ?
                    profile?.fill[0] !== 100 ?
                    <PUI.SideBarBottom>
                        <PUI.ProfileVerified>
                            <h4>Your profile is not complete!</h4>
                            <p>
                                Fill in all the items in the profile and also pass verification.
                            </p>
                            <span>{profile?.fill[0]}%</span>
                            <PUI.LineInPercent percent={profile?.fill[0]}></PUI.LineInPercent>
                        </PUI.ProfileVerified>
                        <hr/>
                        <PUI.ShadowButton onClick={() => logOut()}>
                            <img src={"/images/WhiteLogOut.svg"} alt={"Go out"}/>
                            Go Out
                        </PUI.ShadowButton>
                    </PUI.SideBarBottom>
                    :
                        <PUI.SideBarBottom>
                            <hr/>
                            <PUI.ShadowButton onClick={() => logOut()}>
                                <img src={"/images/WhiteLogOut.svg"} alt={"Go out"}/>
                                Go Out
                            </PUI.ShadowButton>
                        </PUI.SideBarBottom>
                :
                <>
                    <PUI.SideBarBottom style={{width: "90%"}}>
                        <hr/>
                        <PUI.ShadowButton onClick={() => logOut()}>
                            <img src={"/images/WhiteLogOut.svg"} alt={"Go out"}/>
                            Go Out
                        </PUI.ShadowButton>
                    </PUI.SideBarBottom>
                </>
            }
        </PUI.LeftSideBar>
    )
}