import {SuccessNotify, ErrorNotify, DefaultNotify} from "../Notifications";

export const NotificationResponse = (data: any) => {
    console.log(data)
    if (data?.error) {
        ErrorNotify(data?.error?.data?.message)
    }
    else if (data?.success) {
        SuccessNotify(data?.success?.data?.message)
        return 'ok'
    }
    else if (data?.data?.success) {
        SuccessNotify(data?.data?.message)
    }
    else DefaultNotify('Undefined error. Please try later.')
    return 'ok'
}