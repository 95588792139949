import React from 'react';
import * as UI from './style'

export const CustomInput = ({register, name, label, data, disabled}: any) => {

    return (
        <UI.CustomInputElem>
            <label>{label}</label>
            <input type={"text"}
                   disabled={disabled}
                   placeholder={data}
                    {...register(name, {
                        value: data
                    })}/>
        </UI.CustomInputElem>
    )
}