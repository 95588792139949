import React from 'react';

export const AboutUs = () => {

    return (
        <div itemType="http://schema.org/Organization" style={{padding: "20px"}}>
            <a itemProp="url" href="https://myloveexpress.com"></a>
            <a href="/">
                <img itemProp="logo" src="/images/logo.svg" alt="logo" title="logo" className="logomain" style={{width: "200px"}}/>
            </a>
            <h1>Terms and Conditions</h1>
            <p>1. General terms and conditions	</p>

            <p>1.1. You must be over 18 years of age to register as a member or use this site. Membership in the service is invalid where it is prohibited. By using and / or browsing this site, you represent and warrant that you have the right, authority and ability to enter into this Agreement and comply with all its terms and conditions.	</p>

            <p>1.2. This site is intended for your entertainment and educational purposes and does not intend to support immoral interests.	</p>

            <p>1.3. You hereby represent and warrant to the Company that: (a) you have full authority to enter into and comply with the terms and conditions in accordance with this agreement ; (b) your use of this Site will not violate any copyright, trademark rights, publicity rights or any other legal right of any third party; (c) you will comply with all applicable laws when using the Website and participation in all other activities arising from the Terms associated with them, including, among other things, contacts with other participants and / or visitors of the Website; (d) you own or otherwise have all rights necessary to license the content you submit and that the posting and use of your content by Company will not infringe or violate the rights of third parties.	</p>

            <p>2. Fees and non-commercial use by members and / or spectators	</p>

            <p>2.1. The Site and related services and / or subscription fees and duration of services were provided to you during registration and may change from time to time. We reserve the right to terminate your access to the site for any reason or no reason and may terminate your membership by sending you a notification to the email address you provided in your membership application or to another email address that you may later provide to us. If we terminate your membership due to your violation of this Agreement, you are not entitled to a refund of any unused subscription fees.	</p>

            <p>2.2. Due to the fact that some services may be charged for, the participant or user of the Site agrees to regular billing for services / access provided on an ongoing basis. This repetition of invoices continues until the participant or user cancels the service. The participant or user is responsible for the cancellation of the service and payment.	</p>

            <p>2.3. We reserve the right to change the price for the services provided at any time. Members or users who have already used our services prior to any price changes for the services provided will not be subject to the new prices, unless they re-join the Site.	</p>

            <p>2.4. We may use third-party companies to process Site payments on behalf of the Company. These companies are fully responsible for your personal data filled in during the payment process in accordance with their Privacy Policy.	</p>

            <p>2.5. This Site is intended only for the personal use of individual participants and viewers and cannot be used for any commercial purposes. Organizations and / or companies may not become members of this Site and may not use the service or the Site for any purpose, including, but not limited to: sending unsolicited commercial emails to members, advertising other websites and services on this site's chat rooms, or using a member's personal identification information for commercial purposes. Illegal and / or unauthorized use of the site, including unauthorized creation or linking to the site or sending unsolicited commercial emails, will be investigated and appropriate legal action will be taken, including civil, criminal, without limitation, and injunctive relief and / or termination of membership at the Company's sole discretion. To prevent the sending of unsolicited commercial email or chat messages to its members, the Company may take reasonable measures, including limiting the number of chat messages sent or received by a member, as well as electronic filtering.	</p>

            <p>3. Termination	</p>

            <p>3.1. The Company reserves the right to terminate your access to the Site for any reason or no reason (such reason may include your violation of these Terms). The Company may terminate your membership after sending you a notification to the email address you provided in your membership application, or to another email address that you can provide to us later or in any other way at your discretion. If the Company terminates your membership on the Site because you have violated these Terms, you will not, in the Company's sole discretion, be entitled to a refund of any unused subscription fees, and thus you agree to waive any claim for such refund of fees.	</p>

            <p>4. Responsibility of participants and / or viewers	</p>

            <p>4.1. This site is a social platform. The services provided by the Company do not include introducing users to each other. This site is simply a place for participants and / or viewers to learn about each other and, if they want, arrange meetings with each other. The Company reserves the right in actual contact between participants and / or viewers. As a result, the Company does not control the behavior of participants and / or viewers, as well as the accuracy or accuracy of the information that participants and / or viewers post on the Site.	</p>

            <p>4.2. The Company cannot and does not confirm the alleged identity of the participants and / or viewers. Although the Company provides tools designed to assist you, you are responsible for determining the identity and suitability of others with whom you communicate through this Site. The Company does not support any persons who use or register for the services provided by the Company. The Company does not investigate the reputation of participants and / or viewers, behavior, morals, criminal history and does not check the information that such participants and / or viewers may provide to the Site. You should communicate directly with potential hosts and guests using the tools available on the Site, and take the same precautions that you would normally take when meeting a stranger in person for the first time.	</p>

            <p>4.3. You are solely responsible for your interaction with other members and / or visitors of this Site. The Company will not be liable for any damage or damage resulting from your interaction with other members and / or visitors to this Site. The Company reserves the right, but is not obligated, to monitor interactions between you and other members and / or visitors to this Site and to take any other action in good faith to restrict access to or access to any material that the Company or other Members and / or viewers of this Site may find obscene, depraved, dirty, violent, harassing or otherwise objectionable.	</p>

            <p>4.4. Taking into account that this Site is just a place, in the event of a dispute with other participants and / or visitors to this Site, you release the Company and its officers, directors, members, employees, agents and affiliates from claims, claims and losses (actual and indirect) of any kind and nature, known and unknown, suspected and unsuspected, disclosed and undisclosed, arising from or in any way related to such disputes.	</p>

            <p>4.5. Personal meetings held by groups of interested participants and / or viewers are not sponsored or organized by the Company. Members who organize meetings should make it clear in their announcements, invitations and signs that their meetings have not been organized or approved and are not being conducted by the Company. The Company cannot guarantee the quality, organization or security of any face-to-face meetings (whether sponsored or not). Participants attend meetings at their own risk.	</p>

            <p>4.6. This site is created for non-sexual purposes. The Site may not be used to provide sexual or erotic services by a member or user of the Site. The Site may not be used as a tool for sexual or erotic offers.	</p>

            <p>5. Privacy	</p>

            <p>5.1. This site is intended to allow participants and / or viewers to post photos and information about themselves for public viewing and commenting. Accordingly, by submitting your photo and / or any other personal information, you waive any privacy expectations that you have regarding the use of such likeness or personal information. If you do not want your photo or information about you to be viewed or shared with others, you should not use the Site. The Company may collect certain personal information from you that the Company does not publish on the Site. The Company does not intentionally share this information with independent third parties without your consent, but the Company reserves the right to share such information without your consent in order to prevent an emergency, protect or enforce our rights, protect or enforce the rights of a third party, or in response to a court order or subpoena as required or permitted by law. The Company also provides this personal information to third-party service providers who help maintain the Site and provide information and services to you and other members and / or visitors to the Site.	</p>

            <p>5.2. By submitting any content (including your photo) to the Site, you grant the Company a perpetual, worldwide, non-exclusive, free license to use, reproduce, display, perform, adapt, modify, distribute, receive and use; distribute and promote such content in any form, in all media that is now known or created in the future, and for any purpose.	</p>

            <p>5.3. You are solely responsible for any content that you submit, publish or transmit through the Site. You may not post or provide any content that:	</p>

            <p>5.3.1. is defamatory;	</p>

            <p>5.3.2. may demean any ethnic, racial, sexual or religious group by stereotyping or otherwise;	</p>

            <p>5.3.3. exploits images or images of people under the age of 18;	</p>

            <p>5.3.4. depicts the use of illegal drugs;	</p>

            <p>5.3.5. uses abusive or offensive language or images;	</p>

            <p>5.3.6. characterizes violence as acceptable, glamorous or desirable;	</p>

            <p>5.3.7. provides a link to any commercial sites;	</p>

            <p>5.3.8. contains any of your personal contact information, except as required or required as part of your registration on this Site.	</p>

            <p>5.4. The Company is not obligated to publish any content from you or anyone else. In addition, the Company may, in its sole discretion, edit, delete or delete any content that you post or submit.	</p>

            <p>5.5. When using the site, you may encounter content from other users or third parties, either on this site or through links to third-party websites. The Company does not control and is not responsible for the content of third parties, including materials that may be misleading, incomplete, erroneous, offensive, obscene or otherwise objectionable. You must assess and bear all risks associated with third-party content, including, but not limited to, the profiles of other members and / or viewers of this Site.	</p>

            <p>5.6. You will select a username and password as part of the registration process. You are solely responsible for the confidentiality and use of your username and password. You must: (a) log out of your account at the end of each session on the Site; (b) immediately notify the Company of any unauthorized use of your username and password or any other security breach.	</p>

            <p>6. Refund and Cancellation Policy	</p>

            <p>6.1. Cancellation of the reservation can be carried out at any time without a refund. Unless otherwise specified in this section, you may cancel your registration or subscription to any services at any time during the term of such registration or subscription or any renewal period by going to Services &gt; Cancel Subscription.	</p>

            <p>7. Pricing and payment processing	</p>

            <p>7.1. All payments are repeated. You can cancel your subscription at any time. The participant or user is responsible for the cancellation of the service and payment. However, refunds for paid subscriptions are not made in accordance with the terms of the Refund and Cancellation Policy (Section 6 of the Terms).	</p>

            <p>7.2. Membership is available in 3 different plans:	</p>

            <p>7.2.1. 6-month membership: $ 237 for 180 days.	</p>

            <p>7.2.2. 3-month membership: $ 158 for 90 days.	</p>

            <p>7.2.3. 1-month membership: $ 79 for 30 days.	</p>

            <p>7.3. We reserve the right to change the price for the services provided at any time. Members or users who have already used our services prior to any price changes for the services provided will not be subject to the new prices, unless they re-join the Site. Members or users who have already used our services before any price changes for the services provided may be subject to new prices if they are informed of the price change in advance.	</p>

            <p>8. Information that  we collect	</p>

            <p>8.1. When registering on this site, you must you provide us with certain personally identifiable information, including but not limited to your name, address, email address, telephone number, and credit card and bank information. This information is used to contact the user about the services on our site in which he has expressed interest.	</p>

            <p>8.1.1. When registering, we will give you the opportunity to provide us with additional information, such as your interests, hobbies, occupation, and more.	</p>

            <p>8.1.2. In some cases, we may ask for additional information for security reasons or to provide you with specific services.	</p>

            <p>8.1.3. After registering, you can visit your profile at any time to add or remove additional personal information about yourself. You can add basic information about yourself, such as information about your hometown, family, relationships, or other specific views. You can also add other information about yourself, including your activities, interests, contact information, as well as additional information about your education and profession.	</p>

            <p>8.1.4. If the user's personal information changes (for example, your zip code) or if the user no longer wishes to use our services, we will try to provide a way to correct, update or delete the personal information of that user provided to us. This can usually be done on the user information page or by contacting the administrators of our website.	</p>

            <p>8.1.5. We reserve the right to change certain settings of your account regarding subscription to news, banners, product offers and other means of marketing the content and services of this site under certain circumstances related to marketing and advertising activities.	</p>

            <p>8.2. We may store information about transactions or payments that you make on this site. However, we will only store your payment source account number with your consent.	</p>

            <p>8.3. Cookie information. Cookies are small pieces of data that are stored by the user's web browser on the user's hard drive for a specified period of time. Cookies can record information that a user accesses on one web page in order to facilitate subsequent interaction with that website by the same user, or use this information to optimize the user's transactions on linked web pages.	</p>

            <p>8.3.1. Cookies make it easier for the user to navigate from web page to web page and make transactions over the Internet.	</p>

            <p>8.3.2. We use "cookies" to make this site easier for you to use, to improve advertising and to protect you and this site.	</p>

            <p>8.3.3. This site also uses cookies to store and sometimes track information to make your online experience easier and more personalized.	</p>

            <p>8.3.4. Most major web browsers are set to initially accept cookies, but you can change your computer settings to warn you when downloading cookies, or to disable the ability to download cookies by third parties. If you choose to reject all cookies, some areas of this site may not function properly.	</p>

            <p>8.4. We reserve the right to use your IP address to locate and publicly share your location.	</p>

            <p>8.5. If you send us personal correspondence, such as emails or letters, or if other users of this site or third parties send us correspondence about your activities or publications on this site, we have the right to collect such information in a file that only authorized persons have access to. our employees or representatives. We may also collect comments about you made by other users of the site.	</p>

            <p>8.6. This site may contain links to other sites. You should be aware that we are not responsible for the privacy practices of such other sites. We encourage our users to be aware of when they leave our site and to read the privacy statements of each website that collects personally identifiable information. This privacy statement applies only to information collected by this website.	</p>

            <p>9. How we use the information we collect	</p>

            <p>9.1. We use the information we collect to provide a safe, effective, and personalized experience. The primary purpose of using the information collected is to provide you with our services and features, measure and improve those services and features, and provide you with customer support.	</p>

            <p>9.2. We use the information we collect internally to improve our marketing efforts, analyze site usage, improve our content and product offerings, and customize site content and layout. This use enables us to improve this site and better tailor your online experience.	</p>

            <p>9.3. We may use the information we collect to provide information that, in some cases, is targeted to your interests, such as banners and promotions.	</p>

            <p>9.4. We may also send you periodic information updates by email, providing phone numbers and / or all other contact sources.	</p>

            <p>9.5. We may use the information we collect to resolve disputes, troubleshoot and enforce any agreements, policies, and rules that govern your use of this site.	</p>

            <p>10. Disclosure of collected information	</p>

            <p>10.1. We do not sell or rent your personal information to third parties for marketing purposes without your permission. However, we share the collected information with our service providers (e.g. credit card processing companies, billing services) as reasonably necessary or necessary to provide services on the site.	</p>

            <p>10.2. We disclose information collectively to advertisers and for other marketing and advertising purposes. However, in these situations, we do not disclose information in any form that can be used to personally identify you, such as your site account password, credit card number, and bank account number.	</p>

            <p>10.3. In response to mandatory requests from the government and / or third parties, we may disclose your personal information in accordance with a court order, subpoena and search warrant.	</p>

            <p>10.4. We reserve the right to disclose your personal information if we have a good faith belief that disclosure is necessary to protect our rights or to enforce our agreements, policies and rules that govern your use of the site, or to voluntarily cooperate with law enforcement agencies.	</p>

            <p>10.5. If ownership of all or substantially all of our business changes, we may transfer your information to a new owner so that the service can continue to operate. In such a case, your information will continue to be subject to the promises made in any pre-existing privacy policy.	</p>

            <p>11. Your use of member information	</p>

            <p>11.1. You may not use other members' information, whether personal or not, for commercial purposes, for spam, harassment, or illegal threats. We reserve the right to terminate the activities of those who abuse the information of other users or otherwise violate any user agreements, policies or rules governing the use of the Site.	</p>
            <p itemProp="name"><strong>
                MyLoveExpress - dating site</strong>
            </p>
            <div itemProp="address" itemType="http://schema.org/PostalAddress">
                <p itemProp="addressLocality"><strong>Address:</strong> USA, State California, City Chico, 2296 Bloomington Ave.</p>
            </div>
            <p itemProp="telephone"><strong>Phone:</strong> 8053772766</p>
        </div>
    )
}