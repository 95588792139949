import React from 'react';
import * as FUI from "./style";
import Stories from 'react-insta-stories';
import {CustomBlockStories} from "./style";
export const ShowStories = ({storiesArray, setShowStories, index}: any) => {
    // console.log(storiesUsers)
    // // const {data: currentStory, isLoading }
    // console.log(storiesUsers[0]?.stories[0]?.url)

    //
    // const delay = (milliseconds: number) => {
    //     console.log('ok', currentIndex)
    //     setTimeout(() => {
    //         upStory(currentIndex+1)
    //     }, milliseconds)
    // }
    // const upStory = (index: any) => {
    //     setCurrentIndex((prevState: number) => prevState +1)
    //     setCurrentStory(storiesArray[index])
    //     delay(2000)
    // }

    const burakHeading = {
        heading: "ssaas",
        subheading: "",
        profileImage: "sss"
    }
    const storyStyle = {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain",
        margin: "auto"
    }
    const progressStyle={
        color: "#FEEF34",
        background: "#FEEF34",
    }

    const testProgess = {
        height: "0",
        background: "none",
    }
    // @ts-ignore
    return (
        <FUI.ShowStoriesBlock>
            <FUI.StoriesList>
                {/*<img src={"/images/CaretLeftMini.svg"} alt={"left"}/>*/}
                <span></span>
                {/*// style={{backgroundImage: `url(${currentStory?.url})`}}*/}
                <Stories stories={storiesArray} width={"100%"} height={"100%"}
                         defaultInterval={5000}
                         keyboardNavigation={true}
                         currentIndex={index}
                         storyStyles={storyStyle}
                         // progressContainerStyles={testProgess}
                         // progressWrapperStyles={testProgess}
                    // @ts-ignore
                         progressStyles={progressStyle}
                         onAllStoriesEnd={() => setShowStories(false)}/>
                <FUI.CustomBlockStories>
                    <img src={"/images/WhiteClose.svg"} alt={"Close"}
                    onClick={() => setShowStories(false)}/>
                    <span></span>
                    {/*<img src={"/images/CaretRightMini.svg"} alt={"right"}/>*/}
                    <span></span>
                </FUI.CustomBlockStories>
            </FUI.StoriesList>
        </FUI.ShowStoriesBlock>
    )
}