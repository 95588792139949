import React from 'react';
import * as UI from "../components/Profile/ProfileStyles";

interface IBasicElem {
    label: string,
    name: string | number
}
export const BasicElement = ({label, name}: IBasicElem) => {
    return (
        <UI.BasicElement>
            <label>{label}</label>
            <h4>{name || 'No data'}</h4>
        </UI.BasicElement>
    )
}