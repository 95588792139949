import React, {useEffect, useId, useLayoutEffect, useState} from 'react';
import * as UI from "./ProfileStyles";
import {TopPanel} from "./TopPanel";
import {Trips} from "../../features/Trips";
import {Media} from "../../features/Media";
import {profileApi} from "../../services/Profile";
import {ProfileData, ProfileInterests} from "./ProfileData";
import {BlogList} from "../../features/BlogList";
import {IPopUpMedia, PopUpMedia} from "../../features/PopUpMedia";
import {TextArea} from "../../features/TextArea";
import {useForm} from "react-hook-form";
import Webcam from "react-webcam";
import {AddTrip} from "../../features/style";
import * as fs from "fs";
import {SuccessNotify} from "../../Notifications";
import moment from "moment";
import {useAppDispatch} from "../../app/hooks";
import {Spinner} from "../Spinner";

export interface ITrip {
    location: string,
    date_from: string,
    date_to: string,
    id: number | string,
    address: {
        city: string,
        country: string
    }
}
export const MyProfile = ({setShowModal, showModal, profile, isFetching,
                              refetch, showSettings, setShowSettings,
                              showPlannedTrips, setShowPlannedTrips, showSpecifyTrips,
                              setShowSpecifyTrips, userId, setShowMedia,
                        showMobileMenu, setShowSendMessage, needRefetch, setNeedRefetch,
                        needRefetchTrips, isLoading}: IPopUpMedia) => {
    const personalInformationId = useId()
    const purposeId = useId()
    const { register, handleSubmit } = useForm()
    const [ personalStatus, setPersonalStatus ] = useState<boolean>(false)
    const [ personalInformation, setPersonalInformation ] = useState<boolean>(false)
    const [ updatePersonal ] = profileApi.useUpdateProfileDataMutation()
    const [ webCamStatus, setWebCamStatus ] = useState<boolean>(false)
    const updatePersonalInformation = async (data: any) => {

        await updatePersonal({userId, data})
            .then(() => refetch())
            .then(() => SuccessNotify('Data is updated'))
            .then(() => setPersonalStatus(!personalStatus))
    }


    const dispatch = useAppDispatch()

    const [ verificationAccount ] = profileApi.useVerificationAccountMutation()

    const sendVerificationPhoto = async () => {
        const formData = new FormData();
        function b64toBlob(b64Data: any, contentType: any, sliceSize: any) {
            contentType = contentType || "";
            sliceSize = sliceSize || 512;
            let byteCharacters = atob(b64Data);
            let byteArrays = [];

            for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                let slice = byteCharacters.slice(offset, offset + sliceSize);

                let byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                let byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }
            return new File(byteArrays, "pot", { type: contentType });
        }
        // @ts-ignore
        const test = b64toBlob(imgSrc.slice(23), "image/jpeg", 512)
        // @ts-ignore
        const file = new File([test], "random.jpeg", {
            type: "image/jpeg"
        })

        // @ts-ignore
        formData.append("image", file)

        await verificationAccount({userId, formData})
            .then(() => setWebCamStatus(!webCamStatus))
            .then(() => SuccessNotify('Photo for verification uploaded'))
            .then(() => setImgSrc(null))
    }
    const webcamRef = React.useRef(null);
    const [imgSrc, setImgSrc] = React.useState(null);
    const capture = React.useCallback(() => {
        // @ts-ignore
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc);

    }, [webcamRef, setImgSrc]);
    const checkOnline = () => {
        const dateUtc = moment(profile?.data?.online).unix()
        const currentDate = Math.floor(Date.now() / 1000)
        if (currentDate - dateUtc > 12000) {
            const messageFromTimeZone = moment.utc(profile?.data?.online).local()
            // return `Last seen at ${messageFromTimeZone.format("D MMMM HH:mm")}`
            return messageFromTimeZone.fromNow()
        } else if (profile?.data?.online === null) {
            return 'Offline'
        } else {
            return 'Online'
        }
    }

    const [ showAboutVerify, setShowAboutVerify ] = useState(false)
    return (
        <UI.Content>
            {webCamStatus ? <UI.FixedForModalWeb>
                <UI.ModalWeb>
                    <div style={{width: '100%', display: "flex", justifyContent: "space-between"}}>
                        {showAboutVerify && <AddTrip type="button" onClick={() => setShowAboutVerify(false)} style={{marginLeft: "-5px"}}>
                            <img src={"/images/YellowRight.svg"} alt={"right"} style={{transform: "rotate(180deg)"}}/>
                            Back
                        </AddTrip>}
                        <img src={"/images/CloseSVG.svg"} alt={"close"}
                             style={{width: "24px"}}
                             onClick={() => setWebCamStatus(!webCamStatus)}/>
                    </div>
                    <UI.BlockModalWeb>
                        {/*// @ts-ignore*/}
                        {imgSrc ? <UI.NewPhoto src={imgSrc} alt={"Image"}/> :
                            <UI.BlockModalWeb>
                                {showAboutVerify ?
                                    <Webcam audio={false}
                                            ref={webcamRef}
                                            screenshotFormat="image/jpeg"
                                            height={"100%"}
                                            width={"100%"}/>
                                    :
                                    <div>
                                        <p style={{ margin: "0", fontWeight: "600", padding: "5px", fontSize: "1.2em"}}>Take a photo from the screen of the device you are using and upload it.</p>
                                        <p style={{color: "gray", padding: "5px"}}>
                                            This photo will be seen only by the site moderator when checking your profile
                                        </p>
                                        <img src={"/images/photo.png"} alt={'photo'}/>
                                        <p style={{color: 'gray', marginTop: "10px !important"}}>
                                            This photo is private, it will not appear in your profile
                                        </p>
                                    </div>
                                }
                        </UI.BlockModalWeb>}
                    </UI.BlockModalWeb>
                    {
                        showAboutVerify ?
                            <div>
                                {imgSrc ? <>
                                        <AddTrip type="button" onClick={() => sendVerificationPhoto()}>
                                            Send
                                            <img src={"/images/YellowRight.svg"} alt={"right"}/>
                                        </AddTrip>
                                        <AddTrip type="button" onClick={() => setImgSrc(null)} >
                                            New
                                            <img src={"/images/YellowPencil.svg"} alt={"right"}/>
                                        </AddTrip>
                                    </>
                                    :
                                    <>
                                        <AddTrip type="button" onClick={capture}>
                                            Create
                                            <img src={"/images/YellowRight.svg"} alt={"right"}/>
                                        </AddTrip>
                                    </>
                                }
                            </div>
                            :
                            <div>
                                <AddTrip type="button" onClick={() => setShowAboutVerify(!showAboutVerify)}>
                                    Make photo
                                    <img src={"/images/YellowRight.svg"} alt={"right"}/>
                                </AddTrip>
                            </div>
                    }
                </UI.ModalWeb>
            </UI.FixedForModalWeb> : <></>}
            <UI.Container>
                {!showMobileMenu ?
                    <>
                        <UI.TopBlock>
                            <UI.LeftTopBlock>
                                <div style={{display: "flex", width: "100%"}}>
                                    {!profile?.data.user ?
                                        <Spinner/>
                                        :
                                        <>
                                            {profile?.data.user.avatar ?
                                                <UI.ProfilePhoto src={profile?.data.user.avatar} alt={'Profile'}/>
                                                :
                                                <UI.ProfilePhoto src={'/images/NoAvatar.png'} alt={'Profile'}/>
                                            }
                                    <UI.AboutProfile>
                                        <UI.ProfileUser>
                                            <UI.UserDataTop>
                                                <UI.UserNameStatus>
                                                    <h3>{profile?.data?.user.name}</h3>
                                                    <h4>{checkOnline()}</h4>
                                                </UI.UserNameStatus>
                                                {profile?.data.my ?
                                                    <UI.AddStory onClick={() => setShowModal(!showModal)}>
                                                        <UI.ProfilePhotoAddStory src={profile?.data.user.avatar ? profile?.data.user.avatar : '/images/NoAvatar.png'}
                                                                                 alt={"Add Story"}/>
                                                        <UI.ImgPlusAddStory src={"/images/YellowPlus.svg"} alt={"Plus"}/>
                                                    </UI.AddStory>
                                                    :
                                                    <></>}
                                            </UI.UserDataTop>
                                            <UI.ProfileInfo>
                                                <div>
                                                    <img src={"/images/location.svg"} alt={"Location"}/>
                                                    <p>{profile?.data?.information?.location?.city},&nbsp;
                                                        {profile?.data?.information?.location?.country}</p>
                                                </div>
                                                <div>
                                                    <img src={"/images/heart.svg"} alt={"Heart"}/>
                                                    <p> {profile?.data.user.age} Years</p>
                                                </div>
                                            </UI.ProfileInfo>
                                            {profile?.data.my ?
                                                <>
                                                    <UI.BlockSettings>
                                                        <UI.ButtonSettings type={"button"}
                                                                           onClick={() => setShowModal(!showModal)}>
                                                            <img src={"/images/MediaManager.svg"} alt={"Media"}/>
                                                            Media Manager
                                                        </UI.ButtonSettings>
                                                        <UI.ButtonRight src={"/images/GrayRight.svg"} alt={"Right"}/>
                                                    </UI.BlockSettings>
                                                    <UI.BlockSettings style={{marginTop: "10px"}}>
                                                        <UI.ButtonSettings type={"button"}
                                                                           onClick={() => setShowSettings ? setShowSettings(!showSettings) : console.log('')}>
                                                            <img src={"/images/ProfileSettings.svg"} alt={"Media"}/>
                                                            Profile Settings
                                                        </UI.ButtonSettings>
                                                        <UI.ButtonRight src={"/images/GrayRight.svg"} alt={"Right"}/>
                                                    </UI.BlockSettings>
                                                    <UI.VerifiedStatus>
                                                        <img src={"/images/ClearRedWarning.svg"} alt={"Warning"}/>
                                                        <span>Get your profile verified now</span>
                                                    </UI.VerifiedStatus>
                                                </>
                                                :
                                                <UI.SendMessage type="button"
                                                                onClick={() => setShowSendMessage ?
                                                                    setShowSendMessage(true)
                                                                    :
                                                                    console.log('error')}>
                                                    Send a message
                                                    <img src={"/images/YellowRight.svg"} alt={"right"}/>
                                                </UI.SendMessage>}
                                        </UI.ProfileUser>
                                    </UI.AboutProfile>
                                        </>
                                    }
                                </div>

                                {profile?.data.my ?
                                    <UI.ButtonsBlockSettingsMobile>
                                        <UI.BlockSettingsMobile>
                                            <UI.ButtonSettings type={"button"}
                                                               onClick={() => setShowModal(!showModal)}>
                                                <img src={"/images/MediaManagerMobile.svg"} alt={"Media"}/>
                                                Media Manager
                                            </UI.ButtonSettings>
                                        </UI.BlockSettingsMobile>
                                        <img src={"/images/Line.svg"} alt={"Line"}/>
                                        <UI.BlockSettingsMobile>
                                            <UI.ButtonSettings type={"button"}
                                                               onClick={() => setShowSettings ? setShowSettings(!showSettings) : console.log('')}>
                                                <img src={"/images/SettingsMobile.svg"} alt={"Media"}/>
                                                Profile Settings
                                            </UI.ButtonSettings>
                                        </UI.BlockSettingsMobile>
                                    </UI.ButtonsBlockSettingsMobile>
                                    :
                                    <></>
                                }
                            </UI.LeftTopBlock>
                            <UI.BlockTrips>
                                <Trips image={'PlannedTrips'}
                                       name={'My planned trips'}
                                       type={"1"}
                                       showPlannedTrips={showPlannedTrips}
                                       setShowPlannedTrips={setShowPlannedTrips}
                                       userId={userId}
                                       profile={profile}
                                       needRefetchTrips={needRefetchTrips}/>
                                <Trips image={'WorldTrips'}
                                       name={'Specify the place'}
                                       type={"0"}
                                       showPlannedTrips={showSpecifyTrips}
                                       setShowPlannedTrips={setShowSpecifyTrips}
                                       userId={userId}
                                       profile={profile}
                                       needRefetchTrips={needRefetchTrips}/>
                            </UI.BlockTrips>
                        </UI.TopBlock>
                        <Media data={profile} setShowMedia={setShowMedia}/>
                        <ProfileData profile={profile?.data} userId={userId} setNeedRefetch={setNeedRefetch}/>
                        <ProfileInterests profile={profile?.data} userId={userId} setNeedRefetch={setNeedRefetch}/>
                        <UI.ProfileDataBlock>
                            <UI.FormDataProfile id={personalInformationId} onSubmit={handleSubmit(updatePersonalInformation)}>
                                <UI.TopBlockProfileData style={{marginRight: "30px"}}>
                                    <h3>Personal information</h3>
                                    <div>
                                        {personalStatus ?
                                            <UI.ButtonSave type={"submit"} form={personalInformationId}>
                                                Save
                                                <img src={"/images/YellowPencil.svg"} alt={"Edit Button"}/>
                                            </UI.ButtonSave>
                                            : <span></span>}
                                        {profile?.data.my ?
                                            <UI.CustomButtonForm
                                                onClick={() => setPersonalStatus(!personalStatus)}
                                                status={personalStatus} type={"button"}>
                                                {personalStatus ? 'Cancel' : 'Edit'}
                                                <img src={!personalStatus ? "/images/YellowPencil.svg" : "/images/YellowClose.svg"} alt={"Edit Button"}/>
                                            </UI.CustomButtonForm>
                                            :
                                            <></>}
                                    </div>
                                </UI.TopBlockProfileData>
                                <TextArea register={register} name={'about'}
                                          label={'How would you describe yourself?'}
                                          data={profile?.data.information.personal.about}
                                          my={profile?.data.my} status={personalStatus}/>
                                <TextArea register={register} name={'imagine_partner_meeting'}
                                          label={'How do you imagine your meeting with a partner?'}
                                          data={profile?.data.information.personal.imagine_partner_meeting}
                                          my={profile?.data.my} status={personalStatus}/>
                            </UI.FormDataProfile>
                        </UI.ProfileDataBlock>
                        <UI.ProfileDataBlock>
                            <UI.FormDataProfile id={purposeId} onSubmit={handleSubmit(updatePersonalInformation)}>
                                <UI.TopBlockProfileData style={{marginRight: "30px"}}>
                                    <h3>Purpose of using the site</h3>
                                    <div>
                                        {personalInformation ?
                                            <UI.ButtonSave type={"submit"} form={purposeId}>
                                                Save
                                                <img src={"/images/YellowPencil.svg"} alt={"Edit Button"}/>
                                            </UI.ButtonSave>
                                            : <span></span>}
                                        {profile?.data.my ?
                                            <UI.CustomButtonForm
                                                onClick={() => setPersonalInformation(!personalInformation)}
                                                status={personalInformation} type={"button"}>
                                                {personalInformation ? 'Cancel' : 'Edit'}
                                                <img src={!personalInformation ? "/images/YellowPencil.svg" : "/images/YellowClose.svg"} alt={"Edit Button"}/>
                                            </UI.CustomButtonForm>
                                            :
                                            <></>}
                                    </div>
                                </UI.TopBlockProfileData>
                                <TextArea register={register} name={'purpose'}
                                          label={'Are you looking for are travel partner or a long term relationship?'}
                                          data={profile?.data.information?.personal?.purpose}
                                          my={profile?.data?.my} status={personalInformation}/>
                            </UI.FormDataProfile>
                        </UI.ProfileDataBlock>
                        {profile?.data.my ?
                        <UI.ProfileDataBlock>
                            <UI.TopBlockProfileData style={{marginRight: "30px"}}>
                                <h3>Verification</h3>
                            </UI.TopBlockProfileData>
                            <UI.VerificationList>
                                <UI.VerificationButton color={"#39BB47"} onClick={() => setWebCamStatus(!webCamStatus)}>
                                    <img src={"/images/VerificationPhoto.svg"} alt={"Photo"}/>
                                    Verify uploading photo
                                </UI.VerificationButton>
                            </UI.VerificationList>
                        </UI.ProfileDataBlock>
                            :
                            <></>
                        }
                        <BlogList/>
                    </>
                    :
                    <></>}
            </UI.Container>
        </UI.Content>
    )
}