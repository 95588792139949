import React, {Dispatch, useEffect, useState} from 'react';
import * as UI from "./ProfileStyles";
import {TopPanel} from "./TopPanel";
import {Stories} from "../../features/Stories";
import {BlogList} from "../../features/BlogList";
import {userApi} from "../../services/UserService";
import {UserLittle} from "./UserLittle";

interface ISearch {
    setSelectedTab: any,
    setShowFilter: Dispatch<boolean>,
    ageFrom: number,
    ageTo: number,
    showMobileMenu: boolean,
    setCurrentActive: Dispatch<any>,
    heightFrom: any,
    heightTo: any,
    relation: any,
    smoke: any,
    zodiac: any,
    children: any,
    education: any
    gender: any

}
export const Search = ({setSelectedTab, setShowFilter,
                           ageFrom, ageTo, showMobileMenu,gender, setCurrentActive, education, heightFrom, heightTo, smoke, children, zodiac, relation}: ISearch) => {
    const [ size, setSize ] = useState<number>(8)
    const { data: users, isLoading, refetch } = userApi.useGetSearchUsersQuery({
        size, ageFrom, ageTo, education, heightFrom,
        heightTo, smoke, children, zodiac, relation
    })

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        // @ts-ignore
        document.scrollingElement.scrollTop = 0;
        // mainContentRef.current.scrollTop = 0;
    }, []);

    return (
        <UI.Content>
           <UI.Container>
               {showMobileMenu ?
                   <></>
                   :
                   <>
                       <Stories/>

                       <UI.ImageForModal>
                           <h1>Choose a partner <br/> and start a chat</h1>
                           <h3>Your soulmate is waiting for you</h3>
                           <div>
                               <h5>Filter search</h5>
                               <img src={"/images/YellowFilterSearch.svg"} alt={"Search Icon"} onClick={() => setShowFilter(true)}/>
                           </div>
                       </UI.ImageForModal>
                       {isLoading ?
                           <span>Loading</span>
                           :
                           <>
                               { users?.data.data.length > 0 ?
                                       <UI.UserList>
                                           {users?.data.data.map((item: any, index: number) => {
                                               return (
                                                   <UserLittle user={item} index={index}
                                                               key={index} setSelectedTab={setSelectedTab} setCurrentActive={setCurrentActive}/>
                                               )
                                           })}
                                       </UI.UserList>
                                   :
                                   <h1>Users not found, try to change the filters</h1>
                               }
                               <UI.LoadMore onClick={() => setSize((prevState) => prevState + 8)}
                               style={{margin: "0 auto", marginTop: "10px"}}>
                                   Load more<img src={"/images/YellowRight.svg"} alt={'right'}/>
                               </UI.LoadMore>
                           </>
                       }

                       <BlogList/>
                   </>}
           </UI.Container>
        </UI.Content>
    )
}