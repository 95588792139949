import React, { Fragment, useEffect, useState } from 'react';
import * as UI from './style'
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Cookies from 'js-cookie'
import {Header} from "./components/Header";
import {MainPage} from "./pages/MainPage";
import {ProtectedRoute} from "./components/ProtectedRoute";
import {Register} from "./pages/Auth/Register";
import {SignIn} from "./pages/Auth/SignIn";
import {Profile} from "./pages/Profile/Index";
import {BlogList} from "./pages/Blog";
import {ForgotPassword} from "./pages/Auth/ForgotPassword";
import {EnterCode} from "./pages/Auth/EnterCode";
import {ResetPassword} from "./pages/Auth/ResetPassword";
import {Privacy} from "./pages/Other/Privacy";
import {AboutUs} from "./pages/Other/AboutUs";
import {Terms} from "./pages/Other/Terms";
function App() {

  // or set True in isAuth ???
  const [ isAuth, setIsAuth ] = useState<boolean>(true)
  const [ token, setToken ] = useState(Cookies.get('token_mle'))
  useEffect(() => {
    token ? setIsAuth(true) : setIsAuth(false)
  }, [ token ])
  const [ showHeader, setShowHeader ] = useState<boolean>(true)
  return (
    <UI.Body>
      <BrowserRouter>
        <Fragment>
          <Header isAuth={isAuth} setIsAuth={setIsAuth} showHeader={showHeader} setShowHeader={setShowHeader}/>
          <Routes>
            <Route path={"/"} element={<MainPage/>}/>
            <Route path={"/register"} element={<Register setToken={setToken}/>}/>
            <Route path={"/signin"} element={<SignIn isAuth={isAuth} setToken={setToken}/>}/>
            <Route path={"/blog"} element={<BlogList/>}/>
            <Route path={"/forgot-password"} element={<ForgotPassword isAuth={isAuth}/>}/>
            <Route path={"/enter-code"} element={<EnterCode isAuth={isAuth}/>}/>
            <Route path={"reset-password"} element={<ResetPassword isAuth={isAuth}/>}/>
            <Route path={"/blogs/:id"} element={<BlogList/>}/>
            <Route path={"/privacy"} element={<Privacy/>}/>
            <Route path={"/about"} element={<AboutUs/>}/>
            <Route path={"/terms"} element={<Terms/>}/>
            <Route path={"/"} element={<ProtectedRoute isAuth={isAuth}/>}>
              <Route path={"/profile"} element={<Profile setIsAuth={setIsAuth}/>}/>
              <Route path={"/users/:id"} element={<Profile setIsAuth={setIsAuth}/>}/>
            </Route>
          </Routes>
        </Fragment>
      </BrowserRouter>
    </UI.Body>
  );
}

export default App;
