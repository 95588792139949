import React, {useEffect, useState} from 'react';
import * as FUI from "./style";
import {Link} from "react-router-dom";
import {userApi} from "../services/UserService";

type TCurrentBlock = "start" | "center" | "end"
export const IncreaseBlocks = ({type, content, size}: any) => {
    const [ current, setCurrent ] = useState<TCurrentBlock>("start")
    const [ activeItem, setActiveItem ] = useState<number>(0)
    useEffect(() => {
        switch(activeItem) {
            case 0:
                setCurrent("start")
                break;
            case 1:
                setCurrent("center")
                break;
            case 2:
                setCurrent("end")
                break;
            default:
                setCurrent("start")
        }
    }, [activeItem])
    return (
        <>
            <FUI.BlockDefault current={current}>
                {content?.data.map((item: any, index: number) => {
                    return (
                        <FUI.IncreaseBlock status={activeItem}
                                           size={size}
                                           type={type}
                                           image={item?.avatar_url}
                                           key={index}>
                            <FUI.AboutIncreaseUser>
                                <h2>{item.name}</h2>
                                <h4>{item.interests?.map((item: any) => item.slice(2, )).slice(0, 3).join(', ')}</h4>
                                {activeItem === index ?
                                    <>
                                        <div>
                                            <h4 style={{fontWeight: "400"}}>
                                                <img src={"/images/YellowLocation.svg"}
                                                     alt={"location"}/>
                                                {item.location?.city}, {item.location?.country}</h4>
                                            <h4 style={{fontWeight: "400"}}>
                                                <img src={"/images/YellowHeart.svg"}
                                                     alt={"heart"}/>
                                                {item.age} Years</h4>
                                        </div>
                                        <FUI.AboutUser>{item.about}</FUI.AboutUser>
                                        <FUI.MoreExplorerButton>
                                            <h4>
                                                More Explorer
                                            </h4>
                                            <Link to={"#"}>
                                                <img src={"/images/WhiteRight.svg"}
                                                     alt={"Arrow Right"}/>
                                            </Link>
                                        </FUI.MoreExplorerButton>
                                    </>
                                    :
                                    <FUI.MoreExplorerButton style={{marginBottom: "25px"}}>
                                        <img src={"/images/WhitePlus.svg"} alt={"Plus"}
                                             onClick={() => setActiveItem(index)}/>
                                    </FUI.MoreExplorerButton>
                                }
                            </FUI.AboutIncreaseUser>
                        </FUI.IncreaseBlock>
                    )
                })}
            </FUI.BlockDefault>
            <FUI.CurrentLineBlock status={activeItem + 1}>
                <img src={"/images/CaretLeft.svg"} alt={'left'}
                     onClick={() => setActiveItem((prevState: number) => {
                         switch (prevState) {
                             case 2:
                                 return 1
                             case 1:
                                 return 0
                             case 0:
                                 return 2
                             default:
                                 return 0
                         }
                     })}
                />
                {content?.data.map((item: any, index: number) => {
                    return(
                        <span key={index}
                              onClick={() => setActiveItem(index)}></span>
                    )
                })}
                <img src={"/images/CaretRight.svg"} alt={'left'}
                     onClick={() => setActiveItem((prevState: number) => {
                         switch (prevState) {
                             case 0:
                                 return 1
                             case 1:
                                 return 2
                             case 2:
                                 return 0
                             default:
                                 return 0
                         }
                     })}
                />
            </FUI.CurrentLineBlock>
        </>
    )
}