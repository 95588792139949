import styled from "styled-components";

const yellow = "#FEEF34"
const dark = "#464852"
const lightgray = "#EEF0ED"
const white = "#FFFFFF"
const gray = "#A89CA0"

export const MainBlock = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`

export const LeftSideBar = styled.div`
  width: 260px;
  height: 100vh;
  position: fixed;
  background-image: url("/images/SideBar.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-left: 40px;
  backdrop-filter: blur(5px);
  border-radius: 0 40px 0 0;
  img:first-child{
    padding-top: 13px;
    width: 202px;
  }
  h1{

  }
  @media(max-width: 800px) {
    display: none;
  }
  //@media(max-height: 800px) {
  //  height: 100%;
  //  border: 1px solid red;
  //}
`

export const SideBarMenu = styled.ul`
  z-index: 0;
  margin-top: 30px;
`


export const SideBarMenuItem = styled.li<{active: number}>`
  display: flex;
  padding: 5px 0;
  margin-left: -40px;
  margin-top: -5px;
  position: relative;
  z-index: 1000 !important;
  align-items: center;
  height: 44px;
  span{
    margin-top: -15px;
    font-weight: 400;
    margin-left: 15px;
    font-size: 20px;
    line-height: 24px;
    text-transform: capitalize;
    color: ${(props: any) => props.active === 'false' ?
            white : dark};
  }
  img{
    padding: 5px !important;
    position: relative;
    width: 25px !important;
    height: 25px !important;
    background: none;
    margin-left: 10px;
    margin-top: -10px;
  }
  :nth-child(${(props: any) => props.active}) {
    background: url(/images/BackgroundImageSidebar.svg) !important;
    border-bottom-left-radius: 40px;
    border-top-left-radius: 40px;
    z-index: 10 !important;
    padding-top: 55px;
    padding-left: -30px !important;
    margin-top: -55px;
    margin-bottom: -40px;
    height: 80px;
    background-size: 100% 105% !important;
    img{
      background: yellow;
      margin-top: -45px !important;
      //filter: invert(99%) sepia(0%) saturate(634%)
      //hue-rotate(211deg) brightness(119%) contrast(100%);
    }
    span{
      margin-top: -50px !important;
    }
  }
  :hover{
    cursor: pointer;
  }
`

export const SideBarBottom = styled.div`
  position: absolute;
  bottom: 50px;
  color: white;
  max-width: 270px;
  width: 100%;
  hr{
    width: 90%;
    margin-left: -10px;
    margin-top: 20px;
  }
`

export const ProfileVerified = styled.div`
  background: rgba(70, 72, 82, 0.8);
  border-radius: 20px;
  width: 80%;
  padding: 15px;
  margin-left: -10px;
  h4{
    margin-top: 0;
  }
  p{
    font-size: 14px !important;
  }
  span{
    position: sticky;
    float: right;
    margin-bottom: 6px;
  }
  
`

export const LineInPercent = styled.hr<{percent: number}>`
  width: 100% !important;
  background: rgb(254,239,52);
  height: 4px;
  margin-left: 0 !important;
  border-image: linear-gradient(90deg, rgba(254,239,52,1) ${(props: any) => props.percent}%, rgba(255,255,255,1) ${(props: any) => props.percent}%);;
  background: linear-gradient(90deg, rgba(254,239,52,1) ${(props: any) => props.percent}%, rgba(255,255,255,1) ${(props: any) => props.percent}%) !important;
`

export const ShadowButton = styled.button`
  background: none;
  border: none;
  color: white;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-direction: row;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  margin-top: 20px;
  :hover{
    cursor: pointer;
  }
  padding: 0;
  img {
    width: 25px !important;
    padding: 0 !important;
    margin-right: 5px;
  }
`