import React, {useEffect, useState} from 'react';
import * as UI from "./style"

export const TextArea = ({register, name, label, data, my, status}: any) => {

    const [symbols, setSymbols] = useState(0)
    const dataLength = (e: any) => {
        setSymbols(e.target.value.length)
    }
    useEffect(() => {
        setSymbols(data?.length || 0)
    }, [data])
    return (
        <UI.TextAreaContent>
            {my ?
                <>
                    {status ?
                    <>
                        <label>{label}</label>
                        <textarea
                            onInput={(e) => dataLength(e)}
                            {...register(name, {
                                value: data
                            })}/>
                        <h5>Symbols: {symbols}</h5>
                    </>
                    :
                        <>
                            <label>{label}</label>
                            <p style={{margin: "5px 0 20px 0"}}>{data}</p>
                        </>}
                </>
            :
                <p>{data}</p>}
        </UI.TextAreaContent>
    )
}