import React, {Dispatch} from 'react';
import * as FUI from "../style"
import {checkFormat} from "../Stories";

interface ISlider {
    image: any,
    setCloseSlider: any
}
export const ImageInSlider = ({image, setCloseSlider}: ISlider) => {
    return  (
        <FUI.SliderBlockMessage>
            <FUI.SliderContentMessage>
                <div>
                    <FUI.RightButtonsBlock>
                        <span></span>
                    </FUI.RightButtonsBlock>
                    {checkFormat(image) === 'image' ?
                        <FUI.ImageInSlider src={image} alt={"image"}/>
                        :
                        <video autoPlay={true} controls={true}>
                            <source src={image}/>
                        </video>
                    }
                    <FUI.RightButtonsBlock>
                        <img src={"/images/WhiteClose.svg"} alt={"close"}
                             style={{marginTop: "10px"}}
                             onClick={() => setCloseSlider(false)}/>
                        <span></span>
                        {/*<img src={"/images/CaretLeftRight.svg"} alt={"right"}*/}

                        {/*     onClick={() => {*/}
                        {/*         current === images.length - 1? setCurrent(0) : setCurrent(current+1)*/}
                        {/*     }}/>*/}
                        <span></span>
                    </FUI.RightButtonsBlock>
                </div>
            </FUI.SliderContentMessage>
        </FUI.SliderBlockMessage>
    )
}