import React, {Dispatch, useEffect, useLayoutEffect, useState} from 'react';
import * as PUI from "./ProfileStyle"
import {IMenuItem, SideBar, TMenuItemTitle} from "./SideBar";
import {Travels} from "../../components/Profile/Travels";
import {Search} from "../../components/Profile/Search";
import {MyProfile} from "../../components/Profile/MyProfile";
import {PopUpMedia} from "../../features/PopUpMedia";
import {profileApi} from "../../services/Profile";
import {PopUpSettings} from "../../features/PopUpSettings";
import {PopUpPlannedTrips} from "../../features/PopUpPlannedTrips";
import {PopUpSpecifyTrips} from "../../features/PopUpSpecifyTrips";
import {useLocation} from "react-router-dom";
import {PopUpShowMedia} from "../../features/PopUpShowMedia";
import {FilterSearchModal} from "../../components/Profile/FilterSearchModal";
import {Viewers} from "../../components/Profile/Viewers";
import {ProfileMobileMenu} from "../../components/Profile/ProfileMobileMenu";
import {TopPanel} from "../../components/Profile/TopPanel";
import * as UI from "../../components/Profile/ProfileStyles"
import Cookies from "js-cookie";
import {Messages} from "../../components/Profile/Messages";
import {PopUpSendMessage} from "../../features/PopUpSendMessage";
import {BlogItems} from "../Blog/BlogItems";
import {Support} from "../../components/Profile/Support";
import {pusher} from "../../app/Pusher";
import {MobileMenuBottomBeautiful} from "../../components/Profile/ProfileStyles";
import {ProfileUser} from "../../components/Profile/ProfileUser";
import {useAppDispatch} from "../../app/hooks";
import {Spinner} from "../../components/Spinner";

interface IProfileProps {
    setIsAuth: Dispatch<boolean>
}
export const menuItemsMobile: IMenuItem[] = [
    {icon: "/images/MyProfileMobile.svg", iconActive: "/images/MyProfileActive.svg", title: "My profile"},
    {icon: "/images/SearchMobile.svg", iconActive: "/images/SearchActive.svg", title: "Search"},
    {icon: "/images/TravelsActive.svg", iconActive: "/images/TravelsActive.svg", title: "Travels"},
    {icon: "/images/MessagesMobile.svg", iconActive: "/images/MessagesActive.svg", title: "Messages"},
    {icon: "/images/GuestsMobile.svg", iconActive: "/images/GuestsActive.svg", title: "Guests"},
]
export const Profile = ({setIsAuth}: IProfileProps) => {
    const [selectedTab, setSelectedTab] = useState<TMenuItemTitle | any>( localStorage.getItem('profile_tab') || 'Travels')
    const [ currentActive, setCurrentActive ] = useState<any>(localStorage.getItem("profile_tab_index") || 0)
    const [ showModal, setShowModal ] = useState<boolean>(false)
    const [ showSettings, setShowSettings ] = useState<boolean>(false)
    const [ showPlannedTrips, setShowPlannedTrips ] = useState<boolean>(false)
    const [ showSpecifyTrips, setShowSpecifyTrips ] = useState<boolean>(false)
    const [ showMedia, setShowMedia ] = useState<boolean>(false)
    const [ showSendMessage, setShowSendMessage ] = useState<boolean>(false)
    const [ showMobileMenu, setShowMobileMenu ] = useState<boolean>(false)
    const [ needRefetch, setNeedRefetch ] = useState<boolean>(false)
    const [ needRefetchTrips, setNeedRefetchTrips ] = useState<boolean>(false)
    // params
    const [ ageFrom, setAgeFrom ] = useState<number>(0)
    const [ ageTo, setAgeTo ] = useState<number>(70)
    const [ heightFrom, setHeightFrom ] = useState('152')
    const [ heightTo, setHeightTo ] = useState('213')
    const [ relation, setRelation ] = useState('')
    const [ smoke, setSmoke ] = useState('')
    const [ zodiac, setZodiac ] = useState('')
    const [ education, setEducation ] = useState('')
    const [ children, setChildren ] = useState('')
    const [ gender, setGender ] = useState('')

    const [ showFilter, setShowFilter ] = useState<boolean>(false)
    const [ showBlog, setShowBlog ] = useState(false)
    const [ notificationPool, setNotificationPool ] = useState<any>([])
    const [ selectedLocation, setSelectedLocation ] = useState<any>()
    const [ countMessages, setCountMessages ] = useState<any>(0)

    const [ goRefetchCounters, setGoRefetchCounters ] = useState<boolean>(false)

    const [ viewerId, setViewerId ] = useState<any>(0)
    const userId = Cookies.get("userId")
    const location = useLocation()
    const forViewId = location.pathname.slice(7)
    const checkId = () => {
        if (!isNaN(+forViewId) && location.pathname.slice(1, 6) === 'users') {
            return forViewId
        } else {
            return userId
        }
    }
    const {currentData: profile, isFetching, isError, refetch, isLoading} = profileApi.useGetProfileQuery(checkId(), {
        refetchOnMountOrArgChange: true,

    })


    const {data: allNotifications} = profileApi.useGetNotificationsAllQuery(userId, {
        refetchOnMountOrArgChange: true
    })
    const [currentActiveMobile, setCurrentActiveMobile] = useState(localStorage.getItem("profile_tab_index") || 0)

    useEffect(() => {
        switch(currentActive.toString()) {
            case '0':
                setCurrentActiveMobile(2)
                break
            case '1':
                setCurrentActiveMobile(1)
                break
            case '2':
                setCurrentActiveMobile(0)
                break
            case '3':
                setCurrentActiveMobile(3)
                break
            case '4':
                setCurrentActiveMobile(4)
                break
            default:
                setCurrentActiveMobile(-1)
        }
    }, [currentActive]);

    const switchMobileToMain = (index: any) => {
        switch(index.toString()) {
            case '0':
                return '2'
            case '1':
                return '1'
            case '2':
                return '0'
            case '3':
                return '3'
            case '4':
                return '4'

        }
    }
    useEffect(() => {
        if (allNotifications?.data) {
            setCountMessages(allNotifications?.data.new_messages)
        }
    }, [allNotifications]);
    const [ count, setCount ] = useState(0)
    useEffect(() => {
        setCount(notificationPool?.filter((item: any) => item?.read_at === null).length)
    }, [notificationPool]);

    const SelectTab = () => {
        switch (selectedTab){
            case "Travels":
                return <Travels size={5} profile={profile} setCurrentActive={setCurrentActive} setSelectedTab={setSelectedTab} selectedLocation={selectedLocation}/>
            case "Search":
                return <Search
                    gender={gender}
                    setSelectedTab={setSelectedTab} setShowFilter={setShowFilter}
                    ageFrom={ageFrom} ageTo={ageTo} showMobileMenu={showMobileMenu}
                    setCurrentActive={setCurrentActive}
                heightTo={heightTo} heightFrom={heightFrom} children={children}
                education={education} smoke={smoke} zodiac={zodiac} relation={relation}/>
            case "My profile":
                return (!isLoading ?
                    <MyProfile setShowModal={setShowModal} showModal={showModal}
                               setShowSettings={setShowSettings} showSettings={showSettings}
                               profile={profile} isFetching={isLoading} refetch={refetch}
                               setShowPlannedTrips={setShowPlannedTrips} showPlannedTrips={showPlannedTrips}
                               setShowSpecifyTrips={setShowSpecifyTrips} showSpecifyTrips={showSpecifyTrips}
                               userId={checkId()} setShowMedia={setShowMedia}
                               showMobileMenu={showMobileMenu}
                               setShowSendMessage={setShowSendMessage}
                               needRefetch={needRefetch} setNeedRefetch={setNeedRefetch}
                               needRefetchTrips={needRefetchTrips} isLoading={isLoading}/>
                :
                    <Spinner/>)
            case "Messages":
                return <Messages setSelectedTab={setSelectedTab}
                                 setCurrentActive={setCurrentActive} profile={profile}/>
            case "Guests":
                return <Viewers selectedTab={selectedTab}
                                setSelectedTab={setSelectedTab} profile={profile}
                setCurrentActive={setCurrentActive}/>
            case "Blog":
                return <BlogItems showBlog={showBlog} setShowBlog={setShowBlog}/>
            case "Support":
                return <Support profile={profile?.data}/>
            default:
                return <span></span>
        }
    }
    useEffect(() => {
        const channel = pusher.subscribe(`private-notify.${userId}`);
        channel.bind(`notification.send`, function (data: any) {
            setNotificationPool((prevMessages: any) => {
                if (prevMessages[prevMessages.length -1].id !== data.id) {
                    return [...prevMessages, data]
                } else {
                    return prevMessages
                }
            })
        })
        channel.bind(`message.count`, function (data: any) {
                console.log('data', data)
            setCountMessages(data.new_messages)
            // setNotificationPool()
        })
        return () => {
            const channel = pusher.subscribe(`private-notify.${userId}`);
            channel.bind(`notification.send`, function (data: any) {
                setNotificationPool((prevMessages: any) => {
                    if (prevMessages[prevMessages.length -1].id !== data.id) {
                        return [...prevMessages, data]
                    } else {
                        return prevMessages
                    }
                })
            })
        }
    }, []);
    useEffect(() => {
        refetch()
    }, [needRefetch]);
    const styleCounter = {
        filter: "drop-shadow(0px 2px 5px rgba(253, 0, 148, 0.50)",
        fontSize: "12px",
        background: "#FD0094",
        width: "18px",
        height: "18px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50px",
        marginTop: "-38px",
        marginBottom: "20px",
        marginRight: "-38px"
    }

    // if (isLoading) return <Spinner/>
    return (
        <PUI.MainBlock>
            {showMedia ?
                <PopUpShowMedia setShowMedia={setShowMedia} profile={profile?.data}/>
                :
                <></>}
            {
                showFilter ?
                    <FilterSearchModal ageFrom={ageFrom} ageTo={ageTo} setAgeFrom={setAgeFrom}
                                       setAgeTo={setAgeTo} setShowFilter={setShowFilter}
                                       heightFrom={heightFrom} setHeightFrom={setHeightFrom}
                                       heightTo={heightTo} setHeightTo={setHeightTo}
                                       children={children} setChildren={setChildren}
                                       smoke={smoke} setSmoke={setSmoke}
                                       zodiac={zodiac} setZodiac={setZodiac}
                                       relation={relation} setRelation={setRelation}
                                       education={education} setEducation={setEducation}
                                       gender={gender} setGender={setGender}
                    />
                    :
                    <></>
            }
            {
                showSendMessage ?
                    <PopUpSendMessage setShowSendMessage={setShowSendMessage} profile={profile?.data}/>
                    :
                    <></>
            }
            <PopUpSpecifyTrips showSpecifyTrips={showSpecifyTrips}
                               setShowSpecifyTrips={setShowSpecifyTrips} userId={userId} setNeedRefetchTrips={setNeedRefetchTrips}/>
            <PopUpPlannedTrips setShowPlannedTrips={setShowPlannedTrips}
                               showPlannedTrips={showPlannedTrips} userId={userId} setNeedRefetchTrips={setNeedRefetchTrips}/>
            <PopUpMedia setShowModal={setShowModal} showModal={showModal}
                        profile={profile} refetch={refetch}/>
            <PopUpSettings setShowModal={setShowSettings} showModal={showSettings}
                           profile={profile} refetch={refetch}/>
            <SideBar
                setSelectedTab={setSelectedTab}
                setIsAuth={setIsAuth}
                selectedTab={selectedTab}
                currentActive={currentActive}
                setCurrentActive={setCurrentActive}
                profile={profile?.data}
                notificationPool={notificationPool}
                showBlog={showBlog} setShowBlog={setShowBlog}
            countMessages={countMessages}/>
            { showMobileMenu ?
                <ProfileMobileMenu showMobileMenu={showMobileMenu} selectedTab={selectedTab}
                                   setShowMobileMenu={setShowMobileMenu} setSelectedTab={setSelectedTab}
                                   notificationPool={notificationPool}
                                   setCurrentActive={setCurrentActive}
                                   currentActive={currentActive}
                setIsAuth={setIsAuth} profile={profile?.data} countMessages={countMessages}/>
            :
                <></>
            }
            <UI.MainBLock mobile={selectedTab === 'Messages'}>
                <TopPanel profile={profile} selectedTab={selectedTab}
                          showMobileMenu={showMobileMenu} setShowMobileMenu={setShowMobileMenu}
                notificationPool={notificationPool} setNotificationPool={setNotificationPool}
                setSelectedTab={setSelectedTab} setCurrentActive={setCurrentActive}
                selectedLocation={selectedLocation} setSelectedLocation={setSelectedLocation}/>
                {isLoading ? <Spinner/> : <SelectTab/>}
            </UI.MainBLock>
            { !showMobileMenu && localStorage.getItem('profile_tab') !== 'Messages' ?
                <MobileMenuBottomBeautiful>
                    {menuItemsMobile.map((item: any, index: number) => {
                        return (
                            <UI.MobileMenuBottomBeautifulItem key={index} active={+currentActiveMobile + 1}
                                                              onClick={() => {
                                                                  setSelectedTab(item.title)
                                                                  setCurrentActive(switchMobileToMain(index.toString()))
                                                                  // @ts-ignore
                                                                  localStorage.setItem("profile_tab_index", switchMobileToMain(index).toString())
                                                                  localStorage.setItem('profile_tab', item.title)
                                                              }}>
                                <img src={selectedTab === item.title ? item.iconActive : item.icon}
                                     style={{borderRadius: selectedTab === item.title ? "50px" : "0"}}
                                     alt={item.title}/>
                                {item.title === 'Guests' && count > 0? <span style={styleCounter}>{count}</span> : ''}
                                {item.title === 'Messages' && countMessages > 0 ? <span style={styleCounter}>{countMessages}</span> : ''}
                                <span>{item.title}</span>
                            </UI.MobileMenuBottomBeautifulItem>
                        )
                    })}
                </MobileMenuBottomBeautiful>
            :
            <></>
            }
        </PUI.MainBlock>
    )
}