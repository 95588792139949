import { configureStore } from "@reduxjs/toolkit";
import { userApi } from "../services/UserService";
import { setupListeners } from "@reduxjs/toolkit/query";
import {profileApi} from "../services/Profile";
import {otherApi} from "../services/Other";

export const store = configureStore({
    reducer: {
        [userApi.reducerPath]: userApi.reducer,
        [profileApi.reducerPath]: profileApi.reducer,
        [otherApi.reducerPath]: otherApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        }).concat(userApi.middleware)
            .concat(profileApi.middleware)
            .concat(otherApi.middleware)
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch)