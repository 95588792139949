import React from 'react';
import * as UI from "./ProfileStyles";
import {profileApi} from "../../services/Profile";
import Cookies from "js-cookie";
import {AboutBlock} from "./ProfileStyles";
import {useNavigate} from "react-router-dom";
import {Spinner} from "../Spinner";

interface IViewers {
    selectedTab: any,
    setSelectedTab: any,
    profile: any,
    setCurrentActive: any
}
export const Viewers = ({selectedTab, setSelectedTab, profile, setCurrentActive}: IViewers) => {
    const navigate = useNavigate()
    const { data: viewers, isLoading, isError } = profileApi.useGetVisitorsQuery({
        userId: Cookies.get("userId")
    })
    const userId = Cookies.get("userId")
    const [ addViewer ] = profileApi.useAddVisitorMutation()
    const goToUser = async (user: any) => {
        await addViewer({userId, user_visitor_id: user.visitorId})
        localStorage.setItem('profile_tab', "My profile")
        localStorage.setItem("profile_tab_index", "2")
        setSelectedTab("My profile")
        setCurrentActive(2)
        navigate(`/users/${user.user_visitor_id}`)
    }
    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        // @ts-ignore
        document.scrollingElement.scrollTop = 0;
        // mainContentRef.current.scrollTop = 0;
    }, []);
    if (isLoading) return <Spinner/>
    return (
        <UI.Content>
            <UI.Container>
                <UI.ViewersBlock>
                    <AboutBlock>
                        <h2>Guests</h2>
                        <h4>Visited my profile
                            <span>{viewers?.data?.length}</span></h4>
                    </AboutBlock>
                    <UI.ViewersList>
                        {viewers?.data.map((viewer: any, index: number) => {
                            return (
                                <UI.ViewerItem key={index} onClick={() => goToUser(viewer)}>
                                    <img src={viewer.visitor?.avatar?.url || "/images/NoAvatar.png"} alt={"avatar"}/>
                                    <h4>{viewer.visitor?.name}</h4>
                                    <div>
                                        <img src={"/images/location.svg"} alt={"Location"}/>
                                        <p> {viewer.visitor?.location?.country}, {viewer.visitor?.location?.city}</p>
                                    </div>
                                </UI.ViewerItem>
                            )
                        })}
                    </UI.ViewersList>
                </UI.ViewersBlock>
            </UI.Container>
        </UI.Content>
    )
}