import React from 'react';
import * as FUI from './style';
import {useParams} from "react-router-dom";
import {ErrorNotify, SuccessNotify} from "../Notifications";
import {userApi} from "../services/UserService";
import {useForm} from "react-hook-form";
import moment from "moment/moment";

export const PopUpSendMessage = ({setShowSendMessage, profile}: any) => {
    const { id } = useParams()
    const [ createChat ] = userApi.useCreateChatMutation()
    const { register, handleSubmit } = useForm()
    const getCreateChat = async(data: any) => {
        if (data.message.length < 1) ErrorNotify('Message is empty')
        else {
            await createChat({
                receiver_id: id,
                content: data.message
            }).then(() => SuccessNotify('Message send!'))
                .then(() => setShowSendMessage(false))
        }
    }


    const checkOnline = () => {
        const dateUtc = moment(profile?.data?.online).unix()
        const currentDate = Math.floor(Date.now() / 1000)
        if (currentDate - dateUtc > 12000) {
            const messageFromTimeZone = moment.utc(profile?.data?.online).local()
            // return `Last seen at ${messageFromTimeZone.format("D MMMM HH:mm")}`
            return messageFromTimeZone.fromNow()
        } else if (profile?.data?.online === null) {
            return 'Offline'
        } else {
            return 'Online'
        }
    }
    return (
        <FUI.PopUpBlock>
            <FUI.PopUpContent height={'custom'}>
                <FUI.SendMessageContent>
                    <FUI.ProfileInSendMessage>
                        <img src={profile.user?.avatar || "/images/AvatarUser.svg"} alt={"avatar"}/>
                        <div>
                            <h4>{profile.user?.name}</h4>
                            <h5>{checkOnline()}</h5>
                        </div>
                    </FUI.ProfileInSendMessage>
                    <button onClick={() => setShowSendMessage(false)}>
                        <img src={"/images/CloseSVG.svg"} alt={"Close"}/>
                    </button>
                </FUI.SendMessageContent>
                <form onSubmit={handleSubmit(getCreateChat)}>
                    <FUI.InputSendMessage {...register('message')}
                                          placeholder={'Start typing...'}/>
                    <FUI.ButtonSendMessage type={"submit"}>
                        Send
                        <img src={"/images/YellowRight.svg"} alt={"right"}/>
                    </FUI.ButtonSendMessage>
                </form>
            </FUI.PopUpContent>
        </FUI.PopUpBlock>
    )
}