import React, {Dispatch, useId, useState} from 'react';
import * as UI from "./style"
import {useForm} from "react-hook-form";
import {profileApi} from "../services/Profile";
import * as MUI from "../components/Profile/ProfileStyles";
import cities from "cities.json"
import {SuccessNotify} from "../Notifications";
interface ISpecifyTrips {
    showSpecifyTrips: boolean,
    setShowSpecifyTrips: Dispatch<boolean>
    userId?: string | null,
    setNeedRefetchTrips: any
}
export const PopUpSpecifyTrips = ({showSpecifyTrips, setShowSpecifyTrips, userId, setNeedRefetchTrips}: ISpecifyTrips) => {
    const specifyTripsId = useId()
    const { register, handleSubmit, setValue } = useForm()
    const [ selectedLocation, setSelectedLocation ] = useState<any>('me')
    const [ listLocations, setListLocation ] = useState<any>([])
    const [ addTrip ] = profileApi.useAddTripMutation()
    const onChangeInput = async (data: any) => {
        try {
            await fetch(`https://api.teleport.org/api/cities/?search=${data}`)
                .then((r: any) => r.json())
                .then((r: any) => setListLocation(r._embedded["city:search-results"].slice(0, 10)))
        } catch(e: any) {
            console.log(e)
        }
    }
    const select = async (data: any) => {
        await fetch(data._links["city:item"]?.href)
            .then((r: any) => r.json())
            .then((r: any) => {
                setSelectedLocation(r)
                setValue('trip', `${r?.full_name}`)
            })
        setListLocation([])
    }

    const createTrip = async () => {
        const findCountry = selectedLocation.full_name.lastIndexOf(',')
        const finalCountry = selectedLocation.full_name.slice(findCountry+2)
        const request = {
            city: selectedLocation.name,
            country: finalCountry,
            latitude: selectedLocation.location.latlon.latitude,
            longitude: selectedLocation.location.latlon.longitude,
            planned: 0
        }
        await addTrip({userId, request})
            .then(() => SuccessNotify('Trip is created'))
            .then(() => setShowSpecifyTrips(!showSpecifyTrips))
            .then(() => setNeedRefetchTrips((prevState: boolean) => !prevState))
    }
    if (!showSpecifyTrips) return <></>
    return (
        <UI.PopUpBlock>
            <UI.PopUpContent height={"400px"}>
                <UI.ProfileSettingsContent style={{paddingBottom: "0", marginBottom: "0"}}>
                    <img style={{width: "45px", height: "45px"}} src={
                        '/images/WorldTrips.svg'
                    } alt={'Profile'}/>
                    <div>
                        <h1 style={{fontSize: "20px", marginBottom: "0"}}>Specify the place</h1>
                        <h4 style={{marginTop: "0", fontSize: "14px"}}>You would like to visit</h4>
                    </div>
                    <span onClick={() => setShowSpecifyTrips(!showSpecifyTrips)}>
                           <img src={"/images/CloseSVG.svg"} alt={"close"}/>
                    </span>
                </UI.ProfileSettingsContent>
                <UI.FormProfileSettings onSubmit={handleSubmit(createTrip)} id={specifyTripsId}>
                    <MUI.ProfileInfo>
                        <div>
                            <img src={"/images/location.svg"} alt={"Location"}/>
                            <h4> Location</h4>
                        </div>
                    </MUI.ProfileInfo>
                    <UI.BlockSearchLocation>
                        <input type={"text"} onInput={(e: any) => onChangeInput(e.target?.value)}
                               {...register('trip')}/>
                        <UI.LocationList>
                            {listLocations.map((item: any, index: number) => {
                                return (
                                    <h5 key={index}
                                        onClick={() => select(item)}>{item?.matching_full_name}</h5>
                                )
                            })}
                        </UI.LocationList>
                    </UI.BlockSearchLocation>
                    <UI.GoToButton
                        form={specifyTripsId}
                        type={"submit"}
                        style={{margin: "15px 5px", float: "right", textTransform: "capitalize"}}
                        color={"white"}>Create
                        <img src={"/images/YellowRight.svg"} alt={'Right'}/>
                    </UI.GoToButton>
                </UI.FormProfileSettings>
            </UI.PopUpContent>
        </UI.PopUpBlock>
    )
}