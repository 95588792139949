import React, {useEffect, useState} from "react";
import * as UI from "../../style";
import Cookies from "js-cookie";
import * as PUI from "../Profile/ProfileStyle";
import * as CUI from "../../components/Profile/ProfileStyles"
import {IMenuItem, menuItems, SideBar, TMenuItemTitle} from "../Profile/SideBar";
import {ProfileMobileMenu} from "../../components/Profile/ProfileMobileMenu";
import {useNavigate} from "react-router-dom";
import {profileApi} from "../../services/Profile";
import {SuccessNotify} from "../../Notifications";
import {BlogItems} from "./BlogItems";
import {TopPanel} from "../../components/Profile/TopPanel";

export const BlogList = () => {
    const userId = Cookies.get("userId")
    const [ selectedTab, setSelectedTab] = useState<TMenuItemTitle | any>( localStorage.getItem('profile_tab') || 'Travels')
    const [ currentActive, setCurrentActive ] = useState<number | string>(localStorage.getItem("profile_tab_index") || -1)
    const navigate = useNavigate()
    const [ showBlog, setShowBlog ] = useState(false)
    const menuItemsNoLogin = [
        {icon: "/images/Blog.svg", iconActive: "/images/BlogActive.svg", title: "Blog"},
    ]
    const {data: profile, isFetching, isError, refetch, isLoading} = profileApi.useGetProfileQuery(userId)
    const logOut = () => {
        Cookies.remove('token_mle')
        // setIsAuth(false)
        SuccessNotify('Bye!')
        navigate('/')
    }
    useEffect(() => {
        setSelectedTab('Blog')
        setCurrentActive(5)
        localStorage.setItem("profile_tab_index", '5')
        // navigate("/profile")
        localStorage.setItem('profile_tab', 'Blog')
    }, [profile])


    useEffect(() => {

        if (window.location.pathname.slice(0, 7) === '/blogs/') {
            setShowBlog(true)
        }
    }, []);
    const [ showMobileMenu, setShowMobileMenu ] = useState<boolean>(false)
    return (

        <PUI.MainBlock>
            <PUI.LeftSideBar>
                <UI.MenuItem to={"/"} style={{zIndex: 10000}}>
                    <UI.Logo src={"/images/logo.svg"} alt="logo"/>
                </UI.MenuItem>
                {profile ?
                    <>
                        <PUI.SideBarMenu>
                            {menuItems.map((item: IMenuItem, index: number) => {
                                return (
                                    <PUI.SideBarMenuItem key={index}
                                                         onClick={() => {
                                                             setSelectedTab(item.title)
                                                             setCurrentActive(index)
                                                             localStorage.setItem("profile_tab_index", index.toString())
                                                             navigate("/profile")
                                                             localStorage.setItem('profile_tab', item.title)
                                                         }}
                                                         active={+currentActive + 1}>
                                        <img src={selectedTab === item.title ? item.iconActive : item.icon}
                                             style={{borderRadius: selectedTab === item.title ? "50px" : "0"}}
                                             alt={item.title}/>
                                        <span style={{color: selectedTab === item.title ? "black" : "white"}}>{item.title}</span>
                                    </PUI.SideBarMenuItem>
                                )
                            })}
                        </PUI.SideBarMenu>
                        <PUI.SideBarBottom>
                            <PUI.ProfileVerified>
                                <h4>Your profile is not verified!</h4>
                                <p>
                                    <strong>
                                        Get verified. &nbsp;
                                    </strong>
                                    The administrator will verify the
                                    information and confirm the profile.
                                </p>
                                <span>{profile?.data?.fill[0]}%</span>
                                <PUI.LineInPercent percent={profile?.data?.fill[0]}></PUI.LineInPercent>
                            </PUI.ProfileVerified>
                            <hr/>
                            <PUI.ShadowButton onClick={() => logOut()}>
                                <img src={"/images/WhiteLogOut.svg"} alt={"Go out"}/>
                                Go Out
                            </PUI.ShadowButton>
                        </PUI.SideBarBottom>
                    </>
                :
                <>
                    <PUI.SideBarMenu>
                        {menuItems.map((item: any, index: number) => {
                            return (
                                <PUI.SideBarMenuItem key={index}
                                                     onClick={() => {
                                                         item.title !== 'Blog' ? navigate("/profile") :
                                                             setSelectedTab(item.title)
                                                         setCurrentActive(index)
                                                         localStorage.setItem("profile_tab_index", index.toString())
                                                         navigate("/profile")
                                                         localStorage.setItem('profile_tab', item.title)
                                                     }}
                                                     active={6}>
                                    <img src={selectedTab === item.title ? item.iconActive : item.icon}
                                         style={{borderRadius: selectedTab === item.title ? "50px" : "0"}}
                                         alt={item.title}/>
                                    <span style={{color: selectedTab === item.title ? "black" : "white"}}>{item.title}</span>
                                </PUI.SideBarMenuItem>
                            )
                        })}
                    </PUI.SideBarMenu>
                </>}
            </PUI.LeftSideBar>
            <CUI.MainBLock mobile={false}>
                <BlogItems showBlog={showBlog} setShowBlog={setShowBlog}/>
            </CUI.MainBLock>
        </PUI.MainBlock>
    )
}