import React, {Dispatch, useState} from 'react';
import * as UI from "./style"
import {Slider} from "./Slider";
import {checkFormat} from "./Stories";
interface IMedia {
    data: any,
    setShowMedia: Dispatch<boolean>
}
export const Media = ({data, setShowMedia}: IMedia) => {
    const [ showSlider, setShowSlider ] = useState<boolean>(false)
    const [ currentSlide, setCurrentSlide ] = useState<number>(0)
    const [currentIndex, setCurrentIndex] = useState<number>(0)
    const [lastIndex, setLastIndex] = useState<number>(0)

    if (!data?.data?.media[0]) return <span>Error data</span>

    return (
        <>
            {showSlider ? <Slider images={data?.data?.media[0]} current={currentSlide}
                                  setSlider={setShowSlider} setCurrent={setCurrentSlide}/>
            :
            <></>
            }
            {data.data?.media[0].length > 0 ?
                <UI.MediaContent>
                    {data?.data.media[0].map((item: any, index: number) => {
                        return (
                            checkFormat(item.url) === 'image' ?
                                <UI.MediaImage src={item.url} alt={'image'} key={index}
                                               onClick={() => {
                                                   setCurrentSlide(index)
                                                   setShowSlider(!showSlider)
                                               }}/>
                                :
                                <video src={item.url} key={index} onClick={() => {
                                    setCurrentSlide(index)
                                    setShowSlider(!showSlider)
                                }}/>
                        )
                    })}
                    {/*<UI.MediaMoreBlock>*/}
                    {/*    {data.data?.media[0].length > 10 ?*/}
                    {/*        <UI.AddTrip*/}
                    {/*            style={{width: "150px"}}*/}
                    {/*            type="button"*/}
                    {/*            onClick={() => setShowMedia ?*/}
                    {/*                setShowMedia(true)*/}
                    {/*                :*/}
                    {/*                console.log('error')}>*/}
                    {/*            Show all*/}
                    {/*            <img src={"/images/YellowRight.svg"} alt={"right"}/>*/}
                    {/*        </UI.AddTrip>*/}
                    {/*        :*/}
                    {/*        <></>*/}
                    {/*    }*/}
                    {/*</UI.MediaMoreBlock>*/}
                </UI.MediaContent>
            :
                <UI.NoMediaContent>
                    <h2>NO PHOTOS</h2>
                    {data?.data.my ?
                        <h4>You can add a photo using the Media Manager</h4>
                    :
                        <></>}
                    <img src={"/images/NoPhotos.png"} alt={'No photos'}/>
                </UI.NoMediaContent>
            }
        </>
    )
}