import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {TAuth, TAuthResponse, TRegister} from "../types/UserTypes";
import { _baseURL } from "./index";
import Cookies from "js-cookie";

export const profileApi = createApi({
    reducerPath: "profileApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${_baseURL}api/profile/`,
        prepareHeaders: (headers) => {
            headers.set('Authorization', `Bearer ${Cookies.get('token_mle')}`)
            return headers
        }
    }),
    endpoints: (builder) => ({
        getProfile: builder.query({
            query: (username) => ({
                url: `${username}/`,
                method: 'GET'
            })
        }),
        uploadAvatar: builder.mutation<any, any>({
            query: (data: any) => ({
                url: `${data.userId}/media/upload/avatar`,
                method: 'POST',
                body: {media_id: data.id}
            })
        }),
        uploadMedia: builder.mutation({
            query: (data: any) => ({
                url: `${data.userId}/media/upload`,
                method: 'POST',
                body: data.formData
            })
        }),
        deletePhoto: builder.query({
            query: (data: any) => ({
                url: `${data.userId}/media/${data.imageId}/delete`,
                method: 'DELETE'
            })
        }),
        getInterests: builder.query({
            query: (user: any) => ({
                url: `${user}/interest`
            })
        }),
        updateInterests: builder.mutation({
            query: (data: any) => ({
                url: `${data.userId}/update`,
                method: 'PATCH',
                body: {interests: data.arrayInterests}
            })
        }),
        updateProfileData:builder.mutation({
            query: (data: any) => ({
                url: `${data.userId}/update`,
                method: 'PATCH',
                body: data.data
            })
        }),
        updateMainData:builder.mutation({
            query: (data: any) => ({
                url: `${data.userId}/update`,
                method: 'PATCH',
                body: data.updateDataReq
            })
        }),
        //trips
        getTrips: builder.query({
            query: (data: any) => ({
                url: `${data.userId}/travel/list?planned=${data.planned}`,
                method: 'GET'
            })
        }),
        addTrip: builder.mutation({
            query: (data: any) => ({
                url: `${data.userId}/travel/add`,
                method: 'POST',
                body: data.request
            })
        }),
        deleteTrip: builder.mutation({
            query: (data: any) => ({
                url: `${data.userId}/travel/delete/${data.tripId}`,
                method: 'DELETE'
            })
        }),
        getVisitors: builder.query({
            query: (data: any) => ({
                url: `${data.userId}/visitor/list`,
                method: 'GET',
            })
        }),
        addVisitor: builder.mutation({
            query: (data: any) => ({
                url: `${data.visitorId}/visitor/add/${data.userId}`,
                method: 'POST'
            })
        }),
        verificationAccount: builder.mutation({
            query: (data: any) => ({
                url: `${data.userId}/verification/load-photo`,
                method: "POST",
                body: data.formData
            })
        }),
        updatePassword: builder.mutation({
            query: (data: any) => ({
                url: `${data.userId}/update/password`,
                method: "PUT",
                body: data.passwords
            })
        }),
        addStory: builder.query({
            query: (data: any) => ({
                url: `${data.userId}/media/${data.media_id}/switch-story`,
                method: 'POST'
            })
        }),
        // Notifications
        getNotifications: builder.query({
            query: (id: any) => ({
                url: `/${id}/notification/list`,
                method: 'GET'
            })
        }),
        setNotifications: builder.mutation({
            query: (id: any) => ({
                url: `/${id}/notification/read`,
                method: 'POST'
            })
        }),
        deleteNotification: builder.mutation({
            query: (id: any) => ({
                url: `/${id}/notification/delete/all`,
                method: 'DELETE'
            })
        }),
        getNotificationsAll: builder.query({
            query: (id: any) => ({
                url: `/${id}/notification/count-new`,
                method: 'GET'
            })
        })
    })
})