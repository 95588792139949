
export const options = {
    height: [
        'None',
        '5′ 0″	152.4 cm',
        '5′ 1″	154.9 cm',
        '5′ 2″	157.5 cm',
        '5′ 3″	160 cm',
        '5′ 4″	162.6 cm',
        '5′ 5″	165.1 cm',
        '5′ 6″	167.6 cm',
        '5′ 7″	170.2 cm',
        '5′ 8″	172.7 cm',
        '5′ 9″	175.8 cm',
        '5′ 10″	177.8 см',
        '5′ 11″	180.3 cm',
        '6′ 0″	182.9 cm',
        '6′ 1″	185.4 cm',
        '6′ 2″	188 cm',
        '6′ 3″	190.5 cm',
        '6′ 4″	193 cm',
        '6′ 5″	195.6 cm',
        '6′ 6″	198.1 cm',
        '6′ 7″	200.7 cm',
        '6′ 8″	203.2 cm',
        '6′ 9″	205.7 cm',
        '6′ 10″	208.3 cm',
        '6′ 11″	210.8 cm',
        '7′ 0″	213.4 см'
    ],
    custom: [
        'None', "No", "Yes"
    ],
    interests: [
        "None", "Sport", "Porno", "Sex", "other"
    ],
    relationship: [
        "None",
        "Single", "In a Relationship",
        "Engaged", "Married", "It's Complicated",
        "In an Open Relationship", "Widowed",
        "Separated", "Divorced", "In a Civil Union",
        "In a Domestic Partnership"
    ],
    zodiac: [
        "None",
        "Aries",
        "Taurus",
        "Gemini",
        "Cancer",
        "Leo",
        "Virgo",
        "Libra",
        "Scorpio",
        "Sagittarius",
        "Capricorn",
        "Aquarius",
        "Pisces",
    ],
    children: [
        "None", "0", "1", "2", "3", "4", "5"
    ],
    education: [
        "None",
        "Formal Education",
        "Informal Education",
        "Primary Education",
        "Secondary Education",
        "Higher Education",
        "Vocational Education",
        "Technical Education",
        "Professional Education",
        "Online Education",
        "Distance Education",
        "Adult Education",
        "Special Education",
        "Montessori Education",
        "Homeschooling",
        "Specialized Training Programs",
        "Continuing Education",
        "Skill-based Education",
        "Liberal Arts Education",
        "STEM Education",
        "Arts Education",
    ],
    gender: ["Female", "Male", "Other"]
}

export const interests = [
    {
        name: "Going out",
        list: [
            "🍻 Bars", "🕺 Clubs", "🎟 Concerts", "☕ Cafe-hopping",
            "🎊 Festivals", "🏛 Museum & galleries", "🎙 Stand up",
            "🎭 Theater", "🎤 Karaoke"

        ]
    },
    {
        name: "Staying in",
        list: [
            "🎲 Board games", "🍰 Baking", "🍳 Cooking", "🌱 Gardening",
            "🥡 Cleaning", "🎮 Video games"
        ]
    },
    {
        name: "Film & TV",
        list: [
            "📺 Action & adventure", "📺 Animated", "📺 Bollywood", "📺 Comedy",
            "📺 Cooking shows", "📺 Documentaries", "📺 Crime"
        ]
    }
]

export const registerData = {
    date: ['01', '02', '03', '04', '05', '06', '07', '08', '09', 10,
        11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
    monthData: [
        {January: "01"}, {February: "02"}, {March: "03"},
        {April: "04"}, {May : "05"}, {June: "06"}, {July: "07"},
        {August: "08"}, {September: "09"}, {October: "10"},
        {November: "11"}, {December: "12"}
    ],
    month: ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"],
    years: [
        "1970", "1971", "1972", "1973", "1974", "1975",
        "1976", "1977", "1978", "1979", "1980", "1981",
        "1982", "1983", "1984", "1985", "1986", "1987",
        "1988", "1989", "1990", "1991", "1992", "1993",
        "1994", "1995", "1996", "1997", "1998", "1999",
        "2000", "2001", "2002"
    ]
}