import styled from "styled-components";

const gray = "#A89CA0"
const lightgray = "#EEF0ED"
const yellow = "#FEEF34";
const dark = "#464852"
const blue = "#7B6AF3"
const red = '#D3385D';
const white = "#FFFFFF"
export const MainBLock = styled.div<{mobile: boolean}>`
  width: calc(100% - 280px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  background: linear-gradient(0deg, #FAFAFA 0%, #FAFAFA 100%), #FFF !important;
  margin-left: 300px;
  @media(max-width: 800px) {
    margin-left: 0;
    width: 100%;
    margin-bottom: ${(props: any) => props.mobile ? "0" : "100px"}
  }
`

export const Content = styled.div`
  width: 100%;
`

export const MobileMenuBottomBeautiful = styled.div`
  display: none;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 5px;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 66px;
  flex-shrink: 0;
  background: white;
  margin-top: 100px;
  z-index: 100000011121 !important;
  @media(max-width: 800px) {
    display: grid;
  }
`

export const MobileMenuBottomBeautifulItem = styled.div<{active: number}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img{
    width: 31px;
    height: 31px;
    padding: 5px;
    border-radius: 0;
  }
  span{
    color: #464852;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Proxima Nova, sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize
  }
  :nth-child(${(props: any) => props.active}) {
    img {
      background: yellow;
      border-radius: 50px !important;
    }
  }
`
export const FixedForModalWeb = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.2);
`
export const BlockModalWeb = styled.div`
  display: flex !important;
  flex-direction: row;
div{
  display: flex;
  flex-direction: column;
  max-width: 50vw;
  align-items: center;
  img{
    max-width: 300px !important;
    max-height: 400px !important;
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
    margin-top: 10px;
  }
  p{
    max-width: 400px;
    text-align: center;
  }
}
`
export const ModalWeb = styled.div`
  background-color: white;
  margin: 20px;
  padding: 20px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  img{
    width: 24px;
    :hover{
      cursor: pointer;
    }
  }
  div{
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    img{
      width: 44px;
    }
  }
  @media(max-width: 840px) {
    width: 95%;
    object-fit: cover;
  }
`

export const NewPhoto = styled.img`
  width: 100% !important;
  height: 100% !important;
`
export const Container = styled.div`
  margin: 10px;
  margin-right: 5px;
  margin-top: 10px;
`
// Top Panel

export const Panel = styled.div`
  display: flex;
  width: 100% !important;
  margin-bottom: 10px;
  justify-content: space-between;
  @media(max-width: 800px) {
    display: none;
  }
`

export const SearchModule = styled.div`
  display: flex;
  align-items: center;
  max-width: 480px;
  width: 100%;
  input{
    width: 100%;
    height: 50px;
    background: #FFFFFF;
    border: 1px solid #EEF0ED;
    border-radius: 25px;
    text-indent:20px;
    font-size: 16px;
    line-height: 19px;
    ::placeholder{
      color: ${gray};
    }
    :focus{
      outline: none;
    }
  }
`

export const LocationListTopPanel = styled.div`
  max-width: 480px;
  width: 100%;
  //z-index: 1000;
  position: absolute;
  top: 15px;
  background: white;
  border-radius: 50px;
  h5{
    margin-left: 20px;
  }
`
export const RightBlock = styled.div`
  display: flex;
  align-items: center;
`
export const ReadNotification = styled.h2<{color: string}>`
  width: 10px;
  height: 10px;
  background: ${(props: any) => props.color};
  border-radius: 50px;
  border: 1px solid lightgray;
  margin-left: 5px;
`
export const ListNotificationBlock = styled.div`
  position: absolute;
  width: 100%;
  max-width: 400px;
  top: 70px;
  right: 20px;
  background: white;
  box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.10);
  border-radius: 24px;
  z-index: 1000000;
  max-height: 400px;
  overflow: hidden;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 2px;
    background-color: #F5F5F5;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: lightgray;
  }
  ul{
    list-style-type: none;
    margin-left: -20px;
    li{
      margin-top: 5px;
      display: flex;
      justify-content: space-between;
      font-weight: 400 !important;
      font-size: 16px;
      margin: 0 !important;
      padding: 0 !important;
      h5{
        margin: 10px 0;
        font-size: 13px;
        display: flex;
        align-items: center;
        img{
          width: 30px;
          height: 30px;
          border-radius: 24px;
          margin-right: 5px;
        }
      }
      h6{
        margin: 10px 0;
        font-size: 12px;
        font-weight: 400;
        display: flex;
        align-items: center;
      }
    }
    margin-right: 20px;
  }
  :hover{
    cursor: pointer;
  }
`
export const BlockNotification = styled.div`
  width: 61px !important;
  height: 50px;
  border: 1px solid #EEF0ED;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  span{
    position: relative;
    bottom: 10px;
    right: 10px;
    padding-right: -10px;
    margin-right: -15px;
    color: white;
    background: #FD0094;
    width: 18px;
    height: 18px;
    font-weight: 700;
    box-shadow: 0 2px 5px rgba(253, 0, 148, 0.5);
    border-radius: 50px;
    display: flex;
    font-size: 12px;
    justify-content: center;
    align-items: center;
  }
`

export const MobilePanel = styled.div`
  margin: 10px;
  width: 100% !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  div{
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      width: 30px;
      height: 30px;
      border-radius: 50px;
    }
    h4{
      margin: 0 0 0 15px;
      font-family: Proxima Nova, sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    ${BlockNotification} {
      img{
        width: 22px !important;
        height: 22px !important;
      }
    }
  }
  @media(min-width: 800px) {
    display: none;
  }
  margin-top: 0 !important;
  @media(max-width: 350px) {
    div{
      :first-child{
        h4{
          display: none;
        }
      }
    }
  }
`

export const MobileSearch = styled.div<{width: boolean}>`
  left: 0;
  transition: all 0.1s ease;
  position: ${(props: any) => props.width ? "absolute" : "relative"};
  max-width: ${(props: any) => props.width ? "100%" : "50px"};
  width: 93%;
  z-index: 100000;
  top: ${(props: any) => props.width ? "10px" : "0"};
  // height: ${(props: any) => props.width ? "400px" : "0"};
  margin-top: ${(props: any) => props.width ? "10px" : "0"};
  overflow: hidden;
  margin-left: ${(props: any) => props.width ? "15px" : "0"};
  background: ${(props: any) => props.width ? "white" : "none"};
  input{
    width: 100%;
    height: 50px;
    background: ${(props: any) => props.width ? "white" : "none"};
    background-image: ${(props: any) => props.width ? "none" : "url(/images/SearchMobileTopPanel.svg)"};
    background-repeat: no-repeat;
    background-position: 10px;
    border-radius: 25px;
    text-indent:20px;
    font-size: 16px;
    line-height: 19px;
    z-index: 100000;
    border: ${(props: any) => props.width ? "1px solid #EEF0ED" : "none"};
    ::placeholder{
      color: ${gray};
    }
    :focus {
      outline: none;
    }
  }
  display: flex;
  flex-direction: column;
  align-items: start !important;
  h5{
    text-align: start !important;
    margin-left: 20px;
    display: ${(props: any) => props.width ? "block" : "none"};
  }
`


export const MobileMenu = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 10px;
  span{
    width: 22px;
    height: 5px;
    border-top: 2px solid #464852;
  }
  :hover{
    cursor: pointer;
  }
`
export const BlockLanguage = styled.div<{current: string}>`
  background-color: #EFF0F4;
  margin-left: 20px;
  display: flex;
  align-items: center;
  border-radius: 25px;
  div{
    transition: all 0.5s ease;
    :nth-child(${(props: any) => props.current}){
      background: white;
    }
    border: 1px solid #EEF0ED;
    border-radius: 25px;
    background: none;
    margin: 5px;
    :hover{
      cursor: pointer;
    }
    img{
      display: flex;
      align-items: center;
      margin: 10px;
      width: 24px;
    }
  }
`

// Travel Content

export const FindTravelImage = styled.div`
  background-size: initial;
  width: 100%;
  color: white;
  background: url('/images/PageTravelFind.svg') center / contain no-repeat;
  background-size: 100%;
  border-radius: 50px;
  padding: 1px;
  h1{
    color: #FFF;
    font-family: Proxima Nova, sans-serif !important;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 44px */
    margin-left: 40px;
    margin-bottom: 14px;
  }
  h3{
    margin-left: 40px;
    margin-top: 0 !important;
    color: #FFF;
    font-family: Proxima Nova, sans-serif !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  @media(max-width: 1240px) {
    background-size: auto !important;
  }
  @media(max-width: 600px) {
    h1{
      font-size: 30px;
    }
  }
  
`

export const IncreaseBlock = styled.div`

  width: 100%;
`

export const BlogItems = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
`

export const BlogItemForTravel = styled.div<{color: string, text: string}>`
  background: ${(props: any) => props.color};
  border-radius: 25px;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 5px 40px 5px 5px;
  margin-top: 10px;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 10px;
  width: 100%;
  max-width: 450px;
  @media(max-width: 1900px) {
    max-width: 420px;
  }
  @media(max-width: 1900px) {
    max-width: 380px;
  }
  @media(max-width: 1685px) {
    max-width: 330px;
  }
  @media(max-width: 1535px) {
    max-width: 290px;
  }
  @media(max-width: 1420px) {
    max-width: 250px;
  }
  @media(max-width: 1295px) {
    max-width: 350px;
  }
  @media(max-width: 1180px) {
    max-width: 290px;
  }
  @media(max-width: 1060px) {
    max-width: 250px;
  }
  @media(max-width: 982px) {
    max-width: 100%;
    margin: 10px 0;
  }
  @media(max-width: 800px) {
    margin: 10px;
    max-width: 240px;
  }
  @media(max-width: 660px) {
    margin: 10px 0;
    max-width: 100%;
  }
  :hover{
    cursor: pointer;
  }
  h4{
    line-height: 19px;
    font-weight: 400;
    color: ${(props: any) => props.text};
    margin-left: 5px;
    strong{
      font-weight: 700;
    }
  }
`


// Search Panel
export const ContentFilterSearch = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  align-items: start !important;
  justify-content: start !important;
  h1{
    color: #FFF;
    font-family: Proxima Nova, sans-serif !important;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 44px */
    margin-left: 40px;
    margin-bottom: 14px;
  }
  h3{
    margin-left: 40px;
    margin-top: 0 !important;
    color: #FFF;
    font-family: Proxima Nova, sans-serif !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`

export const ImageForModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  margin-top: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50px !important;
  background-image: url('/images/PageSearchImage.svg');
  background-repeat: no-repeat;
  background-size: cover !important;
  background-position: bottom center !important;
  h1{
    color: #FFF;
    font-family: Proxima Nova, sans-serif !important;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 44px */
    margin-left: 40px;
    margin-bottom: 14px;
  }
  h3{
    margin-left: 40px;
    margin-top: 0 !important;
    color: #FFF;
    font-family: Proxima Nova, sans-serif !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  div{
    display: flex;
    flex-direction: column;
    align-items: center;
    h5{
      color: white;
      margin-bottom: 0 !important;
    }
    :hover{
      cursor: pointer;
    }
  }
  @media(max-width: 1000px) {
    h1{
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
    }
    h3{
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  @media(max-width: 900px) {
    background-image: url('/images/PageSearchImageMobile.svg');
  }
  @media(max-width: 800px) {
    background-image: url('/images/PageSearchImage.svg');
  }
  @media(max-width: 600px) {
    background-image: url('/images/PageSearchImageMobile.svg');
    h1, h3{
      margin-left: 25px;
    }
  }
`
// MY PROFILE
export const BlockTrips = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-template-rows: 1fr;
  @media(max-width: 940px) {
    grid-template-columns: 1fr;
  }
  @media(max-width: 800px) {
    grid-template-columns: 2fr 2fr;
  }
  @media(max-width: 600px) {
    grid-template-columns: 1fr;
  }
`
export const TopBlock = styled.div`
  width: 100%;
  display: grid;
  background-color: white;
  grid-template-columns: 2fr 3fr;
  grid-template-rows: 1fr;
  @media(max-width: 1440px){
    grid-template-columns: 1fr;
  }
  @media(max-width: 650px){
    grid-template-columns: 2fr;
  }
`

export const ProfilePhoto = styled.img`
  border-radius: 30px;
  width: 252px;
  
  object-fit: cover;
  @media(max-width: 600px) {
    fill: var(--eef-0-ed, #EEF0ED);
    width: 100%;
    max-width: 145px;
    height: 181px;
    flex-shrink: 0;
  }
  @media(max-width: 369px) {
    max-width: 120px;
    max-height: 80%;
    margin-right: -10px;
  }
`

export const LeftTopBlock = styled.div`
  box-sizing: border-box;
  height: 261px;
  background: #FFFFFF;
  border: 1px solid #EEF0ED;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  min-width: 530px;
  overflow: hidden;
  object-fit: cover;
  @media(max-width: 1480px) {
    width: 490px;
  }
  @media(max-width: 940px) {
    width: 97% !important;
    min-width: auto;
    margin: 10px;
  }
  // @media(max-width: 600px) {
  //   width: 100%;
  //   ${ProfilePhoto} {
  //     width: 175px;
  //     height: 181px;
  //   }
  //   height: auto;
  // }
  // @media(max-width: 900px) {
  //   flex-direction: column;
  //   //min-width: 100%;
  //   padding-right: 5px;
  //   ${ProfilePhoto}{
  //     object-fit: cover !important;
  //     border-radius: 40px;
  //     height: auto;
  //   }
  // }
  @media(max-width: 600px) {
    flex-direction: column;
    height: auto;
  }
`

export const UploadFile = styled.div`
  background: gray;
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;
  align-self: center;
  z-index: 1000;
`

export const AboutProfile = styled.div`
  width: 100%;
  margin-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: start;
`

export const AddStory = styled.div`
  margin-top: 18px;
  box-sizing: border-box;
  margin-right: -15px;
  :hover{
    cursor: pointer;
  }
  height: 30px;
  width: 50px;
`

export const ProfilePhotoAddStory = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 50px;
`

export const ImgPlusAddStory = styled.img`
  margin-left: -12px;
  margin-bottom: -5px;
`

export const ProfileUser = styled.div`
  word-wrap: break-word;
  width: 100%;
  flex-wrap: wrap;
`

export const UserNameStatus = styled.div`
  h3{
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: ${dark};
    margin-bottom: 0;
    word-wrap: break-word;
    margin-top: 15px;
  }
  h4{
    margin-top: 3px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: ${blue};
    flex: none;
    order: 1;
    flex-grow: 0;
    margin-bottom: 0;
  }
  @media(max-width: 600px) {
    h3{
      width: 100px;
    }
    h4{
      margin-top: 5px;
    }
  }
`

export const UserDataTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 100%;
`
export const ProfileInfo = styled.div`
    div{
      display: flex;
      align-items: center;
      align-content: center;
      align-self: center;
      justify-content: start;
      flex-direction: row;
      margin-top: 5px;
      p{
        margin: 0 0 0 5px;
        color: ${dark};
        font-size: 16px !important;
      }
    }
`

export const BlockSettings = styled.div`
  display: flex;
  margin-top: 20px;
  @media(max-width: 600px) {
    display: none;
  }
`


export const ButtonSettings = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EEF0ED;
  border-radius: 24px;
  border: none;
  padding: 6px 12px;
  font-family: Proxima Nova, sans-serif !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  width: 157px;
  img{
    margin-right: 5px;
  }
  :hover{
    cursor: pointer;
  }
  @media(max-width: 600px) {
    color: var(--7-b-6-af-3, #7B6AF3);
    background: none;
    /* text 12/regular */
    font-family: Proxima Nova, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 116.667% */
  }
  @media(max-width: 380px) {
    width: auto;
  }
`
export const BlockSettingsMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ButtonsBlockSettingsMobile = styled.div`
  margin-top: 50px;
  display: none;
  @media(max-width: 600px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-bottom: 10px;
  }
`
export const ButtonRight = styled.img`
  margin-left: 5px;
  border-radius: 50px;
`

export const VerifiedStatus = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  span{
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: ${red};
    margin-left: 3px;
  }
`

export const SendMessage = styled.button`
  background: linear-gradient(0deg, #FAFAFA 0%, #FAFAFA 100%), #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px 7px 5px 10px;
  display: flex;
  align-items: center;
  margin-top: 40px;
  border-radius: 25px;
  background: var(--ffffff, #FFF);
  box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.10);
  border: none;
  color: #464852;
  :hover{
    cursor: pointer;
  }
  @media(max-width: 600px) {
    margin-top: 10px;
    font-family: Proxima Nova, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 10px;
    img {
      padding: 2px;
    }
  }
`
export const ProfileDataBlock = styled.div`
  background: #FFFFFF;
  border-radius: 30px;
  width: 100%;
  margin-top: 30px;
  h3{
    margin-left: 30px;
    margin-top: 20px;
  }
`

export const VerificationList = styled.div`
  display: flex;
  justify-content: start;
  padding: 10px 25px;
`
export const FormDataProfile = styled.form`
  width: 100%;
  //padding-left: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
`

export const VerificationButton = styled.button<{color: string}>`
  display: flex;
  align-items: center;
  border: none;
  padding: 4px 16px 4px 4px;
  gap: 16px;
  border-radius: 15px;
  background-color: ${(props: any) => props.color};
  color: var(--ffffff, #FFF);
  font-family: Proxima Nova, sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  :hover{
    cursor: pointer;
  }
`
export const DataSelect = styled.div`
  width: 100%;
  padding-left: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const BasicElement = styled.div`
  display: flex;
  flex-direction: column;
  width: 27%;
  height: 44px;
  margin: 20px;
  label {
    font-size: 16px;
    font-family: Proxima Nova, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
  h4{
    margin-top: 5px;
    font-size: 16px;
    font-family: Proxima Nova, sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  }
`
export const BlockProfileData = styled.div`
  display: flex;
  flex-direction: column;
  width: 27%;
  height: 44px;
  margin: 20px;
  select {
    input{
      height: 24px;
      border: none;
      outline: none;
      border-bottom: 1px solid #A196F4;
    }
  }
  @media(max-width: 1300px) {
    width: 40%;
  }
`

export const BlockRegisterData = styled(BlockProfileData) `
  width: 29% !important;
  margin: 20px 0;
  label{
    font-size: 14px;
    color: #A89CA0;
  }
`
export const SelectForm = styled.select`
  outline: none;
  border: none;
  border-bottom: 1px solid #A196F4;
  background: none;
  margin-top: 5px;
  ::-webkit-scrollbar {
    width: 3px;
    background-color: #F5F5F5;
  }
  font-size: 14px !important;
  padding: 0 !important;
`

export const SelectInSearch = styled.select`
  border-radius: 10px;
  border: 1px solid #D9D9D9;
  width: 170px;
  height: 40px;
  color: #464852;
  background: white;
  leading-trim: both;
  text-edge: cap;
  font-family: Proxima Nova, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 10px;
  ::-webkit-scrollbar {
    width: 3px;
    background-color: #F5F5F5;
  }
`
export const CustomButtonForm = styled.button<{status: boolean}>`
  width: 117px;
  height: 52px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border: 10px solid #fafafa;
  margin-top: -15px;
  :hover{
    cursor: pointer;
  }
`

export const TopBlockProfileData = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  div{
    display: flex;
    margin-right: 40px;
  }
`
export const ButtonSave = styled.button`
  width: 117px;
  height: 52px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #464852;
  color: white;
  border: 10px solid #fafafa;
  margin-top: -15px;
  margin-right: 10px;
  :hover{
    cursor: pointer;
  }
`
export const InterestsBlock = styled.div`
  
`

export const InterestType = styled.h3`
  
`
export const InterestTypeContent = styled.div`
  display: flex;
  margin-left: 30px;
  flex-wrap: wrap;
  align-items: center;
`
export const InterestsItem = styled.h4<{status: boolean}>`
  border-radius: 14px;
  border: 1px solid #EEF0ED;
  padding: 5px 7px;
  margin-right: 10px;
  margin-top: 0;
  background: ${(props: {status: boolean}) => props.status ? '#D6BFF3' : ''};
  font-weight: normal;
  :hover{
    cursor: pointer;
  }
`

export const UserList = styled.div`
  margin-top: 15px;
  display: grid;
  background-color: white;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  gap: 10px;
  border-radius: 24px;
  width: 100%;
  @media(min-width: 1800px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media(min-width: 2000px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media(max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media(max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const LittleUser = styled.div<{avatar: string}>`
  height: 350px;
  flex-shrink: 0;
  border-radius: 20px;
  background: linear-gradient(180deg, 
  rgba(255, 255, 255, 0.00) 0%,
  rgba(14, 0, 98, 0.50) 100%), 
  url(${(props: any) => props.avatar}) lightgray 50% center / cover no-repeat;
  background-blend-mode: multiply, normal;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  :hover{
    cursor: pointer;
  }
  @media(max-width: 1600px) {
    height: 300px;
  }
  @media(max-width: 1260px) {
    height: 250px;
  }
  @media(max-width: 800px) {
    height: 350px;
  }
  @media(max-width: 600px) {
    height: 250px;
    margin: 6px;
  }
  @media(max-width: 500px) {
    height: 180px;
    margin: 6px;
  }
  
`

export const TopBlockLittleUser = styled.div`
  display: flex;
  padding: 10px;
  justify-content: space-between;
`

export const BottomBlockLittleUser = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  h3{
    color: #FFF;
    font-size: 18px;
    font-family: Proxima Nova, sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
  }
  div{
    display: flex;
    align-items: center;
    h5{
      margin: 5px;
      color: white;
    }
  }
  @media(max-width: 480px) {
    div{
      img{
        width: 24px !important;
        height: 24px !important;
      }
    }
  }
`

export const BlockBackground = styled.div`
  width: 25px;
  height: 25px;
  flex-shrink: 0;
  box-sizing: border-box;
  border-radius: 20px;
  background: linear-gradient(0deg, #FEEF34 0%, #FEEF34 100%), linear-gradient(0deg, #464852 0%, #464852 100%), #DAF433;
  box-shadow: 0px 8px 15px 0px rgba(254, 239, 52, 0.50);
  display: flex;
  justify-content: center;
  align-items: center;
  span{
    color: #464852;
    leading-trim: both;
    text-edge: cap;
    font-size: 12px;
    font-family: Proxima Nova, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`

export const PhotosBlock = styled.div`
  width: 39px;
  height: 25px;
  flex-shrink: 0;
  border-radius: 25px;
  background: rgba(255, 255, 255, 0.50);
  display: flex;
  justify-content: center;
  align-items: center;
  span{
    color: black;
    margin-left: 3px;
  }
`

export const LoadMore = styled.button`
  display: flex;
  align-items: center;
justify-content: space-between;
  border: none;
  margin-left: 10px;
  background: #FFFFFF;
  color: black;
  margin-top: 10px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  padding: 5px 0 5px 15px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 50px;
  img{
    float: right;
  }
  :hover{
    cursor: pointer;
  }
`

// FilterSearchModal

export const FilterSearchModal = styled.div`
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  position: fixed;
  background: rgba(101,101,101,0.3981967787114846) 100%;
  margin: 0 auto;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ContentFilter = styled.div`
  width: 468px;
  height: auto;
  flex-shrink: 0;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.10);
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media(max-width: 500px) {
    width: 90%;
  }
`

export const TopPanelSearchModal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  div{
    display: flex;
    align-items: center;
    h5{
      margin-top: 15px;
    }
    img{
      width: 40px;
      height: 40px;
      fill: linear-gradient(0deg, #FEEF34 0%, #FEEF34 100%), linear-gradient(0deg, #464852 0%, #464852 100%), #DAF433;
      filter: drop-shadow(0px 8px 15px rgba(254, 239, 52, 0.50));
    }
  }
  img{
    :hover{
      cursor: pointer;
    }
  }
`
export const AgeBlock = styled.div`
    display: flex;
  justify-content: space-between;
  margin: 0 !important;
  padding: 15px;
  margin-top: -15px !important;
  div{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    label{
      color: #A89CA0;
      font-family: Proxima Nova, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 5px;
    }
    input{
      border-radius: 10px;
      border: 1px solid #D9D9D9;
      width: 160px;
      height: 40px;
      padding-left: 5px;
      color: #464852;
      leading-trim: both;
      text-edge: cap;
      font-family: Proxima Nova, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    @media(max-width: 600px) {
      input {
        width: 90%;
      }
    }
  }
`

export const ButtonUpdate = styled.button`
  width: 171px;
  height: 50px;
  flex-shrink: 0;
  margin: 0 auto;
  border-radius: 25px;
  background: linear-gradient(0deg, #FEEF34 0%, #FEEF34 100%), #FFF;
  box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.10);
  color: #464852;
  font-family: Proxima Nova, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  h5{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    :hover{
      cursor: pointer;
    }
  }
`

export const ProfileMobileMenu = styled.div`
  width: 100%;
  position: fixed;
  height: 100% !important;
  max-height: 100% !important;
  z-index: 1001110;
  background-image: url("/images/SideBar.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  overflow-y: scroll;

  span{
    position: sticky;
    float: right;
    margin-top: 30px;
    margin-right: 20px;
    z-index: 1500;
    :hover{
      cursor: pointer;
    }
  }
  @media(min-width: 801px) {
    display: none;
  }
`

export const Logo = styled.img`
    margin: 20px 40px;
  width: 197px;
  height: 37px;
`

export const MobileMenuList = styled.ul`
  width: 285px;
  margin-left: -10px;
`
export const MobileMenuItem  = styled.li<{active: number}>`
  display: flex;
  padding: 5px 0;
  margin-top: -5px;
  position: relative;
  z-index: 1000 !important;
  align-items: center;
  height: 44px;
  span{
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
    margin-left: 15px;
    text-transform: capitalize;
    color: ${(props: any) => props.active === 'false' ?
            white : dark};
  }
  img{
    padding: 5px !important;
    position: relative;
    width: 25px !important;
    height: 25px !important;
    background: none;
    margin-left: 10px;
  }
  :nth-child(${(props: any) => props.active}) {
    border-radius: 40px;
    background: white;
    z-index: 10 !important;
    img{
      background: yellow;
      //filter: invert(99%) sepia(0%) saturate(634%)
      //hue-rotate(211deg) brightness(119%) contrast(100%);
    }
  }
  :hover{
    cursor: pointer;
  }
`

export const MobileMenuBottom = styled.div`
  position: relative;
  color: white;
  margin-left: 50px;
  margin-bottom: 140px;
  hr{
    width: 90%;
    margin-left: -10px;
    margin-top: 20px;
  }
`


// Viewers/Guests + Blog

export const ViewersBlock = styled.div`
  width: 100%;
`

export const AboutBlock = styled.div`
  margin-left: 10px;
  h2{
    margin-top: 0;
    font-family: Proxima Nova, sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px; 
    color: #464852;
    margin-bottom: 15px;
  }
  h4{
    margin-top: 0 !important;
    span{
      margin-left: 10px;
      border-radius: 8px;
      background: var(--feef-34, #FEEF34);
      box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.10);
      padding: 5px 15px;
    }
  }
  @media(max-width: 800px) {
    display: none;
  }
`

export const ViewersList = styled.div`
  margin-top: 50px !important;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: 1fr;
  @media(max-width: 1440px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media(max-width: 1260px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media(max-width: 1120px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media(max-width: 960px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media(max-width: 800px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media(max-width: 680px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media(max-width: 520px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

export const ViewerItem = styled.div`
  margin: 0 10px;
  h4{
    font-family: Proxima Nova, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    margin: 0 !important;
    margin-top: 12px !important;
  }
  box-sizing: border-box;
  img{
    width: 100%;
    object-fit: cover;
    height: 70%;
    border-radius: 30px;
  }
  div{
    display: flex;
    align-items: center;
    img{
      width: 24px;
      height: 24px;
    }
    p{
      margin: 0 !important;
      font-family: Proxima Nova, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px; 
    }
  }
  :hover{
    cursor: pointer;
  }
`

// Chat/Messages

export const ChatBlock = styled.div`
  display: grid;
  grid-template-columns: 2fr 5fr !important;
  grid-template-rows: 1fr;
  height: calc(100vh - 50px);
  width: 100%;
  @media(max-width: 1100px) {
    grid-template-columns: 1fr !important;
  }
  @media(max-width: 800px) {
    margin-top: -15px;
    height: calc(100vh - 160px) ;
  }
`

export const LeftChatBlock = styled.div<{show: boolean}>`
  height: 100%;
  width: 95%;
  h2{
    font-family: Proxima Nova,sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px; /* 160% */
    margin-top: 0;
    margin-bottom: 13px;
    margin-left: 20px;
    color: #464852;
  }
  @media(max-width: 1100px) {
    display: ${(props: any) => props.show ? "block" : "none"}
  }
  @media(max-width: 800px) {
    h2{
      display: none;
    }
  }
`

export const RightChatBlock = styled.div<{show: boolean}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  flex-shrink: 0;
  border-radius: 30px;
  border: 1px solid var(--eef-0-ed, #EEF0ED);
  background: var(--ffffff, #FFF);
  justify-content: center;
  @media(max-width: 1100px) {
    display: ${(props: any) => props.show ? "none" : "flex"};
  }
  @media(max-width: 800px) {
    height: 80vh;
  }
`

export const NotSelectedBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h4{
    text-align: center;
    font-family: Proxima Nova, sans-serif !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #464852;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  h5{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    color: var(--a-89-ca-0, #A89CA0);
    text-align: center;
    font-family: Proxima Nova, sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
`

export const SearchInChat = styled.div<{length: number}>`
  width: 95%;
  input[type="text"]{
    border-radius: 25px !important;
    border: 1px solid #EEF0ED !important;
    background: #FFF;
    padding: 14px 20px !important;
    align-items: center !important;
    gap: 16px !important;
    width: 90%;
    outline: none;
    z-index: 0 !important;
    background-image: url(${(props: any) => props.length < 1 ? '/images/SearchSVG.svg' : ''});
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 20px;
    background-position: 20px;

  }
  input::placeholder{
    padding-left: 30px;
  }
  input[type="text"]:focus{
    background: #FFF !important;
  }
  
  //input:focus::placeholder {
  //  color: white;
  //}
  //input:focus::-webkit-input-placeholder { color:transparent; }
  //input:focus:-moz-placeholder { color:transparent; } 
  //input:focus::-moz-placeholder { color:transparent; }
  //input:focus:-ms-input-placeholder { color:transparent; }
`

export const ChatList = styled.div`
  width: 98%;
  flex-direction: column;
  align-items: center;
  
`

export const IFNoMessages = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  h3{
    font-family: Proxima Nova, sans-serif !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #464852;
  }
  h2{
    color: var(--a-89-ca-0, #A89CA0);
    text-align: center;
    font-family: Proxima Nova, sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    width: 60%;
    margin: 0 auto;
  }
`

export const ChatItem = styled.div`
  width: 100%;
  margin-top: 6px !important;
  padding: 2px 0;
  border-radius: 6px;
  :hover{
    cursor: pointer;
  }
  @media(max-width: 800px) {
    margin-top: 3px !important;
`
export const GoToMessages = styled.img`
  display: none !important;
  @media(max-width: 1100px) {
    background: none;
    display: block !important;
    margin-left: -10px;
    margin-right: 10px;
  }
`
export const BlockUserMessages = styled.div`
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  width: 100%;

  box-sizing: content-box;
  div{
    margin-left: 8px;
    margin-top: 0;
    overflow: hidden;
    h4{
      margin-top: 4px !important;
      margin-bottom: 0 !important;
      font-family: Proxima Nova, sans-serif !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px; /* 112.5% */
    }
    h5{
      margin-top: 0 ;
      margin-bottom: 0;
      font-family: Proxima Nova, sans-serif !important;
      font-size: 14px;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 16px;
      color: #464852;
    }
    h6{
      margin-top: 2px;
      font-family: Proxima Nova, sans-serif !important;
      font-size: 12px;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 16px;
      color: #464852;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
      max-width: 200px;
    }
  }
  span{
    background: #464852;
    color: white;
    width: 22px;
    height: 22px;
    display: flex;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
  }
`
export const ChatItemUser = styled.div`
  display: flex;
  border-radius: 11px;
  padding: 4px;
  div{
    margin-left: 8px;
    margin-top: 0;
    overflow: hidden;
    h4{
      margin-top: 4px !important;
      margin-bottom: 0 !important;
      font-family: Proxima Nova, sans-serif !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px; /* 112.5% */
    }
    h5{
      margin-top: 0 ;
      margin-bottom: 0;
      font-family: Proxima Nova, sans-serif !important;
      font-size: 14px;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 16px;
      color: #464852;
    }
    h6{
      margin-top: 2px;
      font-family: Proxima Nova, sans-serif !important;
      font-size: 12px;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 16px;
      color: #464852;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
      max-width: 200px;
    }
  }
  img{
    width: 41px;
    height: 43px;
    fill: var(--eef-0-ed, #EEF0ED);
    border-radius: 5px;
  }
  :hover{
    cursor: pointer;
  }
`

export const SelectedChat = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const TopPanelSelectedChat = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  border-bottom: 1px solid #EEF0ED;
  padding: 20px;
  img{
    width: 32px;
    height: 32px;
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    :hover{
      cursor: pointer;
    }
  }
  ${ChatItemUser} {
    img{
      width: 41px;
      height: 43px;
      fill: var(--eef-0-ed, #EEF0ED);
      border-radius: 5px;
    }
  }
  @media(max-width: 800px) {
    height: 20px !important;
  }
`

export const BottomPanelSelectedChat = styled.div`
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  div{
    width: 100%;
    display: flex;
    margin: 6px 20px;
    input{
      border-radius: 10px;
      border: 1px solid var(--e-2-e-7-ed, #E2E7ED);
      //border: 1px solid black;
      width: 100%;
      padding: 16px;
      outline: none;
      margin: 0 20px;
      word-wrap: break-word !important;
    }
  }
  @media(max-width: 800px) {
    margin-bottom: 0;
  }
`

export const BottomLeftPanelChat = styled.div`
  img {
    margin-right: 20px;
    width: 24px;
  }
  z-index: 0;
  form{
    input{
      text-align: center;
      //margin-bottom: -200px;
      opacity: 0;
      z-index: 0;

      width: 20px;
      margin-left: -50px;
      :hover{
        cursor: pointer;
      }
    }
  }
  div{
    border: 1px solid red;
  }
`

export const ImagePreview = styled.img`
  max-width: 80px;
  width: 100%;
  max-height: 60px;
  height: 100%;
  object-fit: cover;
`

export const Send = styled.button`
  display: inline-flex;
  padding: 5px 7px 5px 10px;
  align-items: center;
  gap: 13px;
  border-radius: 25px;
  background: var(--ffffff, #FFF);
  border: none;
  box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.10);
  color: #464852;
  font-family: Proxima Nova, sans-serif !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 50px;
  img{
    margin-left: -15px;
    margin-right: -5px;
  }
  margin-right: 20px;
  :hover{
    cursor: pointer;
  }
`
export const Messages = styled.ul`
  padding: 0;
  overflow: hidden;
  height: 62vh;
  margin: 0 20px;
  overflow-y: scroll;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  box-sizing: content-box;
  ::-webkit-scrollbar {
    width: 4px;
    background-color: #F5F5F5;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #EAEBEF;
  }
  h3{
    border-radius: 16px;
    background: var(--eef-0-ed, #EEF0ED);
    text-align: center;
    width: 80px;
    height: 30px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 5px 0;
    font-family: Proxima Nova, sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    color: var(--a-89-ca-0, #A89CA0);
  }
`

export const BlockEmoji = styled.div`
  position: fixed;
  z-index: 2222 !important;
  bottom: 180px;
`

export const BottomPreview = styled.div`
  margin-left: -30px !important;
  transition: all 1s ease;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 40px !important;
  height: 40px;
  background-position: center !important;
  padding: 0 !important;
  border: 1px solid ${yellow} !important;
  border-radius: 100px;
  img{
    display: none;
    margin: 0 !important;
    position: relative !important;
    text-align: center;
    transform: rotate(45deg);
  }
  :hover{
    img{
      cursor: pointer;
      display: block;
    }
  }
`
export const ImagePreviewInMessages = styled.img`
  max-width: 70%;
  float: right;
  background: linear-gradient(-90deg, rgba(239, 205, 253, 0.9), #d0e2ff, rgba(206, 206, 206, 0.9));
  padding: 16px;
  border-radius: 20px;
  box-sizing: border-box;
  animation: gradient 5s ease infinite;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-size: 400% 400%;
  @keyframes gradient {
    0% {
      background-position: 0 25%;
    }
    25% {
      background-position: 25% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
`

export const BlockImagePreview = styled.div`
  width: 100%;
`
export const Message = styled.li<{my: boolean}>`
  margin: ${(props: any) => props.my ? "6px 10px" : "6px 0"};
  width: auto;
  z-index: 1000 !important;
  position: relative;

  div {
    padding: 16px;
    float: ${(props: any) => props.my ? "right" : "left"} !important;
    background: ${(props: any) => props.my ?
            "var(--d-6-bff-3, #D6BFF3)" : "var(--eef-0-ed, #EEF0ED)"};
    border-radius: 20px;
    box-sizing: border-box;
    max-width: 70%;
    h6 {
      margin: 0;
      margin-bottom: -12px;
      text-align: end;
      color: var(--a-89-ca-0, #A89CA0);
      font-family: Proxima Nova, sans-serif !important;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 13px;
      display: flex;
      align-items: center;
      justify-content: end;
  
      img{
        margin-left: 5px;
        margin-right: -8px;
        display: ${(props: any) => props.my ? "block" : "none"} !important;
      }
    }
    img, video{
      max-width: 100%;
      object-fit: cover;
      max-height: 50vh;
    }
    :hover{
      cursor: pointer;
    }
    h5 {
      margin: 0;
      font-family: Proxima Nova, sans-serif !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: #464852;
      margin-bottom: 8px;
      word-wrap: break-word !important;
      max-width: 50vw;
    }
    transition: all 1s ease;
    h4{
      transition: all 1s ease;
      display: none;
      :hover{
        cursor: pointer;
      }
    }
    :hover{
      ${(props: any) => props.my ?
              `
              h5{
                transition: all 0.1s ease;
        color: var(--d-6-bff-3, #D6BFF3) !important;
        height: 0;
      }
      h4{
        transition: all 0.1s ease;
        display: block;
        margin: 0;
        font-family: Proxima Nova, sans-serif !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        color: #464852;
        word-wrap: break-word !important;
        max-width: 50vw;
        text-align: center;
      }
      ` :
              ''
      }
    }
  }
  @media(max-width: 1220px) {
    div{
      h5{
        max-width: 30vw;
      }
    }
  }
`

export const BlogContainer = styled.div`
  width: 100%;
  margin-left: 20px;
  @media(max-width: 800px) {
    margin-left: 0;
  }
`

export const ListBlog = styled.div`
  margin-left: -8px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media(max-width: 1440px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media(max-width: 1220px) {
    grid-template-columns: 1fr 1fr;
  }
  @media(max-width: 900px) {
    grid-template-columns: 1fr;
  }
  @media(max-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }
  @media(max-width: 690px) {
    grid-template-columns: 1fr;
  }
`

export const BlogItem = styled.div`
  margin: 12px 17px;
  border-radius: 20px;
  background: var(--eef-0-ed, #EEF0ED);
  div{
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    margin: 20px;
    img{
      max-width: 100%;
      height: 100% !important;
      border-radius: 20px;
      object-fit: cover;
    }
    h4{
      font-family: Proxima Nova, sans-serif !important;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      color: #464852;
      line-height: 24px;
      margin: 10px 0;
    }
    h6{
      font-family: Proxima Nova, sans-serif !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      color: #464852;
      margin: 5px 0;
    }
    button{
      font-family: Proxima Nova, sans-serif !important;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      align-items: center;
      border: none;
      background: white;
      border-radius: 25px;
      box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.10);
      padding-left: 10px;
      margin-bottom: 10px;
      color: black;
      margin-top: 10px;
      img{
        height: 100% !important;
      }
    }
  }
  height: 350px;
  transition: all 0.3s ease;
  :hover{
    background: white;
    box-shadow: 0px 15px 60px 0px rgba(0, 0, 0, 0.10);
    height: 400px;
    div{
      button{
        cursor: pointer;
        img{
          transform: rotate(-45deg);
          height: 100% !important;
        }
      }
    }
  }
`

export const SupportBlock = styled.div`
  min-height: 100vh;
`

export const SupportContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  grid-row: auto;
  @media(max-width: 1090px) {
    grid-template-columns: 1fr;
  }
`

export const FormSupport = styled.form`
  padding: 20px;
  background: white;
  border-radius: 30px;
  border: 1px solid var(--eef-0-ed, #EEF0ED);
  input{
    background: none;
    outline: none;
    width: 100%;
    padding: 10px;
    padding-left: 0;
    margin: -10px;
    margin-bottom: 20px;
    border: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    border-bottom: 1px solid var(--a-196-f-4, #A196F4);
  }
  textarea{
    border-radius: 10px;
    border: 1px solid var(--a-196-f-4, #A196F4);
    width: 100%;
    height: 90px;
    padding: 10px;
    margin: -10px;
    outline: none;
  }
  h2, h3{
    margin: 0 auto;
    width: 100%;
    max-width: 321px;
    text-align: center;
  }
  h2{
    font-family: Proxima Nova, sans-serif !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; 
    color: #464852;
    margin-top: 20px;
    :before{
      content: "Hello, my friend. How can I help you?"
    }
  }
  h3{
    margin-top: 12px;
    font-family: Proxima Nova, sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 32px;
    color: #A89CA0;
    :before{
      content: "Thank you for your request. Your time and attention is valuable to us."
    }
    :last-child{
      :before{
        content: "The administrator will contact you within
                                24 hours. Thank you for your patience."
      }
    }
  }
  @media(max-width: 1090px) {
    h2{
      :before{
        content: "Thank you for your request"
      }
    }
    h3{
      :before{
        content: "Your time and attention is valuable to us."
      }
      :last-child{
        :before{
          content: "The administrator will contact you within 24 hours. Thank you for your patience."
        }
      }
    }
  }
  @media(max-width: 500px) {
    button{
      display: none;
    }
    h3{
      :last-child{
        margin-top: 24px !important;
        margin-bottom: 19px !important;
      }
    }
  }
`

export const CustomButtonRight = styled.button`
  width: 100% !important;
  display: none;
  border-radius: 25px;
  background: var(--feef-34, #FEEF34);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.10);
  border: none;
  height: 40px;
  font-family: Proxima Nova, sans-serif !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-top: 34px;
  @media(max-width: 500px) {
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 8px 4px 8px 8px;
  }
`

export const SupportRight = styled.div`
    @media(max-width: 1090px) {
      display: none;
    }
`

export const SupportLeft = styled.div`
  display: none;
  @media(max-width: 1090px) {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  overflow: hidden;
  h2{
    margin: 20px;
  }
  h3{
    margin-top: 12px;
    font-family: Proxima Nova, sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 32px;
    color: #A89CA0;
  }
  border-radius: 20px;
  background: #ECECEC;
  @media(max-width: 620px) {
    h2{
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
      z-index: 100;
    }
    img{
      z-index: 0;
      position: relative;
      width: auto;
      object-fit: cover;
      margin-left: -70px;
    }
    h3{
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      z-index: 100;
    }
  }
`