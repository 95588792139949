import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { _baseURL } from "./index";
import Cookies from "js-cookie";

export const otherApi = createApi({
    reducerPath: "otherApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${_baseURL}api/`,
        prepareHeaders: (headers) => {
            headers.set('Authorization', `Bearer ${Cookies.get('token_mle')}`)
            return headers
        }
    }),
    endpoints: (builder) => ({
        getBlogList: builder.query({
            query: (size) => ({
                url: `blog?size=${size}`,
                method: 'GET'
            })
        }),
        getBlog: builder.query({
            query: (id) => ({
                url: `blog/${id}`,
                method: 'GET'
            })
        })
    })
})