import React, {Dispatch, useEffect, useState} from 'react';
import * as UI from "./ProfileStyles";
import {menuItems, IMenuItem} from "../../pages/Profile/SideBar";
import * as PUI from "../../pages/Profile/ProfileStyle";
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import {SuccessNotify} from "../../Notifications";
interface IMobileMenu {
    showMobileMenu: boolean,
    setShowMobileMenu: Dispatch<boolean>,
    setSelectedTab: any,
    setIsAuth: Dispatch<boolean>,
    selectedTab: any,
    profile: any,
    notificationPool: any,
    countMessages: any,
    setCurrentActive: any,
    currentActive: any
}
export const ProfileMobileMenu = ({showMobileMenu,currentActive, setCurrentActive, setShowMobileMenu, setSelectedTab, setIsAuth, selectedTab, profile, notificationPool, countMessages}: IMobileMenu) => {

    const [ count, setCount ] = useState(0)
    const logOut = () => {
        Cookies.remove('token_mle')
        setIsAuth(false)
        SuccessNotify('Bye!')
        navigate('/')
    }
    const styleCounter = {
        filter: "drop-shadow(0px 2px 5px rgba(254, 239, 52, 0.50))",
        fontSize: "16px",
        background: "yellow",
        width: "31px",
        height: "31px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "50px"
    }
    // useEffect(() => {
    //     switch(currentActive) {
    //         case '0':
    //             setCurrentActiveMobile(2)
    //             break
    //         case '1':
    //             setCurrentActiveMobile(1)
    //             break
    //         case '2':
    //             setCurrentActiveMobile(0)
    //             break
    //         case '3':
    //             setCurrentActiveMobile(3)
    //             break
    //         case '4':
    //             setCurrentActiveMobile(4)
    //             break
    //         default:
    //             setCurrentActiveMobile(-1)
    //     }
    // }, [currentActive]);
    useEffect(() => {
        setCount(notificationPool?.filter((item: any) => item?.read_at === null).length)
    }, [notificationPool]);
    const navigate = useNavigate()
    return (
        <UI.ProfileMobileMenu>
            <UI.Logo src={"/images/logo.svg"} alt={"logo"}/>
            <span onClick={() => setShowMobileMenu(!showMobileMenu)}>
                    <img src={"/images/WhiteClose.svg"} alt={"close"}/>
                </span>
            <UI.MobileMenuList>
                {menuItems.map((item: IMenuItem, index: number) => {
                    return (
                        <UI.MobileMenuItem key={index}
                                           onClick={() => {
                                               setSelectedTab(item.title)
                                               setCurrentActive(index)
                                               localStorage.setItem("profile_tab_index", index.toString())
                                               if (item.title === 'Blog') navigate("/blog")
                                               else navigate("/profile")
                                               localStorage.setItem('profile_tab', item.title)
                                           }}
                                           active={+currentActive + 1}>
                            <img src={selectedTab === item.title ? item.iconActive : item.icon}
                                 style={{borderRadius: selectedTab === item.title ? "50px" : "0"}}
                                 alt={item.title}/>
                            <span style={{color: selectedTab === item.title ? "black" : "white"}}>{item.title}</span>
                            {item.title === 'Guests' && count > 0 ? <span style={styleCounter}>{count}</span> : ''}
                            {item.title === 'Messages' && countMessages > 0 ? <span style={styleCounter}>{countMessages}</span> : ''}
                        </UI.MobileMenuItem>
                    )
                })}
            </UI.MobileMenuList>
            {profile?.fill[0] !== 100 ?
                <UI.MobileMenuBottom>
                    <PUI.ProfileVerified>
                        <h4>Your profile is not verified!</h4>
                        <p>
                            <strong>
                                Get verified. &nbsp;
                            </strong>
                            The administrator will verify the
                            information and confirm the profile.
                        </p>
                        <span>{profile?.fill[0]}%</span>
                        <PUI.LineInPercent percent={profile?.fill[0]}></PUI.LineInPercent>
                    </PUI.ProfileVerified>
                    <hr/>
                    <PUI.ShadowButton onClick={() => logOut()}>
                        <img src={"/images/WhiteLogOut.svg"} alt={"Go out"}/>
                        Go Out
                    </PUI.ShadowButton>
                </UI.MobileMenuBottom>
            :
                <UI.MobileMenuBottom style={{width: "90%"}}>
                    <hr/>
                    <PUI.ShadowButton onClick={() => logOut()}>
                        <img src={"/images/WhiteLogOut.svg"} alt={"Go out"}/>
                        Go Out
                    </PUI.ShadowButton>
                </UI.MobileMenuBottom>}
        </UI.ProfileMobileMenu>
    )
}