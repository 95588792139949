import styled from "styled-components";
import {Link} from "react-router-dom";
import {BlogItems} from "./components/Profile/ProfileStyles";
export const Body = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Figtree&display=swap');
  font-family: 'Figtree', sans-serif;
`

// Header
export const Header = styled.header`
  width: 100%;
  z-index: 1;
  height: 80px;
  position: relative;
`

export const Content = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 10px 140px 0 140px;
  align-items: center;
  @media(max-width: 680px) {
    margin: 10px 50px 0 80px;
  }
  @media(max-width: 500px) {
    margin: 5px 10px 0 40px;
  }
`

export const ControlContentBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 20px;
  @media(max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
  img{
    :hover{
      cursor: pointer;
    }
  }
`
export const Logo = styled.img`
  @media(max-width: 450px) {
    width: 200px;
  }
  @media(max-width: 400px) {
    width: 150px;
  }
  z-index: 100000 !important;
`

export const Menu = styled.ul`
  display: flex;
  align-items: center;
  @media(max-width: 1330px) {
    display: none;
  }
`

export const MenuItem = styled(Link)`
  list-style-type: none;
  color: white;
  margin-right: 40px;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-decoration: none;
  :hover{
    cursor: pointer;
  }
  //z-index: 1000010 !important;
`

export const MenuItemBold = styled(MenuItem)`
  font-weight: 700;
`

export const MenuItemBoldButton = styled(MenuItemBold)`
  border: 1px solid white;
  border-radius: 5px;
  padding: 10px;
  :hover{
    cursor: pointer;
  }
`

export const MenuOpenClose = styled.div`
  margin: 15px;
  border: 2px solid white;
  width: 38px;
  height: 38px;
  border-radius: 50px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: none;
  transition: all 1s ease;
  hr{
    margin: 2px;
    width: 14.44px;
    color: white;
    border: 1.5px solid white;
  }
  @media(max-width: 1330px) {
    display: flex;
  }
  @media(max-width: 400px) {
    width: 28px;
    height: 28px;
    margin: 10px;
  }
  :hover{
    cursor: pointer;

  }
`

export const MobileMenu = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  width: 350px;
  background: #464852;
  ${MenuOpenClose} {
    position: absolute;
    float: right;
    right: 15px;
    top: 15px;
  }
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: start;
  @media(max-width: 800px) {
    width: 300px;
  }
`

export const SignInButton = styled.button`
  border-radius: 5px;
  border: 1px solid #FFF;
  background: none;
  width: 180px;
  height: 40px;
  font-family: Proxima Nova, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`

export const RegisterButton = styled.button`
  background: #FFFFFF;
  height: 40px;
  margin-top: 40px;
  border: none;
  width: 180px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #464852;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
export const MobileNavMenu = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 200px;
  align-items: center;
  justify-content: center;
  width: 100%;
  ${MenuItem} {
    margin-top: 30px;
  }

`
// Main Page Content
export const MainBlock = styled.div<{selected: string}>`
  transition: all 0.5s ease;
  position: relative;
  width: 100%;
  height: 100vh;
  margin-top: -100px;
  top: 0;
  background-size: cover;
  z-index: 0;
   padding-top: 80px;
  color: white;
  display: flex;
  align-items: center;
  background-position: right;
  @media(max-width: 450px){
    overflow: hidden;
  }
  @media(max-width: 800px) {
    background-position: ${(props: any) => props.selected === 'ADVENTURE' ? "75%" : "85%"};
  }
`

export const MainBlockAuth = styled.div`
  transition: all 0.5s ease;
  position: relative;
  width: 100%;
  top: 0;
  background-size: cover;
  z-index: 0;
  padding-top: 80px;
  color: white;
  display: flex;
  align-items: center;
  background-position: right;
  @media(max-width: 450px){
    overflow: hidden;
  }
  height: 100%;
  min-height: 100vh;
  margin-top: -80px;
`

export const MainBlockSignIn = styled(MainBlockAuth)`
  height: 100%;
  min-height: 100vh;
`
export const ContentBlock = styled.div`
  margin: -140px 0 0 140px;
  flex-wrap: wrap;
  word-wrap: break-word;
  h1{
    margin: 0;
    font-size: 7.5em;
    line-height: 122px;
  }
  h2{
    margin: 0;
    font-size: 2.5em;
    font-weight: 400;
    line-height: 43px;
  }
  @media(max-width: 1260px){
    h1, h2{
      margin-right: 20px;
    }
  }
  @media(max-width: 730px) {
    margin-left: 80px;
    h1 {
      margin-right: 20px;
      font-size: 6em;
      line-height: 78px;
    }
    h2{
      font-size: 1.5em;
      line-height: 29px;
      margin-right: 20px;
    }
  }
  @media(max-width: 500px) {
    margin-left: 40px;
    h1{
      font-size: 4em;
    }
    h2{
      max-width: 350px;
    }
  }
`

export const MatchesType = styled.div`
  display: flex;
  justify-content: start;
  margin-top: 62px;
  flex-wrap: wrap;
  @media(max-width: 1260px){
    margin-right: 20px;
  }
  @media(max-width: 840px) {
    flex-direction: column;
    align-items: start;
  }
`
export const TypeBlock = styled.div<{background: string, color: string}>`
    background: ${(props: any) => props.background} !important;
    border-radius: 25px;
    display: flex;
    align-items: center;
    padding: 5px;
    margin-right: 20px;
    margin-top: 10px;
  
  
    :hover{
      cursor: pointer;
    }
    h3{
        color: ${(props: any) => props.color};
        font-weight: 400;
        margin: 0 10px 0 10px;
        font-size: 1.0526315789473684em;
    }
`


export const MainPageContent = styled.div`
  width: 1220px;
  margin: 0 auto;
  padding: 10px;
  overflow: hidden;
  z-index: 0;
  @media(max-width: 1260px) {
    width: 95%;
    flex-wrap: wrap;
  }
  @media(max-width: 450px) {
    width: 90% !important;
    word-wrap: break-word !important;
  }
  button {
    img {
      border: none !important;
      margin: 0 !important;
      padding: 0 !important;
      width: 40px !important;
      height: 40px !important;
      background: none !important;
    }
  }
  h1{
    word-wrap: break-word !important;
    max-width: initial !important;
    width: initial !important;
    @media(max-width: 840px) {
      display: inline-block !important;
      flex-wrap: wrap;
    }
  }
`


export const BestFeaturesContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr !important;
  width: 1220px;
  margin: 0 auto;
  h4{
    font-size: 18px !important;
  }
  h2{
    font-size: 30px !important;
  }
  @media(max-width: 1260px) {
    width: 95%;
    h4{
      font-size: 18px !important;
    }
    h2{
      font-size: 30px !important;
    }
  }
  @media(max-width: 1140px) {
    display: none !important;
  }
`

export const BestLeft = styled.div`
  width: 100% !important;
  display: grid;
  grid-template-columns: 1fr !important;
  margin: 0 !important;
  padding: 5px 0;
  div {
    background-repeat: no-repeat;
    width: 100% !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: end;
    align-items: center;
    h2{
      color: white;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      width: 70%;
      margin: 0;
    }
    h4{
      width: 70%;
      margin: 0;
      margin-top: 10px;
      margin-bottom: 100px;
      font-family: Proxima Nova, sans-serif !important;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: white;
    }
  }
  @media(max-width: 1240px) {
    div {
      background-size: 100% 100% !important;
    }
  }
`

export const BestRight = styled.div`
  display: flex !important;
  flex-direction: column !important;
  width: 100%;
  @media(max-width: 1240px) {
    div {
      background-size: 100% 100% !important;
    }
  }
`

export const BestRightTop = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr !important;
  margin-bottom: 5px;
  div{
    display: grid;
    grid-template-columns: 1fr !important;
    margin: 0 !important;
    height: 289px !important;
    background-repeat: no-repeat;
    :first-child{
      display: flex !important;
      flex-direction: column !important;
      justify-content: center;
      align-items: center;
      h2{
        color: white;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: 70%;
        margin: 0;
      }
      h4{
        width: 70%;
        margin: 0;
        margin-top: 10px;
        font-family: Proxima Nova, sans-serif !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: white;
      }
      @media(max-width: 1240px) {
        div {
          background-size: 100% 100% !important;
        }
      }
    }
    :last-child{
      display: flex !important;
      flex-direction: column !important;
      justify-content: center;
      align-items: center;
      h2{
        color: white;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: 70%;
        margin: 0;
      }
      h4{
        width: 70%;
        margin: 0;
        margin-top: 10px;
        font-family: Proxima Nova, sans-serif !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: white;
      }
      @media(max-width: 1240px) {
        div {
          background-size: 100% 100% !important;
        }
      }
    }
  }
`

export const BestRightBottom = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr !important;
  margin-top: 5px;
  div{
    background-repeat: no-repeat;
    :first-child{
      display: flex !important;
      flex-direction: column !important;
      justify-content: center;
      align-items: center;
      height: 289px !important;
      h2{
        color: #464852; 
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: 70%;
        margin: 0;
      }
      h4{
        width: 70%;
        margin: 0;
        margin-top: 10px;
        font-family: Proxima Nova, sans-serif !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #464852;
      }
    }
    :last-child{
      display: flex !important;
      flex-direction: column !important;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-left: 20px;
      h2{
        color: white;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: 70%;
        margin: 0;
      }
      h4{
        width: 70%;
        margin: 0;
        margin-top: 10px;
        font-family: Proxima Nova, sans-serif !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: white;
      }
      @media(max-width: 1240px) {
        margin-left: 5px;
      }
    }
  }
  @media(max-width: 1240px) {
    div {
      background-size: 100% 100% !important;
    }
  }
`

export const BestFeaturesContentMobile = styled.div`
  display: none !important;
  img {
    margin: 0 auto !important;
    display: inline-block !important;
  }
  @media(max-width: 1140px) {
    display: flex !important;
    justify-content: center !important;
    width: 100%;
    flex-wrap: wrap;
    img{
      width: 33% !important;
      :nth-child(4) {
        margin-top: -20px !important;
      }
      :nth-child(5) {
        margin-top: -20px !important;
      }
    }
  }
  @media(max-width: 840px) {
    img{
      width: 45% !important;
      :nth-child(3) {
        margin-top: -20px !important;
      }
    }
  }
  @media(max-width: 640px) {
    img{
      width: 90% !important;
      :nth-child(2) {
        margin-top: -50px !important;
      }
      :nth-child(3) {
        margin-top: -50px !important;
      }
      :nth-child(4) {
        margin-top: -50px !important;
      }
      :nth-child(5) {
        margin-top: -50px !important;
      }
    }
  }
  @media(max-width: 470px) {
    img{
      width: 90% !important;
      :nth-child(2) {
        margin-top: -30px !important;
      }
      :nth-child(3) {
        margin-top: -30px !important;
      }
      :nth-child(4) {
        margin-top: -30px !important;
      }
      :nth-child(5) {
        margin-top: -30px !important;
      }
    }
  }
`
export const MainPageFooter = styled.div`
  width: 100%;
  margin: 0 auto;
  h1, button{
    margin-left: 140px !important;
  }
  button{
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    img{
      margin: 0 !important;
      padding: 0 !important;
      width: 40px !important;
      height: 40px !important;
      background: none !important;
      border: none !important;
    }
  }
`
export const FeatureMainPageContent = styled.div`
  margin-top: 90px;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  div {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    img{
      max-width: 360px;
      margin: 10px;
      :first-child{
        margin: 0 !important
      }
    }
  }
  @media(max-width: 1260px) {
    //justify-content: center !important;
    align-items: center;
    margin-top: 20px;
  }
  @media(max-width: 840px) {
    flex-direction: column;
  }
  img{
    max-width: 360px;
    margin: 10px;
    :first-child, :last-child{
      margin: 0 !important
    }
    @media(max-width: 1260px){
      max-width: 300px;
      margin: 20px;
    }
    @media(max-width: 1000px){
      max-width: 230px;
    }
    @media(max-width: 840px) {
      max-width: 360px;
    }
    @media(max-width: 450px) {
      max-width: 90%;
    }
  }
`

export const ReasonsBlock = styled.div`
  max-width: 440px;
  margin-left: 35px;
  h1{
    font-size: 2.25em !important;
  }
  ul{
    margin-top: -20px;
    li{
      margin-top: 10px;
      margin-left: -50px;
      display: flex;
      align-items: center;
      list-style-type: none;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      span{
        margin-left: 10px !important;
      }
    }
  }
  @media(max-width: 600px) {
    margin-left: 0;
    h1{
      margin-left: -5px !important;
    }
    padding: 10px !important;
  }
`

export const VideoMainPage = styled.div`
  width: 100%;
  height: 100%;
  background: #464852CC;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 102222200 !important;
  video{
    box-sizing: content-box;
    object-fit: contain;
    max-width: 90%;
    max-height: 90%;
    border-radius: 12px;
    width: 100%;
    height: 100%;
    z-index: 122022200 !important;
    @media(max-width: 600px) {
      max-height: 85% !important;
      max-width: 85% !important;
      width: 100% !important;
      height: 100% !important;
    }
  }
  :hover{
    img{
      cursor: pointer;
    }
  }
`
export const ReasonVideoBlock = styled.div`
  height: 505px;
  width: 560px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: end;
  img{
    width: auto;
    height: auto;
    :hover{
      cursor: pointer;
    }
  }
  @media(max-width: 600px) {
    background-size: cover !important;
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    height: 300px;
    align-content: center;
    background: url(/images/ReasonVideoMobile.svg) no-repeat !important;
    background-size: 100% 100% !important;
  }
`
export const BlockAboutShare = styled.div`
  display: flex !important;
  justify-content: center;
  margin-right: 20px;
  h1{
    font-size: 1.25em;
  }
  div{
    display: flex;
    align-items: center !important;
    flex-direction: row;
    align-content: center !important;
    h3{
      font-size: 1.0526315789473684em;
    }
  }
  :first-child{
    width: 80%;
    display: flex;
    flex-direction: column;
    h1{
      font-size: 35px;
      line-height: 43px;
    }
  }
  :hover{
    img{
      cursor: pointerghg;
    }
  }

  @media(max-width: 1140px) {
    :first-child{
      width: 100%;
      h1{
        font-size: 1.35em;
        line-height: normal;
      }
    }
  }
  @media(max-width: 840px) {
    :first-child{
      width: 60%;
      justify-content: start !important;
      align-items: start !important;
      h1{
        text-align: start;
        font-size: 35px;
      }
      div{
        display: none !important;
      }
    }
  }
  @media(max-width: 580px) {
    :first-child{
      width: 74%;
    }
  }
  @media(max-width: 380px) {
    :first-child{
      width: 80%;
    }
  }
`
export const ShareContainerContent = styled.div`
  display: grid;
  margin-top: 120px;
  padding: 10px;
  margin-bottom: 120px;
  grid-template-columns: 4fr 7fr;
  @media(max-width: 1140px) {
    grid-template-columns: 2fr 7fr;
  }
  @media(max-width: 840px) {
    justify-content: start;
    margin-top: 20px;
  }
  @media(max-width: 840px) {
    display: flex;
    flex-wrap: wrap;
  }
`

// register page

export const BlockAuth = styled.div`
  margin-top: 20px;
  background: linear-gradient(0deg, #FFFFFF, #FFFFFF), #EEF0ED;
  width: 348px;
  border-radius: 25px;
  padding: 10px;
  ${MenuItem} {
    color:  #464852;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    margin-left: 15px;
  }
  @media(max-width: 420px) {
    width: 95%;

    ${MenuItem} {
      margin-left: 0;
    }
  }
`
export const FormAuth = styled.form`
  display: flex;
  flex-direction: column;
  margin: 20px;
  h2{
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
    color: black;
  }
  h4{
    color: black;
    margin-top: -10px;
    ${MenuItem} {
      margin-left: 0;
      text-decoration: none;
      color: #7B6AF3;
      font-weight: bolder;
    }
  }
  label{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #464852;
    margin-bottom: 3px;
  }
  input{
    border:0 solid #fff;
    border-bottom: 1px solid rgba(123, 106, 243, 0.7);
    margin-bottom: 26.99px;
    outline:none;
  }
  h3{
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #464852;
  }
  input[type=submit]{
    position: sticky;
    font-weight: 700;
    max-width: 182px;
    font-size: 16px;
    line-height: 19px;
    color: white;
    background: #7B6AF3;
    border-radius: 8px;
    height: 37px;
    margin-bottom: -10px;
    padding: 0 10px 0 10px;
  }
  select{
    margin: 5px 0 10px 0;
    padding: 5px;
    font-size: 1.1em;
    border-radius: 5px;
    outline: 0;
    background-color: transparent;
  }
  span{
    color: red;
    font-size: 0.8em;
  }
`

export const DateInRegister = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
`

export const CheckBoxInRegister = styled.div`
  display: flex;
  label{
    margin-left: 5px;
    font-size: 10px;
    line-height: 12px;
  }
`
export const LocationList = styled.div`
  display: flex;
  flex-direction: column;
  color: black;
  h5{
    margin: 3px 0;
    position: sticky;
    :hover{
      cursor: pointer;
    }
  }
`
export const Full1440px = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  margin-top: 4em;
  h1{
    width: 1220px;
    margin: 0 auto;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
    margin-bottom: 1em;
  }
`

export const MasonryGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  div {
    img {
      width: 100%;
    }
    :nth-child(even) {
      margin-top: 40px;
    }
    :nth-child(5), :nth-child(6) {
      display: none;
    }
  }
  @media(max-width: 600px) {
    width: 100%;
    h1{
      width: 100%;
      font-size: 36px;
    }
    margin: 0 auto;
    display: grid !important;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-auto-rows: minmax(150px, 180px) !important;
    grid-auto-flow: dense;
    
    div {
      padding: 15px;
      overflow: hidden;
      :nth-child(5), :nth-child(6) {
        display: inline-block !important;
      }
      :nth-of-type(1){
        grid-row: span 3 !important;
      }
      :nth-of-type(2), :nth-of-type(5),:nth-of-type(3), :nth-of-type(4) {
        grid-row: span 2 !important;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 30px;
      }
    }
  }
  @media(max-width: 530px) {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    grid-auto-rows: minmax(50px, 150px) !important;
    div {
      margin: 0 !important;
      :nth-of-type(6) {
        grid-row: span 2 !important;
      }
      :nth-of-type(5) {
        grid-row: span 3 !important;
      }
    }
  }
  @media(max-width: 390px) {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-auto-rows: minmax(80px, 120px) !important;
    div{
      
    }
  }
`
export const CEOBlock = styled.div<{current: number}>`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 4em;
  margin-bottom: 4em;
  @media(max-width: 1220px) {
    flex-direction: column;
    align-items: center;
  }
`

export const AboutCEO = styled.div`
  display: flex;
  justify-content: space-between;
  width: 45%;
  div{
    margin-left: 25px;
    p{
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
    }
    h3{
      margin-bottom: 0;
    }
    h4{
      margin-top: 5px;
      font-weight: normal;
    }
  }
  @media(max-width: 1220px) {
    width: 40%;
    flex-direction: column;
    img{
      max-width: 300px;
    }
    align-items: center;
    margin-top: 20px;
  }
  @media(max-width: 1000px) {
    width: 60%;
  }
  @media(max-width: 600px) {
    width: 100%;
  }
`

export const BlogBlock = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 4em;
  margin-bottom: 4em;
  @media(max-width: 850px) {
    flex-direction: column;
    align-items: center;
  }
`
export const selectedCEOBlock = styled.div`

`
export const PerfectTravel = styled.div`
  display: flex;
  margin-top: 4em;
  margin-bottom: 4em;
  justify-content: space-between;
  img{
    z-index: 0;
    position:relative;
    float: right;
    margin-left: -70px;
  }
  @media(max-width: 1000px) {
    img{
      display: none;
    }
  }
`

export const ContentPerfectTravel = styled.div`
  z-index: 12;
  position: relative;
  ul{
    margin-left: -40px;
    margin-top: 39px;
    li{
      margin-top: 20px;
      height: 71px;
      background: #FFFFFF;
      box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      display: flex;
      justify-content: start;
      align-items: center;
      width: 460px;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      padding-right: 70px;
      span{
        margin-left: 30px;
        margin-right: 30px;
        background: #464852;
        border-radius: 50px;
        width: 20px !important;
        height: 20px !important;
        color: white;
        border: 10px solid #464852;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
      }
      list-style-type: none;
    }
  }
  @media(max-width: 600px) {
    ul{
      li{
        width: 95%;
        height: auto;
        padding: 20px 0;
      }
    }
  }
`

export const BlogList = styled.div`
  
`
export const BlogImage = styled.img`
  width: 100%;
  border-radius: 20px;
  object-fit: cover;
  transition: 0.5s !important;
`
export const ShowPost = styled.button`
  margin: 0 auto;
  margin-top: 50px;
  display: none;
  align-items: center;
  height: 40px;
  border-radius: 40px;
  width: 143px;
  border: none;
  background: #464852;
  color: white;
  justify-content: center;
  font-size: 20px;
  line-height: 24px;
  img{
    margin-left: 10px !important;
  }
  :hover{
    cursor: pointer;
  }
`
export const BlogItem = styled.div`
  width: 360px;
  height: 455px;
  border-radius: 40px;
  :hover{
    ${BlogImage} {
      width: 100%;
      height: 40% !important;
      border-radius: 20px;
      transition-duration: 0.5s;
    }
    ${ShowPost}{
      display: flex;
      img{
        margin-left: 10px !important;
        width: 30px !important;
      }
    }
  }
  img{
    height: 60% !important;
    max-width: 360px;
  }
  div{
    margin-left: 30px;
    width: 60%;
    h4{
      display: flex;
      align-items: center;
      font-weight: normal;
      img{
        margin-right: 5px;
      }
    }
  }
  :hover{
    background: #FEEF34;
  }
  @media(max-width: 1200px) {
    width: 300px;
    ${BlogImage} {
      width: 300px;
    }
  }
  @media(max-width: 1000px) {
    width: 250px;
    ${BlogImage} {
      width: 250px;
    }
    div{
      width: 90%;
      margin-left: 10px;
      word-wrap: break-word;
      overflow: hidden;
    }
  }
  @media(max-width: 850px) {

    width: 500px !important;
    ${BlogImage} {
      width: 500px !important;
      max-width: 500px;
      border-radius: 50px;
    }
    border-radius: 50px;
    margin-top: 30px;
  }
  @media(max-width: 550px) {
    width: 95% !important;
    ${BlogImage} {
      width: 100% !important;
      height: 50% !important;
    }
  }
`

export const AboutCEOBlock = styled.div`
  margin-top: 4em;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -2em;
  flex-wrap: wrap;
  button{
    display: flex;
    background: none;
    align-items: center;
    height: 40px;
    border: none;
    font-size: 20px;
    line-height: 24px;
    :hover{
      cursor: pointer;
    }
  }
`
export const ImageBlogJonUs = styled.div`
  background-size: initial;
  width: 100%;
  color: white;
  height: 500px;
  background: url('/images/Footer.png') center / contain no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  border-radius: 40px;
  z-index: 10000 !important;
  h1{
    max-width: 410px;
    font-size:  3.25em;
    margin: 20px 0 10px 0;
  }
  button{
    display: flex;
    background: none;
    align-items: center;
    height: 40px;
    border: none;
    font-size: 20px;
    line-height: 24px;
    color: white;
    margin-top: 30px;
    img{
      background: #FEEF34;
      border-radius: 50px;
      margin-left: 15px;
      width: 20px;
      border: 7px solid #FEEF34;
    }
    :hover{
      cursor: pointer;
    }
  }
  @media(max-width: 1200px) {
    height: 500px;
  }
  @media(max-width: 400px) {
    padding: 0;
    margin: 0;
    margin-top: -50px;
    background: url('/images/FooterMobile.png') no-repeat;
    width: 100% !important;
    background-size: 110%;
    h1{
      font-size: 30px;
      margin-left: 20px;
    }
    button{
      margin-left: 20px;
    }
  }
`

export const FullWidthContent = styled.div`
  background: #222222;
  width: 100%;
  margin-top: -35px;
  z-index: -1 !important;
`

export const FooterContent = styled.div`
  z-index: 0;
  margin-top: 2em;
  display: flex;
  justify-content: space-between;
  color:  rgba(255, 255, 255, 0.5);
  flex-wrap: wrap;
  div{
    display: flex;
    flex-direction: column;
    margin: 20px;
    margin-left: 0;
    img{
      max-width: 200px;
    }
    p{
      margin-top: 20px;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      max-width: 300px;
    }
    h3{
      color: white;
      font-size: 20px;
      line-height: 24px;
    }
  }
`

export const FooterLink = styled(Link)`
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  margin-top: 20px;
  font-size: 20px;
  line-height: 24px;

`