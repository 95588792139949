import React from 'react';
import * as UI from "./ProfileStyles";
import Cookies from "js-cookie";
import {useForm} from "react-hook-form";
import {AddTrip, GoToButton} from "../../features/style";
import {userApi} from "../../services/UserService";
import {NotificationResponse} from "../../modules/NotificationResponse";
import {BlogList} from "../../features/BlogList";


export const Support = ({profile}: any) => {

    const userId = Cookies.get("userId") || 0
    const { register, handleSubmit, setValue } = useForm()

    const [ sendRequest ] = userApi.useSetSupportMutation()
    const sendForm = async (data: any) => {
        await sendRequest({data, userId})
            .then((r: any) => NotificationResponse(r))
    }
    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        // @ts-ignore
        document.scrollingElement.scrollTop = 0;
        // mainContentRef.current.scrollTop = 0;
    }, []);
    return (
        <UI.Content>
            <UI.Container>
                <UI.SupportBlock>
                    <UI.AboutBlock>
                        <h1>Support</h1>
                    </UI.AboutBlock>
                    <UI.SupportContent>
                        <UI.SupportLeft>
                            <h2>Hello, my friend
                                <span>
                                    <h3>How can I help you?</h3>
                                </span>
                            </h2>
                            <img src={"/images/ceomainmobile.png"} alt={"ceo"}/>
                        </UI.SupportLeft>
                        <UI.FormSupport onSubmit={handleSubmit(sendForm)}>
                            <h2></h2>
                            <h3></h3>
                            <input type={"text"}
                                   disabled={true}
                                   value={profile?.user?.name}/>
                            <input type={"text"}
                                   style={{marginBottom: "40px"}}
                                   placeholder={"Title Request"}
                                   {...register("title")}/>
                            <textarea {...register('description')} placeholder={'Start typing...'}/>
                            <GoToButton
                                type={"submit"}
                                style={{margin: "20px -10px 0 0", float: "right",
                                    textTransform: "capitalize", width: "117px"}}
                                color={"white"}>Send
                                <img src={"/images/YellowRight.svg"} alt={'Right'}/>
                            </GoToButton>
                            <UI.CustomButtonRight type={"submit"}>
                                Send
                                <img src={"/images/BlackCircleRight.svg"} alt={'Right'}/>
                            </UI.CustomButtonRight>
                            <h3 style={{marginTop: "100px", bottom: "0", marginBottom: "-10px"}}></h3>
                        </UI.FormSupport>
                        <UI.SupportRight>
                            <img src={"/images/ceomain.png"} alt={"ceo"}/>
                        </UI.SupportRight>
                    </UI.SupportContent>
                </UI.SupportBlock>
                <BlogList/>
            </UI.Container>
        </UI.Content>
    )
}