import React, {useState} from 'react';
import * as UI from '../../style';
import {IncreaseBlocks} from "../../features/IncreaseBlocks";
import {FooterContent} from "../../style";
import {userApi} from "../../services/UserService";
import {useNavigate} from "react-router-dom";
import {otherApi} from "../../services/Other";
import moment from "moment"
import Cookies from "js-cookie";

type TTabs = "TRAVEL" | "DATE" | "ADVENTURE"
export const MainPage = () => {

    const [ tab, selectedTab ] = useState<TTabs>("TRAVEL")
    const [ selectedCEO, setSelectedCEO ] = useState<number>(0)
    const [ showVideo, setShowVideo ] = useState(false)
    const darkColor: string  = "linear-gradient(0deg, #464852, #464852), #FFFFFF;"
    const dataRequest = {
        size: 3,
    }
    const { data: increaseData, isLoading } = userApi.useGetSearchUsersIndexQuery(dataRequest)

    const [ size, setSize ] = useState<number>(3)
    const { data: blogList } = otherApi.useGetBlogListQuery(size, {
        refetchOnMountOrArgChange: true
    })

    const navigate = useNavigate()
    const selected = () => {
        switch (tab) {
            case "TRAVEL":
                return "/images/MainPageTravel.webp"
            case "DATE":
                return "/images/MainPageDate.webp"
            case "ADVENTURE":
                return "/images/MainPageAdventure.webp"
            default:
                return "/images/MainPageDate.webp"
        }
    }

    return (
        <>
            {showVideo ? <UI.VideoMainPage>
                    <img src={"/images/WhiteClose.svg"} alt={"close"}
                         onClick={() => setShowVideo(!showVideo)}/>
                    <video controls autoPlay={true}>
                        <source src={`https://myloveexpress-backend.ru/videos/index_video.mp4`}/>
                    </video>
                </UI.VideoMainPage>
                :
                <></>}
            <UI.MainBlock style={{backgroundImage: `url(${selected()})`}} selected={tab}>
                <UI.ContentBlock>
                    <h1>Travel the world</h1>
                    <h2>with the local partner that matches your vibe!</h2>
                    <UI.MatchesType>
                        <UI.TypeBlock background={tab === "TRAVEL" ? "white" : darkColor}
                                      color={tab !== "TRAVEL" ? "white" : "black"}
                                      onClick={() => selectedTab("TRAVEL")}>
                            <img src={"/images/type_travel.svg"} alt="travel"/>
                            <h3>TRAVEL</h3>
                        </UI.TypeBlock>
                        <UI.TypeBlock background={tab === "DATE" ? "white" : darkColor}
                                      color={tab !== "DATE" ? "white" : "black"}
                                      onClick={() => selectedTab("DATE")}>
                            <img src={"/images/type_date.svg"} alt="date"/>
                            <h3>DATE</h3>
                        </UI.TypeBlock>
                        <UI.TypeBlock background={tab === "ADVENTURE" ? "white" : darkColor}
                                      color={tab !== "ADVENTURE" ? "white" : "black"}
                                      onClick={() => selectedTab("ADVENTURE")}>
                            <img src={"/images/type_adventure.svg"} alt="adventure"/>
                            <h3>ADVENTURE</h3>
                        </UI.TypeBlock>
                    </UI.MatchesType>
                </UI.ContentBlock>
            </UI.MainBlock>
            <UI.MainPageContent>
                <UI.FeatureMainPageContent>
                    <img src={"/images/feature_1.svg"} alt="feature 1"/>
                    <img src={"/images/feature_2.svg"} alt="feature 2"/>
                    <img src={"/images/feature_3.svg"} alt="feature 3"/>
                </UI.FeatureMainPageContent>

                <UI.ShareContainerContent>
                    <UI.BlockAboutShare>
                        <h1>Meet People Around The World That Matches Your Vibe!</h1>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <h3>Find Your Travel Buddy</h3>
                            <img src={"/images/YellowRight.svg"} alt="Arrow Right"
                            onClick={() => navigate('/register')}/>
                        </div>
                    </UI.BlockAboutShare>
                    <IncreaseBlocks type={'default'} content={increaseData} size={3}/>
                </UI.ShareContainerContent>

                <UI.FeatureMainPageContent>
                    <UI.ReasonVideoBlock
                        style={{background: "url(/images/ReasonsVideo.png)"}}>
                        <img src={"/images/WatchPreview.svg"}
                             onClick={() => setShowVideo(!showVideo)}
                             alt={"Preview"}/>
                    </UI.ReasonVideoBlock>
                    <UI.ReasonsBlock>
                        <h1>
                            Reasons<br/>
                            Your traveling partner
                            can be your partner for life
                        </h1>
                        <ul>
                            <li><img src={"/images/BlackRight.svg"} alt={"Right"}/>
                                <span>A common goal and purpose</span></li>
                            <li><img src={"/images/BlackRight.svg"} alt={"Right"}/>
                                <span>Understanding and adjusting to their limitations</span>
                            </li>
                            <li><img src={"/images/BlackRight.svg"} alt={"Right"}/>
                                <span>It reveals their real self to each other</span>
                            </li>
                            <li><img src={"/images/BlackRight.svg"} alt={"Right"}/>
                                <span>Experiencing something new together</span>
                            </li>
                            <li><img src={"/images/BlackRight.svg"} alt={"Right"}/>
                                <span>They live the romance in the moment</span>
                            </li>
                            <li><img src={"/images/BlackRight.svg"} alt={"Right"}/>
                                <span>They become best of friends while experiencing
                                    the spark of coziness.</span>
                            </li>
                        </ul>
                    </UI.ReasonsBlock>
                </UI.FeatureMainPageContent>
            </UI.MainPageContent>
            <UI.Full1440px>
                <UI.MainPageContent>
                    <h1 style={{marginLeft: '10px', wordWrap: "break-word", width: "95%"}}>
                        Steps To Choose your ultimate and<br/>
                        trustworthy travel partner with us
                    </h1>
                </UI.MainPageContent>
                <UI.BestFeaturesContent>
                    <UI.BestLeft>
                        <div style={{backgroundImage: "url(/images/BestFeature1.svg)"}}>
                            <h2>01</h2>
                            <h4>Decide where you want to go and what you plan to visit</h4>
                        </div>
                    </UI.BestLeft>
                    <UI.BestRight>
                        <UI.BestRightTop>
                            <div style={{backgroundImage: "url(/images/BestFeature2.svg)"}}>
                                <h2>02</h2>
                                <h4>Write all hobbies, interests, and plans in your personal profile
                                </h4>
                            </div>
                            <div style={{backgroundImage: "url(/images/BestFeature3.svg)"}}>
                                <h2>03</h2>
                                <h4>Pick and post your best photos on your profile
                                </h4>
                            </div>
                        </UI.BestRightTop>
                        <UI.BestRightBottom>
                            <div style={{backgroundImage: "url(/images/BestFeature4.svg)"}}>
                                <h2>04</h2>
                                <h4>Choose a nickname that you would want your partner to know
                                </h4>
                            </div>
                            <div style={{backgroundImage: "url(/images/BestFeature5.svg)"}}>
                                <h2>05</h2>
                                <h4>Start your exciting journey of seeking the best partner that matches your vibe.
                                </h4>
                            </div>
                        </UI.BestRightBottom>
                    </UI.BestRight>
                </UI.BestFeaturesContent>
                <UI.BestFeaturesContentMobile>
                    <img src={"/images/BestFeatureMobile1.svg"} alt={'Memories '}/>
                    <img src={"/images/BestFeatureMobile2.svg"} alt={'Memories '}/>
                    <img src={"/images/BestFeatureMobile3.svg"} alt={'Memories '}/>
                    <img src={"/images/BestFeatureMobile4.svg"} alt={'Memories '}/>
                    <img src={"/images/BestFeatureMobile5.svg"} alt={'Memories '}/>
                </UI.BestFeaturesContentMobile>
            </UI.Full1440px>
            <UI.Full1440px>
                <UI.MainPageContent>
                    <h1 style={{marginLeft: '10px'}}>
                        Memories From Our<br/>
                        Instagram Feed
                    </h1>
                </UI.MainPageContent>
                <UI.MasonryGrid>
                    <div>
                        <img src={"/images/Memories1.webp"} alt={'Memories '}/>
                    </div>
                    <div>
                        <img src={"/images/Memories2.webp"} alt={'Memories '}/>
                    </div>
                    <div>
                        <img src={"/images/Memories3.webp"} alt={'Memories '}/>
                    </div>
                    <div>
                        <img src={"/images/Memories4.webp"} alt={'Memories '}/>
                    </div>
                    <div>
                        <img src={"/images/Memories5.webp"} alt={'Memories '}/>
                    </div>
                    <div>
                        <img src={"/images/Memories6.webp"} alt={'Memories '}/>
                    </div>
                </UI.MasonryGrid>
            </UI.Full1440px>
            <UI.MainPageContent>
                <UI.CEOBlock current={selectedCEO}>
                    <UI.AboutCEO>
                        <img src={"/images/CEO1.svg"} alt={'CEO'}/>
                        <div>
                            <p>
                                “Lorem ipsum dolor sit amet, deserunt eleifend
                                eloquentiam ut per posse constituto sententiae mea at”
                            </p>
                            <h3>Mei Ann Teo</h3>
                            <h4>Co-Director, Chronicle X</h4>
                        </div>
                    </UI.AboutCEO>
                    <UI.AboutCEO>
                        <img src={"/images/CEO2.svg"} alt={'CEO'}/>
                        <div>
                            <p>
                                “Lorem ipsum dolor sit amet, deserunt eleifend
                                eloquentiam ut per posse constituto sententiae mea at”
                            </p>
                            <h3>Mei Ann Teo</h3>
                            <h4>Co-Director, Chronicle X</h4>
                        </div>
                    </UI.AboutCEO>
                </UI.CEOBlock>
                <UI.PerfectTravel>
                    <UI.ContentPerfectTravel>
                        <h1>
                            Haks To Choose a perfect<br/>
                            travel partner
                        </h1>
                        <ul>
                            <li>
                                <span>01</span>
                                Pay attention to their habits,
                                likings, and area of interest.
                            </li>
                            <li>
                                <span>02</span>
                                Have something that’s common
                                between both of you.
                            </li>
                            <li>
                                <span>03</span>
                                Seek the values and opinions they have
                                about life and relationships.
                            </li>
                        </ul>
                    </UI.ContentPerfectTravel>
                    <img src={"/images/PerfectTravel.png"} alt={"Perfect Travel"}/>
                </UI.PerfectTravel>
                <UI.AboutCEOBlock>
                    <h1>
                        Post From
                        Our Blog
                    </h1>
                    <button type={"button"} onClick={() => navigate('/blog')}>
                        View All Post
                        <img src={"/images/YellowRight.svg"} alt={"Right"}/>
                    </button>
                </UI.AboutCEOBlock>
                <UI.BlogBlock>
                    {blogList?.data?.data.map((item: any, index: number) => {
                        return (
                            <UI.BlogItem>
                                <UI.BlogImage src={`https://myloveexpress-backend.ru/storage/${item?.preview}`} alt={"BlogImage"}/>
                                <div>
                                    <h4>
                                        <img src={"/images/BlackDate.svg"} alt={'Date'}/>
                                        {moment(item?.created_at).format("MMM Do YY")}
                                    </h4>
                                    <h3>{item?.title}</h3>
                                </div>
                                <UI.ShowPost type={"button"} onClick={() => {
                                    Cookies.set("blog_slug", item?.slug)
                                    navigate(`/blogs/${item.id}`)
                                }}>
                                    More
                                    <img src={"/images/ClearRight.svg"} alt={'right'}/>
                                </UI.ShowPost>
                            </UI.BlogItem>
                        )
                    })}
                </UI.BlogBlock>
            </UI.MainPageContent>

            <UI.ImageBlogJonUs>
               <UI.MainPageContent>
                   <h1>
                       Find Your Ultimate
                       travel partner and
                       travel the world
                   </h1>
                   <button type={"button"} onClick={() => navigate('/profile')}>
                       Join Us
                       <img src={"/images/YellowRight.svg"} alt={"Right"}/>
                   </button>
               </UI.MainPageContent>
            </UI.ImageBlogJonUs>

            <UI.FullWidthContent>
                <UI.MainPageContent>
                    <FooterContent>
                        <div>
                            <img src={"/images/logo.svg"} alt={"logo"}/>
                            <p>
                                There are many variations
                                of passages of Lorem Ipsum available,
                                but the majority have
                                suffered alteration in some form, by
                                injected humour or.
                            </p>
                        </div>
                        <div>
                            <h3>Services</h3>
                            <UI.FooterLink to={"#"}>Support</UI.FooterLink>
                            {/*<UI.FooterLink to={"#"}>Festival</UI.FooterLink>*/}
                            <UI.FooterLink to={"/profile"}>Premium</UI.FooterLink>
                            <UI.FooterLink to={"/profile"}>Join Member</UI.FooterLink>
                        </div>
                        <div>
                            <h3>More info</h3>
                            <UI.FooterLink to={"/privacy"}>Privacy Policy</UI.FooterLink>
                            <UI.FooterLink to={"/"}>About us</UI.FooterLink>
                            <UI.FooterLink to={"/terms"}>Terms & Conditions</UI.FooterLink>
                        </div>
                        <div>
                            <h3>Social</h3>
                            <UI.FooterLink to={"https://instagram.com/myloveexpress"} target={"_blank"}>Instagram</UI.FooterLink>
                            <UI.FooterLink to={"https://www.facebook.com/profile.php?id=100039700297500"} target={"_blank"}>Facebook</UI.FooterLink>
                        </div>
                    </FooterContent>
                </UI.MainPageContent>
            </UI.FullWidthContent>
        </>
    )
}