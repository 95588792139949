import React, {Dispatch, useEffect, useState} from 'react';
import * as UI from '../../style'
import { useForm } from "react-hook-form";
import {userApi} from "../../services/UserService";
import {TRegister} from "../../types/UserTypes";
import {DefaultNotify, ErrorNotify, SuccessNotify} from "../../Notifications";
import {useNavigate} from "react-router-dom";
import {NotificationResponse} from "../../modules/NotificationResponse";
import Cookies from "js-cookie";
import {RegisterSelect, Select} from "../../features/Select";
import {options, registerData} from "../../other/options";
import PhoneInput from 'react-phone-number-input/react-hook-form-input'
import {find} from "styled-components/test-utils";
interface IRegister {
    setToken: any
}
export const Register = ({setToken}: IRegister) => {
    const { register, handleSubmit, formState: { errors }, setValue, control} = useForm();
    const [ registerAccount ] = userApi.useRegisterMutation();
    const [ selectedLocation, setSelectedLocation ] = useState<any>()
    const [ listLocations, setListLocation ] = useState<any>([])
    const [ signIn ] = userApi.useLoginMutation()
    const navigate = useNavigate()
    const [ isLocation, setIsLocation ] = useState<boolean>(false)
    // Need update type when backend is updated.
    const onChangeInput = async (data: any) => {
        // @ts-ignore
        // const res = cities.filter((elem: any) => elem.name.includes(data))
        // if (res.length > 5) setListLocation(res.slice(0, 5))
        // else setListLocation(res)
        setSelectedLocation(null)
        setIsLocation(false)
        try {
            await fetch(`https://api.teleport.org/api/cities/?search=${data}`)
                .then((r: any) => r.json())
                .then((r: any) => setListLocation(r._embedded["city:search-results"].slice(0, 10)))
        } catch(e: any) {
            console.log(e)
        }
    }
    const select = async (data: any) => {
        // const country = new Intl.DisplayNames("en", {type: "region"}).of(data.country)
        // setValue('from', `${data.name}, ${country}`)
        // setSelectedLocation(data)
        // onChangeInput(data.name)
        await fetch(data._links["city:item"]?.href)
            .then((r: any) => r.json())
            .then((r: any) => {
                setSelectedLocation(r)
                setValue('from', `${r?.full_name}`)
            })
        setListLocation([])
    }
    const onSubmit = async (data: any) => {
        console.log(selectedLocation)
        if (selectedLocation) {
            const monthToNumber = () => {
                let res: any = ''
                for (const item of registerData.monthData) {
                    if (Object.keys(item)[0] === data.month) {
                        res = Object.values(item)[0]
                    }
                }
                return res
            }
            const date = `${data.year}-${monthToNumber()}-${data.day}`
            const findCountry = selectedLocation?.full_name?.lastIndexOf(',')
            const finalCountry = selectedLocation?.full_name?.slice(findCountry+2)
            // Remove when backend is updated
            const registerDataReq: TRegister = {
                name: data.username,
                email: data.email,
                phone: data.phone,
                password: data.password,
                password_confirmation: data.password_confirmation,
                birthday: date,
                gender: data.gender,
                city: data.from,
                about: data.about,
                privacy: data.privacy,
                notifications: data.notifications,
                location: {
                    city: selectedLocation?.name,
                    country: finalCountry,
                    latitude: selectedLocation?.location.latlon.latitude,
                    longitude: selectedLocation?.location.latlon.longitude,
                }
            }
            await registerAccount(registerDataReq).then((r: any) => {
                if (r.error) {
                    ErrorNotify(r.error.data.message)
                }
                else if (r?.data?.success) {
                    const loginData = {
                        email: data.email,
                        password: data.password
                    }
                    signIn(loginData).then((response: any) => {
                        Cookies.set("userId", response?.data?.data.id, {expires: 1})
                        setToken(Cookies.get('token_mle'))
                        if (response.hasOwnProperty('error')) {
                            ErrorNotify(response.error?.data?.message);
                        } else {
                            SuccessNotify(`Welcome`);
                            navigate('/profile')
                        }
                    })

                }
            })
        } else {
            setIsLocation(true)
        }
    }

    const requiredList = ['username', 'email', 'password', 'day', 'month', 'year']
    return (
        <UI.MainBlockAuth style={{backgroundImage: 'url(/images/BackgroundAuth.png)'}}>
            <UI.ControlContentBlock>
                <img src={"/images/logo.svg"} alt={"logo"} onClick={() => navigate('/')}/>
                <UI.BlockAuth>
                    <UI.FormAuth onSubmit={handleSubmit(onSubmit)}>
                        <h2>Create your account</h2>
                        <h4>or
                            <UI.MenuItem to={"/signin"}> Sign in</UI.MenuItem>
                        </h4>
                        <label>Your name</label>
                        {errors.username && <span>This field is required</span>}
                        <input type="text" {...register("username", { required: true })} />
                        <label>Your e-mail</label>
                        {errors.email && <span>This field is required</span>}
                        <input type="text" {...register("email", { required: true })} />
                        <label>Your number</label>
                        {errors.number && <span>This field is required</span>}
                        <PhoneInput
                            name="phone"
                            control={control}
                            rules={{ required: true }} />
                        <label>Your password</label>
                        {errors.password && <span>This field is required</span>}
                        <input type="password" {...register("password", { required: true })} />
                        <label>Confirm password</label>
                        {errors.password && <span>This field is required</span>}
                        {errors.password_confirmation && <span>This field is required</span>}
                        <input type="password" {...register("password_confirmation", { required: true })} />
                        <h3 style={{fontWeight: "bolder", marginTop: "0"}}>Date of birth</h3>
                        <UI.DateInRegister>
                            <RegisterSelect register={register} options={registerData.date} name={"day"}/>
                            <RegisterSelect register={register} options={registerData.month} name={"month"}/>
                            <RegisterSelect register={register} options={registerData.years} name={"year"}/>
                        </UI.DateInRegister>
                        <label>I am from...</label>

                        <input type="textarea"
                               onInput={(e: any) => onChangeInput(e.target?.value)}
                               {...register("from")} style={{marginBottom: "0"}}/>
                        {isLocation && <span>This field is required</span>}
                        <UI.LocationList>
                            {listLocations.map((item: any, index: number) => {
                                return (
                                    <h5 key={index}
                                        onClick={() => select(item)}>{item?.matching_full_name}</h5>
                                )
                            })}
                        </UI.LocationList>
                        <RegisterSelect register={register} options={options.gender} name={"gender"}/>
                        {errors.gender && <span>This field is required</span>}
                        <UI.CheckBoxInRegister>
                            <input type="checkbox" {...register("privacy", { required: true })}/>
                            <label>By continuing, you are confirming that you have read
                                and agree to our Terms and Conditions and Privacy Policy</label>
                        </UI.CheckBoxInRegister>
                        <UI.CheckBoxInRegister>
                            <input type="checkbox" {...register("notifications")}/>
                            <label>I agree to receive notifications from the site</label>
                        </UI.CheckBoxInRegister>
                        <div style={{display: "flex", justifyContent: "end"}}>
                            <input type="submit" value="Create your account" />
                        </div>
                    </UI.FormAuth>
                </UI.BlockAuth>
            </UI.ControlContentBlock>
        </UI.MainBlockAuth>
    )
}