import React, {useEffect, useId, useState} from 'react';
import * as UI from "./ProfileStyles";
import {useForm} from "react-hook-form";
import {Select} from "../../features/Select";
import {options} from "../../other/options";
import {profileApi} from "../../services/Profile";
import {BasicElement} from "../../features/BasicElement";
import {Spinner} from "../Spinner";
interface IProfile {
    profile: any,
    userId: any,
    setNeedRefetch: any
}
export const ProfileData = ({profile, userId, setNeedRefetch}: IProfile) => {
    const formProfileData = useId()
    const { register, handleSubmit } = useForm()
    const [ updateProfileData ] = profileApi.useUpdateProfileDataMutation()
    const updateForm = async (data: any) => {
        await updateProfileData({userId, data})
            .then(() => setButtonStatus(!buttonStatus))
            .then(() => setNeedRefetch((prevState: boolean) => !prevState))
    }
    const [buttonStatus, setButtonStatus] = useState<boolean>(false)
    return (
        <UI.ProfileDataBlock>
            <UI.FormDataProfile onSubmit={handleSubmit(updateForm)} id={formProfileData}>
                <div>
                    <UI.TopBlockProfileData>
                        <h3>Basics</h3>
                        <div>
                            {buttonStatus ?
                                <UI.ButtonSave type={"submit"} form={formProfileData}>
                                    Save
                                    <img src={"/images/YellowPencil.svg"} alt={"Edit Button"}/>
                                </UI.ButtonSave>
                                : <span></span>}
                            {profile?.my ?
                                <UI.CustomButtonForm
                                    onClick={() => setButtonStatus(!buttonStatus)}
                                    status={buttonStatus} type={"button"}>
                                    {buttonStatus ? 'Cancel' : 'Edit'}
                                    <img src={!buttonStatus ? "/images/YellowPencil.svg" : "/images/YellowClose.svg"} alt={"Edit Button"}/>
                                </UI.CustomButtonForm>
                            :
                                <></>}
                        </div>
                    </UI.TopBlockProfileData>
                    {buttonStatus ?
                        <UI.DataSelect>
                            <Select register={register} options={options.height} name={"height"}
                                    data={profile?.information.basic.height}/>
                            <Select register={register} options={options.custom} name={"smoke"}
                                    data={profile?.information.basic.smoke}/>
                            <Select register={register} options={options.education} name={"education"}
                                    data={profile?.information.basic.education}/>
                            <Select register={register} options={options.relationship} name={"relationship"}
                                    data={profile?.information.basic.relationship}/>
                            <Select register={register} options={options.zodiac} name={"zodiac_sign"}
                                    data={profile?.information.basic.zodiac_sign}/>
                            <Select register={register} options={options.children} name={"children"}
                                    data={profile?.information.basic.children}/>
                        </UI.DataSelect>
                        :
                        <UI.DataSelect>
                            <BasicElement label={'Height'} name={profile?.information.basic.height}/>
                            <BasicElement label={'Smoke'} name={profile?.information.basic.smoke}/>
                            <BasicElement label={'Education'} name={profile?.information.basic.education}/>
                            <BasicElement label={'Relationship'} name={profile?.information.basic.relationship}/>
                            <BasicElement label={'Zodiac'} name={profile?.information.basic.zodiac_sign}/>
                            <BasicElement label={'Children'} name={profile?.information.basic.children}/>
                        </UI.DataSelect>}
                </div>
            </UI.FormDataProfile>
        </UI.ProfileDataBlock>
    )
}

export const ProfileInterests = ({profile, userId, setNeedRefetch}: IProfile) => {
    const formInterests = useId()
    const { handleSubmit } = useForm()
    const { data: interests, isError, isLoading } = profileApi.useGetInterestsQuery(userId)
    const [ arrayInterests, setArrayInterests ] = useState<string[]>([])
    const [ updateInterests ] = profileApi.useUpdateInterestsMutation()
    const updateForm = async () => {
        await updateInterests({userId, arrayInterests})
            .then(() => setButtonStatus(!buttonStatus))
            .then(() => setNeedRefetch((prevState: boolean) => !prevState))
    }
    const [buttonStatus, setButtonStatus] = useState<boolean>(false)

    const addInArray = (interest: string) => {
        if (arrayInterests.includes(interest)) {
            setArrayInterests((prevState: string[]) => prevState.filter((item: string) => item !== interest ))
        } else {
            setArrayInterests((prevState: string[]) => [...prevState, interest])
        }
    }
    useEffect(() => {
        if (profile?.interests !== null) setArrayInterests(profile?.interests)
    }, [profile])
    if (isLoading) return <Spinner/>
    return (
        <UI.ProfileDataBlock>
            <UI.FormDataProfile onSubmit={handleSubmit(updateForm)}
                                id={formInterests}
                                style={{justifyContent: "start"}}>
                <div>
                   <UI.TopBlockProfileData>
                       <h3>Interests</h3>
                       <div>
                           {buttonStatus ?
                               <UI.ButtonSave type={"submit"} form={formInterests}>
                                   Save
                                   <img src={"/images/YellowPencil.svg"} alt={"Edit Button"}/>
                               </UI.ButtonSave>
                               : <span></span>}
                           {profile?.my ?
                               <UI.CustomButtonForm
                                   onClick={() => setButtonStatus(!buttonStatus)}
                                   status={buttonStatus} type={"button"}>
                                   {buttonStatus ? 'Cancel' : 'Edit'}
                                   <img src={!buttonStatus ? "/images/YellowPencil.svg" : "/images/YellowClose.svg"} alt={"Edit Button"}/>
                               </UI.CustomButtonForm>
                           :
                                <></>}
                       </div>
                   </UI.TopBlockProfileData>
                    <UI.InterestTypeContent>
                        {arrayInterests?.map((item: string, index:number) => {
                            return(
                                <UI.InterestsItem status={arrayInterests.includes(item)}
                                                  key={index}
                                                  onClick={() =>
                                                      addInArray(item)}>
                                    {item}
                                </UI.InterestsItem>
                            )
                        })}
                    </UI.InterestTypeContent>
                </div>
                {buttonStatus ?
                    <UI.InterestsBlock>
                        {interests?.data.map((item: {name: string, interests: string[]}, index: number) => {
                            return (
                                <div key={index}>
                                    <UI.InterestType>{item.name}</UI.InterestType>
                                    <UI.InterestTypeContent>
                                        {item.interests.map((interest: string, index:number) => {
                                            return(
                                                <UI.InterestsItem status={arrayInterests?.includes(interest)}
                                                                  key={index}
                                                                  onClick={() =>
                                                                      addInArray(interest)}>
                                                    {interest}
                                                </UI.InterestsItem>
                                            )
                                        })}
                                    </UI.InterestTypeContent>
                                </div>
                            )
                        })}
                    </UI.InterestsBlock>
                :
                <></>}
            </UI.FormDataProfile>
        </UI.ProfileDataBlock>
    )
}