import React, {Dispatch, useState} from 'react';
import * as UI from "./ProfileStyles";
import {options} from "../../other/options";
import {useForm} from "react-hook-form";

interface IFilterSearch {
    ageFrom: number,
    ageTo: number,
    setAgeFrom: Dispatch<number>,
    setAgeTo: Dispatch<number>,
    setShowFilter: Dispatch<boolean>,
    heightFrom: any,
    setHeightFrom: any,
    heightTo: any,
    setHeightTo: any,
    relation: any,
    setRelation: any,
    smoke: any,
    setSmoke: any,
    zodiac: any,
    setZodiac: any,
    education: any,
    setEducation: any,
    children: any,
    setChildren: any,
    gender: any,
    setGender: any
}
export const FilterSearchModal = ({ageFrom, ageTo, setAgeTo, setAgeFrom, setShowFilter, heightFrom, heightTo, setHeightFrom, setHeightTo, children,
                                      setChildren, education, setEducation, relation, setRelation, setSmoke,gender, setGender, smoke, zodiac, setZodiac}: IFilterSearch) => {

    const [ age1, setAge1 ] = useState(0)
    const [ age2, setAge2 ] = useState(0)
    const [ education1, setEducation1 ] = useState('None')

    const { register, handleSubmit } = useForm()
    const update = (data: any) => {
        setAgeFrom(age1)
        setAgeTo(age2)
        setHeightFrom(data.height_from)
        setHeightTo(data.height_to)
        if (data.children === 'None') {
            setChildren('')
        } else {
            setChildren(data.children)
        }
        if (data.zodiac === 'None') {
            setZodiac('')
        } else {
            setZodiac(data.zodiac)
        }
        if (data.education === 'None') {
            setEducation('')
        } else {
            setEducation(data.education)
        }
        if (data.smoke === 'None') {
            setSmoke('')
        } else {
            setSmoke(data.smoke)
        }
        // if (data.gender === 'None') {
        //     setGender('')
        // } else {
        //     setGender(data.gender)
        // }
        if (data.relation === 'None') {
            setRelation('')
        } else {
            setRelation(data.relation)
        }
        setShowFilter(false)
    }

    const changeChildren = (event: any) => {
        console.log(event)
    }
    return (
        <UI.FilterSearchModal>
            <UI.ContentFilter>
                <form onSubmit={handleSubmit(update)}>
                    <UI.TopPanelSearchModal>
                        <div>
                            <img src={"/images/YellowFilterSearch.svg"}
                                 alt={"Search Icon"}/>
                            <h5>Filter search</h5>
                        </div>
                        <img src={"/images/CloseSVG.svg"} alt={"close"}
                             onClick={() => setShowFilter(false)}/>
                    </UI.TopPanelSearchModal>
                    <UI.AgeBlock>
                        <div>
                            <label>Age from</label>
                            <input type={"number"}
                                   placeholder={ageFrom.toString()}
                                   onChange={(e) => setAge1(+e.target.value)}/>
                        </div>
                        <div>
                            <label>Age To</label>
                            <input type={"number"}
                                   placeholder={ageTo.toString()}
                                   onChange={(e) => setAge2(+e.target.value)}/>
                        </div>
                    </UI.AgeBlock>
                    <UI.AgeBlock>
                        <div>
                            <label>Height from (cm)</label>
                            <input type={"number"}
                                   min={152}
                                   placeholder={heightFrom.toString()}
                                   {...register('height_from', {value: 152})}/>
                        </div>
                        <div>
                            <label>Height to (cm)</label>
                            <input type={"number"}
                                   {...register('height_to', {value: 213})}
                                   placeholder={heightTo.toString()}/>
                        </div>
                    </UI.AgeBlock>
                    <UI.AgeBlock>
                        <div>
                            <label>Children</label>
                            <UI.SelectInSearch
                                               {...register('children', {value: children})}
                            >
                                {options.children.map((value: string, index: number) => (
                                    <option value={value} defaultValue={''}  key={index}>{value}</option>
                                ))}
                            </UI.SelectInSearch>
                        </div>
                        <div>
                            <label>Zodiac</label>
                            <UI.SelectInSearch
                                {...register('zodiac', {value: zodiac})}
                            >
                                {options.zodiac.map((value: string, index: number) => (
                                    <option value={value} defaultValue={''}  key={index}>{value}</option>
                                ))}
                            </UI.SelectInSearch>
                        </div>
                    </UI.AgeBlock>
                    <UI.AgeBlock>
                        <div>
                            <label>Education</label>
                            <UI.SelectInSearch
                                {...register('education', {value: education})}
                            >
                                {options.education.map((value: string, index: number) => (
                                    <option value={value} defaultValue={''}  key={index}>{value}</option>
                                ))}
                            </UI.SelectInSearch>
                        </div>
                        <div>
                            <label>Smoke</label>
                            <UI.SelectInSearch
                                {...register('smoke', {value: smoke})}
                            >
                                {options.custom.map((value: string, index: number) => (
                                    <option value={value} defaultValue={''}  key={index}>{value}</option>
                                ))}
                            </UI.SelectInSearch>
                        </div>
                    </UI.AgeBlock>
                    <UI.AgeBlock>
                        {/*<div>*/}
                        {/*    <label>Gender</label>*/}
                        {/*    <UI.SelectInSearch*/}
                        {/*        {...register('gender', {value: gender})}*/}
                        {/*    >*/}
                        {/*        <option value={'None'} defaultValue={''}>{'None'}</option>*/}
                        {/*        {options.gender.map((value: string, index: number) => (*/}
                        {/*            <option value={value} defaultValue={''}  key={index}>{value}</option>*/}
                        {/*        ))}*/}
                        {/*    </UI.SelectInSearch>*/}
                        {/*</div>*/}
                        <div>
                            <label>Relationship</label>
                            <UI.SelectInSearch
                                {...register('relation', {value: relation})}
                            >
                                {options.relationship.map((value: string, index: number) => (
                                    <option value={value} defaultValue={''}  key={index}>{value}</option>
                                ))}
                            </UI.SelectInSearch>
                        </div>
                    </UI.AgeBlock>
                    <UI.ButtonUpdate type={"submit"}>
                        <h5>
                            Update
                        </h5>
                    </UI.ButtonUpdate>
                </form>
            </UI.ContentFilter>
        </UI.FilterSearchModal>
    )
}