import React, {useEffect, useState} from 'react';
import * as UI from "./style";
import {useNavigate} from "react-router-dom";
import {ShowStories} from "./ShowStoires";
import {userApi} from "../services/UserService";
import {Spinner} from "../components/Spinner";

interface IUserStory {
    image: string,
    userImage: string,
    userName: string,
    url: string
}

export const checkFormat = (url: string) => {
    const photoFormats = ['jpeg', 'png', 'jpg']
    const checkType = url?.lastIndexOf('.')
    const resultType = url?.slice(checkType + 1)
    return photoFormats.includes(resultType)  ? 'image' : 'video'
}
export const Stories = () => {
    const navigate = useNavigate()
    const [currentIndex, setCurrentIndex] = useState<number>(0)
    const [lastIndex, setLastIndex] = useState<number>(0)

    const swipeLeft = () => {
        setCurrentIndex((prevState: number) => prevState - 1)
        navigate(`/profile#${currentIndex-1}`)
    }
    const swipeRight = () => {
        setCurrentIndex((prevState: number) => prevState + 1)
        navigate(`/profile#${currentIndex+1}`)
    }
    const { data: stories, isLoading } = userApi.useGetStoriesQuery(undefined, {
        refetchOnMountOrArgChange: true
    })
    const [ showStories, setShowStories ] = useState<boolean>(false)

    const [ storiesArray, setStoriesArray ] = useState<any>([])

    useEffect(() => {
        const arr : any = []
        stories?.data.map((user: any) => {
            user.stories.map((story: any) => {
                if (!arr.find((elem: any) => elem.url === story.url)) {
                    arr.push({
                        name: user.name,
                        url: story.url,
                        duration: 5000,
                        type: checkFormat(story.url),
                        header: {
                            heading: user.name,
                            subheading: '',
                            profileImage: user?.avatar_url || "/imags/NoAvatar.png"
                        }
                    })
                }
            })
        })
        setStoriesArray(arr)
    }, [stories])
    const findCurrent = (story: any) => {
        setLastIndex(storiesArray?.findIndex((elem: any) => elem.name === story.name))
    }


    if (isLoading) return <Spinner/>

    return (
        <>
            {showStories ?
                <ShowStories storiesArray={storiesArray} setShowStories={setShowStories} index={lastIndex}/>
                :
                <>
                </>
            }
            <UI.Stories>
                {stories?.data.length > 8 ?
                    <UI.SwipeLeft src={"/images/CaretLeft.svg"} alt={"Swipe Left"}
                    onClick={() => swipeLeft()}/>
                :
                <></>
                }
                {/*<UI.Story image={`/images/StoryTest5.png`}>*/}
                {/*    <img src={`/images/YellowPlus.svg`} alt={'user'}/>*/}
                {/*    <span>Add Story</span>*/}
                {/*</UI.Story>*/}
                {stories?.data.map((story: any, index: number) => {
                    return(
                        <UI.Story image={story?.avatar_url || '/images/NoAvatar.png'}
                                  onClick={() => {
                                      findCurrent(story)
                                      // selectedIndex(index)
                                      setShowStories(!showStories)
                                  }}
                                  id={`#${index}`}
                                  key={index}>
                            <img src={checkFormat(story?.stories[0]?.url) === 'image' ? `${story?.stories[0]?.url}` : story?.avatar_url || '/images/NoAvatar.png'} alt={'user'}/>
                            <span>{story?.userName}</span>
                        </UI.Story>
                    )
                })}
                {stories?.data.length > 8 ?
                    <UI.SwipeRight src={"/images/CaretRight.svg"} alt={"Swipe Right"}
                                   onClick={() => swipeRight()}/>
                :
                <></>
                }
            </UI.Stories>
        </>
    )
}