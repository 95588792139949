import styled from "styled-components";
import {Link} from "react-router-dom";

// Increase Block

export const BlockDefault = styled.div<{current: string}>`
  display: flex;
  justify-content: start;
  overflow: hidden;
  width: 100%;
  transition: all 0.3s ease;
  z-index: 0;
  @media(max-width: 1000px){
    justify-content: ${(props: any) => props.current};
    width: 100%;
    align-items: center;
  }
  @media(max-width: 600px) {
    width: 100% !important;
    justify-content: ${(props: any) => props.current};
  }
`

export const CurrentLineBlock = styled.div<{status: number}>`
  display: none;
  justify-content: space-between;
  margin-top: 30px;
  width: 100%;
  align-items: center;
  span{
    color: red;
    border-bottom: 2px solid gray;
    border-top: 2px solid white;
    padding: 0 30px;
    transition: all 0.3s ease;
    :nth-child(${(props: any) => +props.status + 1}) {
      border-bottom: 5px solid black !important;
    }
    :hover{
      cursor: pointer;
    }
  }
  @media(max-width: 600px) {
    display: flex;
    justify-content: center;
  }
  img{
    margin: 0 20px;
  }
  :hover{
    img{
      cursor: pointer;
    }
  }
`
export const AboutIncreaseUser = styled.div`
  overflow: hidden;
  padding-left: 10px;
  width: 100% !important;
  height: auto !important;
  display: flex !important;
  flex-direction: column !important;
  align-self: start !important;
  align-items: start !important;
  position: relative;
  box-sizing: border-box;
  h2{
    font-size: 1.1816666666666667em;
    font-weight: 700;
    color: white;
    line-height: 30px;
    margin: 0;
  }
  h4{
    margin: 0 10px 10px 0;
    font-family: Proxima Nova, sans-serif !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    color: white;
  }
  div{
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start !important;
    margin-bottom: 20px;
    margin-top: 10px;
    h4{
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
`

export const AboutUser = styled.h4`
  margin: 0 10px 10px 0;
  font-family: Proxima Nova, sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
`
export const IncreaseBlock = styled.div<{status: number, type: string, size: number, image: string}>`
  min-height: ${(props: any) => props.type === 'profile' ? '503px' : '453px'};
  height: 100%;
  max-height: 700px;
  transition: all 0.3s ease;
  box-sizing: border-box;
  background-size: cover;
  background-position-x: center;
  background-position: center !important;
  margin-right: 20px;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: end;
  scroll-behavior: revert;
  width: 25%;
  position: relative;
  background: linear-gradient(180deg, rgba(10, 6, 35, 0.00) 0%, rgba(10, 6, 35, 0.55) 100%),
  url(${(props: any) => props.image}), lightgray no-repeat;
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply, normal;
  z-index: -1 !important;
  :nth-child(${(props: any) => +props.status + 1}) {
    width: 50% !important;
    position: relative;
    ::before{
      content: "";
      position: absolute;
      top: 0; left: 0;
      width: 100%; 
      height: 100% !important;
      background-image: url(${(props: any) => props.image});
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 40px;
      filter: brightness(70%) blur(0.3px);
    }
    ${AboutIncreaseUser} {
      border-radius: 40px;
      background: linear-gradient(180deg, rgba(10, 6, 35, 0.00) 0%, #0A0623 100%),
      url(${(props: any) => props.image}), lightgray no-repeat;
      background-size: cover;
      background-blend-mode: multiply, normal;
      background-position: center !important;
      padding-right: -10px !important;
      margin-right: 10px !important;
      height: 100% !important;
      justify-content: end;
      div{
        h4{
          display: flex;
          align-items: center;
        }
      }
    }
  }
  ::-webkit-scrollbar {
    width: 15px;
    background-color: #F5F5F5;
  }
  :last-child{
    margin-right: 0;
  }
  @media(min-width: 2000px) {
    min-height: ${(props: any) => props.type === 'profile' ? '800px' : '500px'}
  }

  @media(max-width: 600px) {
    width: 150px !important;
    min-width: 150px;
    
    :nth-child(${(props: any) => +props.status + 1}) {
      display: flex;
      margin: 0 auto;
      width: 250px !important;
      min-width: 250px;
      margin-right: 10px;
    }
  }

`



export const MoreExplorerButton = styled.div`
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  align-self: end;
  margin-right: 30px;
  border: none;
  h4{
    margin-top: 10px;
  }
  img{
    :hover{
      cursor: pointer;
    }
  }
`

// Buttons

export const GoToButton = styled.button`
  text-transform: uppercase;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 135px;
  border-radius: 25px;
  background: white;
  margin-top: 10px;
  font-size: 20px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  padding: 5px 7px 5px 10px;
  img{
    margin-right: 0;
  }
  :hover{
    cursor: pointer;
  }
`
export const MapsContent = styled.div`
  width: 100%;
  height: 300px;
  box-sizing: content-box;
  overflow: hidden;
  border-radius: 40px;
  margin-top: 20px;
  z-index: 0 !important;
  position: relative;
`
// Stories

export const UserOnMap = styled.div`
  :hover{
    cursor: pointer;
  }
`

export const ShowStoriesBlock = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: #464852CC;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StoriesList = styled.div`
  width: 528px;
  height: 80vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  :hover{
    img{
      cursor: pointer;
    }
  }

  div{
    
  }
  @media(max-width: 600px) {
    width: 100vw;
    height: 100vh;
  }
`

export const StoriesBlock = styled.div`
  
`

export const StoriesAboutUser = styled.div`

`
export const CustomBlockStories = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  :hover{
    img{
      cursor: pointer;
    }
  }
  img{
    margin-left: -30px;
    z-index: 10000;
    margin-top: 15px;
  }
`

export const StoryInList = styled.div`
  height: 100%;
  border: 1px solid green;
  width: 100%;
  background-position: center;
  object-fit: cover;
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: black;
  
  
`
export const Stories = styled.div`
  width: 100%;
  min-width: 100%;
  display: flex;
  box-sizing: border-box;
  overflow-x: auto;
  scroll-behavior: revert;
  white-space: nowrap;
  margin-bottom: 20px;
  ::-webkit-scrollbar {
    width: 2px;
    height: 5px;
    background-color: #F5F5F5;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: lightgray;
  }
`

export const SwipeLeft = styled.img`
  position: sticky;
  left: 10px;
  width: 100px !important;
  height: 20px;
  margin-left: -20px;
  margin-top: 40px;
  :hover{
    cursor: pointer;
  }
  z-index: 5;
`

export const SwipeRight = styled(SwipeLeft)`
  left: 0;
  right: 25px;
  margin-right: 20px;
  margin-left: 0;
  z-index: 5;
`
export const Story = styled.div<{image: string}>`
  background: url(${(props: any) => props.image});
  background-position: center;
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 160px;
  min-width: 160px;
  height: 108px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 20px;
  :hover{
    cursor: pointer;
  }
  span{
    color: white;
  }
  :last-child{
    margin-right: 0 !important;
  }
  img{
    width: 40px;
    height: 40px;
    border-radius: 50px;
    object-fit: cover;
    stroke-width: 1px;
    stroke: #FEEF34;
    border: 1px solid #FEEF34;
  }
`

// Trips

export const Trips = styled.div`
  margin: 10px;
  min-width: 290px;
  background: #FFFFFF;
  background-repeat: no-repeat;
  background-position: 60% 88%;
  background-size: 100px 90px;
  border: 1px solid #EEF0ED;
  max-height: 251px;
  height: 261px;
  overflow: hidden;
  //height: 100%;
  border-radius: 10px 10px 30px 30px;
  @media(max-width: 700px) {
    min-width: auto;
  }
  @media(max-width: 600px) {
    width: 100%;
    margin: 0;
    margin-bottom: 10px;
    min-width: 200px !important;
  }
`

export const AboutTripBlock = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  width: 100%;
  background: linear-gradient(0deg, #464852, #464852), #DAF433;
  border-radius: 15px;
  h4{
    margin-left: 15px;
    color: white;
  }
  img{
    margin-left: 5px;
  }
  @media(max-width: 600px) {
    width: 100%;
  }
`

export const TripScrollBlock = styled.div`
  hr{
    margin: 1px;
    border-top: 1px solid #EEF0ED;
  }
  height: 152px;
  overflow: hidden;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 2px;
    background-color: #F5F5F5;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: lightgray;
  }
`

export const NoDataTrips = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  max-width: 230px;
  height: 150px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  h4{
    
    color: #7F7F7F;
    font-weight: normal;
  }
  h6{
    margin: 0;
    color: #464852;
  }
`

export const LeftBlockTrip = styled.div`
  display: flex !important;
  align-items: center;
  width: 80%;
  height: 35px;
  div{
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    h5{
      margin-top: 3px;
      margin-bottom: 0;
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
    }
    h6{
      margin-top: 2px;
      margin-bottom: 1px;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }
  }
  img{
    margin-left: 5px;
    width: 20px;
    :last-child{
      :hover{
        cursor: pointer;
      }
    }
  }
`
export const Trip = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 29px;
  img{
    :hover{
      cursor: pointer;
    }
  }
`
export const BottomBlockTrip = styled.div`
  display: flex;
  align-items: center;
  margin-top: -10px;
  margin-bottom: 10px;
  img{
    margin-left: 30px;
    margin-top: 5px;
  }
  @media(max-width: 1560px) {
    img{
      margin-left: 10px;
    }
  }
`
export const AddTrip = styled.button`
  background: linear-gradient(0deg, #FAFAFA 0%, #FAFAFA 100%), #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px 7px 5px 10px;
  border-radius: 25px;
  background: var(--ffffff, #FFF);
  box-shadow: 0px 8px 15px 0px rgba(0, 0, 0, 0.10);
  border: none;
  color: #464852;
  margin-left: 10px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    border: none;
    padding: 0 !important;
    margin: 0 !important;
    margin-left: 0 !important;
  }
  :hover{
    cursor: pointer;
  }
  @media(max-width: 600px) {
    font-family: Proxima Nova, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 10px;
    img{
      padding: 2px;
    }
  }
`

// MEDIA

export const MediaContent = styled.div`
  margin-top: 25px;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #EEF0ED;
  border-radius: 30px;
  height: 203px;
  padding: 15px;
  overflow: hidden;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    width: 2px;
    height: 5px;
    background-color: #F5F5F5;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: lightgray;
  }
  video{
    height: 173px !important;
    width: 90% !important;
    border-radius: 24px;
    margin-right: 24px;
    object-fit: cover;
    max-width: 200px;
    :hover{
      cursor: pointer;
    }
  }
`


export const NoMediaContent = styled.div`
  margin-top: 25px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background: white;
  border-radius: 30px;
  h2{
    font-weight: bolder !important;
  }
  h4{
    font-weight: normal;
    margin-top: 0;
  }
`
export const MediaImage = styled.img`
  height: 173px !important;
  width: 90% !important;
  border-radius: 24px;
  margin-right: 24px;
  object-fit: cover;
  max-width: 200px;
  :hover{
    cursor: pointer;
  }
`

export const MediaMoreBlock = styled.div`
  width: 245px;
  height: 173px;
  border-radius: 24px;
  margin-right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`


export const PopUpShowMedia = styled.div`
  display: grid;
  background-color: white;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  padding: 20px;
  max-height: 700px;
  ${MediaImage} {
    margin: 10px;
    width: 23%;
    height: 300px;
  }
  overflow: hidden;
  overflow-y: scroll;
  @media(max-width: 1410px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media(max-height: 720px) {
    height: 90vh;
  }
  @media(max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }
`
// POPUP MEDIA

export const PopUpBlock = styled.div`
  background: rgba(101,101,101,0.3981967787114846) 100%;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: start;
  padding-top: 5%;
`

export const PopUpContent = styled.div<{height: string}>`
  width: ${(props: any) => props.height === 'default' ? '712px' : '500px'};
  border-radius: 25px;
  background: white;
  div{
    margin-top: 10px;
    width: 100%;
  }
  span{
    position: sticky;
    float: right;
    margin-top: -50px;
    margin-right: 20px;
    :hover{
      cursor: pointer;
    }
  }
  @media(max-width: 600px) {
    width: 95% !important;
  }
`

export const ManageContent = styled.div`
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  @media(max-width: 600px) {
    h1{
      color: #464852;
      text-align: center;
      /* text 20/bold */
      font-family: Proxima Nova, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 120% */
    }
  }
`
export const ProfileSettingsContent = styled.div`
  display: flex;
  justify-content: start !important;
  text-align: start;
  padding: 30px;
  img {
    width: 73px;
    height: 76px;
    object-fit: cover;
  }
  
  div{
    margin-left: 24px;
    height: 73px;
    display: flex;
    margin-top: 0;
    justify-content: start;
    word-wrap: break-word;
    flex-direction: column;
    h1{
      width: 90%;
      margin-top: 0;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 10px;
    }
    h4{
      margin-top: 0;
      color: #464852;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }
  }
  margin-bottom: 20px;
  span{
    margin-top: -10px !important;
    margin-right: 40px;
    img{
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
  }
`

export const FormProfileSettings = styled.form`
    padding: 0 30px 30px 30px;
`

export const TrashLabel = styled.h3`
    margin-top: 10px !important;
  font-size: 16px;
  font-family: Proxima Nova, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 0 !important;
`

export const  CustomInputElem = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  label{
    font-size: 16px;
    font-family: Proxima Nova, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: #464852;
    margin-bottom: 3px;
  }
  input {
    font-size: 16px;
    font-family: Proxima Nova, sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    color: black;
    margin-left: -2px;
    ::placeholder{
      font-size: 16px;
      font-family: Proxima Nova, sans-serif;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      color: black;
    }
    outline: none;
    border: none;
    padding-bottom: 3px;
    border-bottom: 1px solid #A196F4;
  }
`
export const UploadMediaBlock = styled.div`
  width: 80% !important;
  border: 3px dashed #EEF0ED;
  z-index: 1000;
  border-radius: 25px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -20px !important;
  :hover{
    cursor: pointer;
  }
  img{
    margin-top: -20px;
  }
  button {
    display: flex;
    background: none;
    align-items: center;
    justify-content: center;
    border: none;
    height: 40px;
    font-size: 20px;
    line-height: 24px;
    color: black;
    img{
      margin-top: 0;
    }
    :hover{
      cursor: pointer;
    }
  }
  h5{
    width: 70%;
    text-align: center;
    color: #7F7F7F;
    /* text 14/regular */
    font-family: Proxima Nova, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 114.286% */
  }
  form{
    input{
      width: 572px;
      height: 200px;
      text-align: center;
      margin-bottom: -200px;
      opacity: 0;
      z-index: 0;
      :hover{
        cursor: pointer;
      }
    }
  }
  @media(max-width: 600px) {
    h5{
      width: 95% !important;
    }
    button{
      color: var(--464852, #464852);
      /* text 16/bold */
      font-family: Proxima Nova, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px; /* 112.5% */
    }
  }
`
export const ReadBlog = styled.div`
  background: white;
  font-family: Proxima Nova, sans-serif !important;
  margin-left: -20px;
  div{
    word-wrap: break-word;
    padding: 20px;
    a{
      color: black;
      text-decoration: none;
    }
    img{
      border-radius: 40px;
      object-fit: cover;
      max-width: 100%;
    }
    h1{
      color: #464852;
      font-family: Proxima Nova;
      font-size: 44px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 81.2px */
      max-width: 80%;
      margin: 0;
    }
    ul{
      list-style-type: none;
      padding: 0;
    }
  }
`
export const MediaContentInPopUp = styled.div`
  width: 100%;
  display: grid;
  background-color: white;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  overflow: hidden;
  overflow-y: scroll;
  max-height: 55vh;
  ::-webkit-scrollbar {
    width: 2px;
    background-color: #F5F5F5;
  }
  @media(max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
  video{
    max-width: 150px !important;
    height: 150px !important;
    margin: 15px !important;
    border-radius: 15px;
    background-size: 200% !important;
    object-fit: cover;
    z-index: 1 !important;
    img{
      width: 145px;
      object-fit: cover;
      height: 150px;
      border-radius: 25px;
    }
    transition: all 1s ease;
    display: flex;
    flex-direction: column;
    button{
      z-index: 1000 !important;
      margin: 0 auto;
      margin-top: 10px;
      height: 32px;
      width: 90%;
      border-radius: 25px;
      border: none;
      background: white;
      display: flex;
      align-items: center;
      img{
        width: 18px !important;
        height: 18px !important;
        border: none;
        background-color: #FEEF34;
        padding: 3px;
        margin-right: 5px;
      }
      :hover{
        cursor: pointer;
      }
    }
    span{
      border: 3px solid red;
    }
  }
`

export const VideoBlock = styled.div`

`
export const ContentForVideo = styled.div`
  z-index: 1000000;
  position: relative;
  margin-top: -160px !important;
  img{
    width: 145px;
    object-fit: cover;
    height: 150px;
    border-radius: 25px;
  }
  transition: all 1s ease;
  display: flex;
  flex-direction: column;
  button{
    z-index: 1000 !important;
    margin: 0 auto;
    margin-top: 10px;
    height: 32px;
    width: 80%;
    border-radius: 25px;
    border: none;
    background: white;
    display: flex;
    align-items: center;
    img{
      width: 18px !important;
      height: 18px !important;
      border: none;
      background-color: #FEEF34;
      padding: 3px;
      margin-right: 5px;
    }
    :hover{
      cursor: pointer;
    }
  }
  span{
    border: 3px solid red;
  }
`
export const ImageInMedia = styled.div`
  max-width: 150px !important;
  height: 150px !important;
  margin: 15px !important;
  border-radius: 15px;
  background-size: 200% !important;
  object-fit: cover;
    img{
      width: 145px;
      object-fit: cover;
      height: 150px;
      border-radius: 25px;
    }
  transition: all 1s ease;
  display: flex;
  flex-direction: column;
  button{
    margin: 0 auto;
    margin-top: 10px;
    height: 32px;
    width: 90%;
    border-radius: 25px;
    border: none;
    background: white;
    display: flex;
    align-items: center;
    img{
      width: 18px !important;
      height: 18px !important;
      border: none;
      background-color: #FEEF34;
      padding: 3px;
      margin-right: 5px;
    }
    :hover{
      cursor: pointer;
    }
  }
  span{
    border: 3px solid red;
  }
`

export const FlexBetweenBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95% !important;
  box-sizing: content-box;
  margin: 15px auto;
  @media(max-width: 600px) {
    flex-direction: column;
  }
`
export const CustomButton = styled.button<{color: string}>`
  border: none;
  background: ${(props: any) => props.color};
  width: auto;
  padding: 2px 15px;
  height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  font-size: 14px;
  font-family: Proxima Nova, sans-serif !important;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  img{
    width: 24px;
    margin-right: 2px;
  }
  :hover{
    cursor: pointer;
  }
`

export const CustomButtonBottom = styled(CustomButton)`
  @media(max-width: 600px) {
    margin-top: 10px;
    width: 100%;
  }
  white-space: nowrap;
`
export const TextAreaContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 30px;
  width: 95% !important;
  word-wrap: break-word;
  label{
    font-size: 16px;
    font-family: Proxima Nova, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: #464852;
  }
  textarea {
    margin-top: 10px;
    font-size: 14px;
    width: 80% !important;
    font-family: Proxima Nova, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    ::placeholder{
      color: #7F7F7F;
    }
    padding-left: 5px;
    padding-top: 5px;
    outline: none;
    border-radius: 14px;
    border: 1px solid var(--eef-0-ed, #EEF0ED);
  }
  p{
    margin-top: 10px;
    font-size: 14px;
    width: 80% !important;
    font-family: Proxima Nova, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
  h5{
    margin-top: 0;
    color: var(--7-f-7-f-7-f, #7F7F7F);
    font-size: 14px;
    font-family: Proxima Nova, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
  @media(max-width: 800px) {
    width: 90% !important;
  }
`

export const BlockSearchLocation = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  label{
    font-size: 16px;
    font-family: Proxima Nova, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: #464852;
    margin-bottom: 3px;
  }
  input {
    font-size: 16px;
    font-family: Proxima Nova, sans-serif;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    color: black;
    margin-left: -2px;
    ::placeholder{
      font-size: 16px;
      font-family: Proxima Nova, sans-serif;
      font-style: normal;
      font-weight: 700;
      line-height: 18px;
      color: black;
    }
    outline: none;
    border: none;
    padding-bottom: 3px;
    border-bottom: 1px solid #A196F4;
  }
`

export const LocationList = styled.div`
  display: flex;
  flex-direction: column;
  h5{
    margin: 3px 0;
    position: sticky;
    :hover{
      cursor: pointer;
    }
  }
`

export const DateList = styled.div`
  display: flex;
  justify-content: space-between;
  div{
    display: flex;
    flex-direction: column;
    label{
      color: var(--a-89-ca-0, #A89CA0);
      /* text 12/regular */
      font-size: 12px;
      font-family: Proxima Nova, sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }
    input{
      border: none;
      border-bottom: 1px solid #A196F4;
      margin-right: 10px;
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
      color: rgba(0, 0, 0, 0);
      opacity: 1;
      display: block;
      background: url(/images/date_from.png);
      width: 20px;
      height: 20px;
      border-width: thin;
    } 
  }
`


// ShowMedia

export const ShowMediaBlock = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: #46485280;
  margin: 0 auto;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ContentShowMediaBlock = styled.div`
  width: 80%;
  position: absolute;
  height: 800px;
  float: right;
  right: 100px;
  border-radius: 20px;
  background: var(--ffffff, #FFF);
  text-align: center;
  span{
    position: absolute;
    right: 30px;
    margin-top: -30px;
    :hover{
      cursor: pointer;
    }
  }
  @media(max-width: 1410px) {
    width: 90% !important;
    right: auto;
  }
  @media(max-width: 600px) {
    width: 95% !important;
  }
`

// Send Message
export const SendMessageContent = styled.div`
  display: flex;
  justify-content: space-between;
  button{
    border: none;
    background: none;
    margin-right: 20px;
    margin-top: -15px;
    :hover {
      cursor: pointer;
    }
  }
`

export const ProfileInSendMessage = styled.div`
  margin: 20px;
  display: flex;
  padding: 0 !important;
  img{
    width: 41px;
    height: 43px;
    fill: var(--eef-0-ed, #EEF0ED);
    border-radius: 5px;
  }
  div{
    margin-left: 18px;
    margin-top: 0;
    h4{
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      font-family: Proxima Nova, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
    h5{
      margin-top: 0 ;
      margin-bottom: 0;
      font-family: Proxima Nova, sans-serif !important;
      font-size: 14px;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: 16px; 
      color: #464852;
    }
  }
`

export const InputSendMessage = styled.textarea`
  margin: 10px 20px;
  display: flex;
  justify-content: start;
  align-items: start;
  padding: 10px;
  width: 430px;
  height: 100px;
  border-radius: 10px;
  border: 1px solid var(--e-2-e-7-ed, #E2E7ED);
  outline: none;
  flex-shrink: 0;
  ::placeholder{
    color: var(--464852, #464852);
    font-family: Proxima Nova, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }
`

export const ButtonSendMessage = styled.button`
  border: none;
  background: none;
  display: inline-flex;
  padding: 5px 7px 5px 10px;
  align-items: center;
  gap: 13px;
  border-radius: 25px;
  background: var(--ffffff, #FFF);
  box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.10);
  float: right;
  margin: 20px;
  font-family: Proxima Nova, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #464852;
  :hover{
    cursor: pointer;
  }
`

export const ChatItem = styled.div`
  
`

export const SliderBlock = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
  background: #464852CC;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11111111110 !important;
`

export const SliderBlockMessage = styled.div`
  position: fixed !important;
  width: 100vw !important;
  height: 100vh !important;
  padding: 0 !important;
  margin: 0 !important;
  left: 0;
  top: 0;
  overflow: hidden !important;
  background: #464852CC !important;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100202002 !important;
`

export const SliderContentMessage = styled.div`
  max-width: 50vw !important;
  max-height: 85vh !important;
  width: 100% !important;
  height: 100% !important;
  box-sizing: content-box !important;
  display: flex !important;
  flex-direction: column !important;
  object-fit: contain !important;
  border-radius: 24px !important;
  z-index: 1000 !important;
  padding-bottom: 20px !important;
  padding-top: 20px !important;
  background: #464852 !important;
  div{
    display: flex !important;
    align-items: center !important;
    justify-content: center;
    overflow: hidden !important;
    height: 100% !important;
    img{
      :hover{
        cursor: pointer;
      }
    }
    video{
      box-sizing: content-box;
      object-fit: contain;
      max-width: 90%;
      max-height: 90%;
      border-radius: 12px;
      width: 100%;
      height: 100%;
      @media(max-width: 600px) {
        max-height: 85% !important;
        max-width: 85% !important;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
  @media(max-width: 1240px) {
    max-width: 70vw;
    max-height: 75vh;
  }
  @media(max-width: 1000px) {
    width: 90vw;
    height: 80vh;
    max-width: 90vw;
  }
  @media(max-width: 840px) {
    max-height: 75vh;
    max-width: 100vw;
  }
  @media(max-width: 600px) {
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
  }
`
export const RightButtonsBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
  height: 100% !important;
`
export const SliderContent = styled.div`
  max-width: 50vw;
  max-height: 85vh;
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  object-fit: contain;
  border-radius: 24px;
  z-index: 1000 !important;
  padding-bottom: 20px;
  padding-top: 20px;
  background: #464852;
  div{
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 100%;
    img{
      :hover{
        cursor: pointer;
      }
    }
    video{
      box-sizing: content-box;
      object-fit: contain;
      max-width: 90%;
      max-height: 90%;
      border-radius: 12px;
      width: 100%;
      height: 100%;
      @media(max-width: 600px) {
        max-height: 85% !important;
        max-width: 85% !important;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
  @media(max-width: 1240px) {
    max-width: 70vw;
    max-height: 75vh;
  }
  @media(max-width: 1000px) {
    width: 90vw;
    height: 80vh;
    max-width: 90vw;
  }
  @media(max-width: 840px) {
    max-height: 75vh;
    max-width: 100vw;
  }
  @media(max-width: 600px) {
    width: 100vw;
    height: 100vh;
    max-height: 100vh;
  }
`

export const TopBlock = styled.div`
  display: flex;
  justify-content: end !important;
  width: 100%;
  img{
    margin: 10px 30px;
  }
`
export const ImageInSlider = styled.img`
  box-sizing: content-box;
  object-fit: contain;
  max-width: 90%;
  max-height: 90%;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  @media(max-width: 600px) {
    max-height: 85% !important;
    max-width: 85% !important;
    width: 100% !important;
    height: 100% !important;
  }
`