import React from 'react';

import './Spiner.css'

export const Spinner = () => {
    return (
        <div className="img-spin">
            <div className="loadingio-spinner-double-ring-yjqniluw5xl">
                <div className="ldio-mj9zj5psgn">
                    <div></div>
                    <div></div>
                    <div><div></div></div>
                    <div><div></div></div>
                </div></div>
        </div>

    )
}

