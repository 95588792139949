import Pusher from "pusher-js";
import Cookies from "js-cookie";

export const pusher = new Pusher("61fa076d655bd53fa35d", {
    cluster: 'eu',
    channelAuthorization: {
        transport: "ajax",
        endpoint: 'https://myloveexpress-backend.ru/broadcasting/auth',
        headers: { Authorization: `Bearer ${Cookies.get("token_mle")}`}
    }
})