import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {TAuth, TAuthResponse, TRegister} from "../types/UserTypes";
import {_baseURL} from "./index";
import Cookies from "js-cookie";

export const userApi = createApi({
    reducerPath: "userApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${_baseURL}api/`,
        prepareHeaders: (headers) => {
            headers.set('Authorization', `Bearer ${Cookies.get('token_mle')}`)
            return headers
        }
    }),
    endpoints: (builder) => ({
        login: builder.mutation<TAuthResponse, TAuth>({
            query: (data) => ({
                url: `login`,
                method: 'POST',
                body: data
            }),
            transformResponse: (response: { data: { token: string, name: string } }) => {
                if (response.data.token) {
                    Cookies.set('token_mle', response.data.token, {expires: 1})
                }
                return response
            }
        }),
        setForgotPassword: builder.mutation<string, any>({
            query: (data) => ({
                url: `password-reset/code`,
                method: 'POST',
                body: data
            })
        }),
        setEnterCode: builder.mutation<any, any>({
            query: (data) => ({
                url: `password-reset/enter`,
                method: 'POST',
                body: data
            })
        }),
        setEnterNewPassword: builder.mutation<string, TRegister>({
            query: (data) => ({
                url: `password-reset/reset`,
                method: 'POST',
                body: data
            })
        }),
        register: builder.mutation<string, TRegister>({
            query: (data) => ({
                url: `register`,
                method: 'POST',
                body: data
            })
        }),
        //&relation=${data.relation}&smoke=${data.smoke}&education=${data.education}
        getSearchUsers: builder.query<any, any>({
            query: (data: any) => ({
                url: `search?size=${data.size}&age_from=${data.ageFrom}&age_to=${data.ageTo}&height_from=${data.heightFrom}&height_to=${data.heightTo}&children=${data.children}&zodiac=${data.zodiac}&relation=${data.relation}&smoke=${data.smoke}&education=${data.education}`,
                method: 'GET',
            })
        }),
        getSearchUsersIndex: builder.query<any, any>({
            query: (data: any) => ({
                url: `search/index?size=${data.size}`,
                method: 'GET',
            })
        }),
        createChat: builder.mutation({
            query: (data: { receiver_id?: string | number, content: string }) => ({
                url: `chat/create`,
                method: 'POST',
                body: data
            })
        }),
        getChatList: builder.query({
            query: () => ({
                url: `chat/list`,
                method: 'GET'
            })
        }),
        getChat: builder.query({
            query: (chatId: number) => ({
                url: `chat/list/${chatId}`,
                method: "GET"
            })
        }),
        getReadChat:builder.mutation({
            query: (chatId: number) => ({
                url: `chat/read/${chatId}`,
                method: "POST"
            })
        }),
        sendMessage: builder.mutation({
            query: (data: any) => ({
                url: 'chat/send',
                method: "POST",
                body: data
            })
        }),
        deleteMessage: builder.mutation({
            query: (data) => ({
                url: 'chat/delete',
                method: "DELETE",
                body: data
            })
        }),
        getTravels: builder.query({
            query: (data: any) => ({
                url: `travels?travel_country=${data?.finalCountry ? data?.finalCountry : ''}&travel_city=${data?.city ? data?.city : ""}`,
                method: 'GET',
            })
        }),
        getStories: builder.query({
            query: () => ({
                url: `stories`,
                method: 'GET'
            })
        }),
        setSupport: builder.mutation({
            query: ({data, userId}: any) => ({
                url: `support/create/${userId}`,
                method: "POST",
                body: data
            })
        })
    })
})

