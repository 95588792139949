import React from 'react';
import * as UI from "./ProfileStyles"
import {useNavigate} from "react-router-dom";
import {profileApi} from "../../services/Profile";
import Cookies from "js-cookie";
import {useAppDispatch} from "../../app/hooks";

export const UserLittle = ({user, index, setSelectedTab, setCurrentActive, refetch}: any) => {
    const userId = Cookies.get("userId")
    const navigate = useNavigate()
    const [ addViewer ] = profileApi.useAddVisitorMutation()
    const dispatch = useAppDispatch()
    const goToUser = async (id: number) => {
        await addViewer({userId, visitorId: user.id})
        setCurrentActive(2)
        localStorage.setItem('profile_tab', "My profile")
        localStorage.setItem("profile_tab_index", "2")
        setSelectedTab("My profile")
        navigate(`/users/${id}`)
    }
    return (
        <UI.LittleUser key={index}
                       avatar={user?.avatar_url} onClick={() => goToUser(user.id)}>
            <UI.TopBlockLittleUser>
                {user?.new ?
                    <UI.BlockBackground>
                        <span>new</span>
                    </UI.BlockBackground>
                :
                    <span></span>}
                <UI.PhotosBlock>
                    <img src={"/images/DarkCamera.svg"} alt={"camera"}/>
                    <span>{user?.media_lenght}</span>
                </UI.PhotosBlock>
            </UI.TopBlockLittleUser>
            <UI.BottomBlockLittleUser>
                <h3>{user?.name}</h3>
                <div>
                    <img src={"/images/YellowLocation.svg"} alt={'location'}/>
                    <h5>{user?.place?.country}, {user?.place?.city}</h5>
                </div>
            </UI.BottomBlockLittleUser>
        </UI.LittleUser>
    )
}