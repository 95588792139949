import React, {useEffect} from 'react';
import * as UI from '../../style';
import {useNavigate} from "react-router-dom";
import {MenuItem} from "../../style";
import Cookies from "js-cookie";
import {ErrorNotify, SuccessNotify} from "../../Notifications";
import {userApi} from "../../services/UserService";
import {useForm} from "react-hook-form";

export const EnterCode = ({isAuth}: any) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate()
    const [ getEnterCode ] = userApi.useSetEnterCodeMutation()
    const email = Cookies.get("email_recover")
    // const [ resetPassword ] = userApi.useSetEnterNewPasswordMutation()
    const onSubmit = async (data: any) => {

        try {
            getEnterCode(data).then((response: any) => {
                if (response.hasOwnProperty('error')) {
                    ErrorNotify(response.error?.data?.message);
                } else {
                    SuccessNotify(`Code sent`);
                    Cookies.set("confirm_code", data.code, {expires: 1})
                    navigate('/reset-password')
                }
            })
        } catch (e) {
            ErrorNotify(`${e}`)
        }
    }

    useEffect(() => {
        if (isAuth) {
            navigate('/profile')
        }
    }, [isAuth, navigate])

    return (
        <UI.MainBlockSignIn style={{backgroundImage: 'url(/images/BackgroundAuth.png)'}}>
            <UI.ControlContentBlock>
                <img src={"/images/logo.svg"} alt={"logo"} onClick={() => navigate('/')}/>
                <UI.BlockAuth style={{paddingBottom: "30px"}}>
                    <UI.FormAuth onSubmit={handleSubmit(onSubmit)}>
                        <h2>Enter code</h2>
                        <h4>or
                            <UI.MenuItem to={"/signin"}> Sign in</UI.MenuItem>
                        </h4>
                        <label>Email</label>
                        <input type="text" {...register("email", { required: true, value: email })} />
                        <label>Code</label>
                        <input type="text" {...register("code", { required: true })} />
                        <div style={{display: "flex", justifyContent: "end"}}>
                            <input type="submit" value="Confirm" />
                        </div>
                    </UI.FormAuth>
                </UI.BlockAuth>
            </UI.ControlContentBlock>
        </UI.MainBlockSignIn>
    )
}