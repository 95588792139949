import React, {Dispatch, useEffect} from 'react';
import * as UI from "./style"
import {ITrip} from "../components/Profile/MyProfile";
import {profileApi} from "../services/Profile";
import {Spinner} from "../components/Spinner";

interface ITrips {
    image: string,
    name: string,
    type?: number | string,
    showPlannedTrips?: boolean,
    setShowPlannedTrips?: Dispatch<boolean>,
    userId: any,
    profile: any,
    needRefetchTrips?: Dispatch<boolean>
}
export const Trips = ({image, name, type, showPlannedTrips, setShowPlannedTrips, userId, profile, needRefetchTrips}: ITrips) => {
    const [ removeTrip ] = profileApi.useDeleteTripMutation()
    const { data: trips, isLoading, refetch } = profileApi.useGetTripsQuery({userId, planned: type})
    const deleteTrip = async (id: number | string) => {
        await removeTrip({userId, tripId: id})
        refetch()
    }

    useEffect(() => {
        refetch()
    }, [needRefetchTrips]);
    if (isLoading) return <Spinner/>
    return (
        <UI.Trips style={{backgroundImage: trips?.data.length === 0 && profile?.data.my ? "url(/images/RoadDotsTrip.svg)" : 'none'}}>
            <UI.AboutTripBlock>
                <img src={`/images/${image}.svg`} alt={image}/>
                <h4>{name}</h4>
            </UI.AboutTripBlock>
            {trips?.data.length > 0 ?
                <UI.TripScrollBlock>
                {trips?.data.map((item: ITrip, index: number) => {
                        return (
                            <div  key={index}>
                                <UI.Trip>
                                    <UI.LeftBlockTrip>
                                        <img src={`/images/Fly.svg`} alt={'Fly'}/>
                                        <div>
                                            <h5>{item.address.city}, {item.address.country}</h5>
                                            {type === '1' ?  <h6>{item.date_from} - {item.date_to}</h6> : ''}
                                        </div>
                                    </UI.LeftBlockTrip>
                                    {profile?.data.my ?
                                        <img onClick={() => deleteTrip(item.id)}
                                             style={{marginRight: "5px"}}
                                             src={"/images/EmptyRedClose.svg"} alt={"cancel"}/>
                                    :
                                        <span>  </span>}

                                </UI.Trip>
                                <hr/>
                            </div>
                        )
                    })}
                </UI.TripScrollBlock>
                :
                <UI.NoDataTrips>
                    {profile?.data.my ?
                        <>
                            <h4>
                                You don't have any scheduled trips yet
                            </h4>
                            <h6>Create your trip</h6>
                        </>
                        :
                        <h4 style={{marginTop: "60px"}}>No trips</h4>
                    }
                </UI.NoDataTrips>
            }
            {profile?.data.my ?
                <UI.BottomBlockTrip style={{display: "flex"}}>
                    <UI.AddTrip type="button"
                                onClick={() => setShowPlannedTrips ?
                                    setShowPlannedTrips(!showPlannedTrips)
                                    :
                                    console.log('')}>
                        Add
                        <img src={"/images/YellowRight.svg"} alt={"right"}/>
                    </UI.AddTrip>
                </UI.BottomBlockTrip>
            :
                <></>}
        </UI.Trips>
    )
}