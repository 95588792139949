import React, {useMemo, useState, useEffect} from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
// import { TileLayer } from 'react-leaflet/TileLayer'
// import { useMap } from 'react-leaflet/hooks'
import * as FUI from "./style";
import "../App.css"
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import {profileApi} from "../services/Profile";
import {Spinner} from "../components/Spinner";
import moment from "moment";
export const GoogleMapAPI = ({profile, increaseData, setCurrentActive, setSelectedTab}: any) => {
    // @ts-ignore
    // useEffect(() => {
    //     navigator.geolocation.getCurrentPosition(
    //         getCurrentCityName,
    //         error,
    //         options
    //     );
    // }, []);
    const navigate = useNavigate()
    const [display_name, setName] = useState("");
    const [address, setAddress] = useState({});
    const [coords, setCorrds] = useState({
        latitude: 0,
        longitude: 0
    });
    // function getCurrentCityName(position: any) {
    //     setCorrds({
    //         latitude: profile?.information?.location?.latitude,
    //         longitude: profile?.information?.location?.longitude,
    //     });
    //
    //     let url = "https://nominatim.openstreetmap.org/reverse?format=jsonv2" +
    //         "&lat=" + profile?.information?.location?.latitude + "&lon=" + profile?.information?.location?.longitude;
    //
    //     fetch(url, {
    //         method: "GET",
    //         mode: "cors",
    //         headers: {
    //             "Access-Control-Allow-Origin": "https://o2cj2q.csb.app"
    //         }
    //     })
    //         .then((response) => response.json())
    //         .then((data) => setName(data.display_name))
    //         .catch((e) => console.log(""));
    // }

    function error(err: any) {
        if (
            err.code === 1 || //if user denied accessing the location
            err.code === 2 || //for any internal errors
            err.code === 3 //error due to timeout
        ) {
            alert(err.message);
        } else {
            alert(err);
        }
    }
    const options = {
        maximumAge: 30000,
        timeout: 27000
    };
    function MapView() {
        let map = useMap();
        map.setView([profile?.information?.location?.latitude, profile?.information?.location?.longitude], map.getZoom());
        //Sets geographical center and zoom for the view of the map
        return null;
    }
    const [ addViewer ] = profileApi.useAddVisitorMutation()
    const userId = Cookies.get("userId")
    const goToUser = async (id: number) => {
        await addViewer({userId, visitorId: id})
        setCurrentActive(2)
        localStorage.setItem('profile_tab', "My profile")
        localStorage.setItem("profile_tab_index", "2")
        setSelectedTab("My profile")
        navigate(`/users/${id}`)
    }

    if (!profile) return <Spinner/>
    return (
        <FUI.MapsContent>
          <MapContainer center={[profile?.information?.location?.latitude, profile?.information?.location?.longitude]}
                        zoom={2} style={{height: "320px"}}>
              <TileLayer
                  attribution='&amp;copy <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
              />
              <Marker position={[profile?.information?.location?.latitude, profile?.information?.location?.longitude]}>
                  <Popup>
                      It's me
                  </Popup>
              </Marker>
              {increaseData.data.map((user: any, index: number) => {
                  return (
                      <div key={index}>
                          {user?.travels.map((item: any, number: number) => {
                              return (
                                  <Marker position={[item?.location?.latitude, item?.location?.longitude]} key={number}>
                                      <Popup>
                                          <FUI.UserOnMap onClick={() => goToUser(user.id) }>
                                              <h4 style={{margin: "5px"}}>Name: {user?.name}</h4>
                                              <h4 style={{margin: "5px"}}>Location: {item?.location?.city}, {item?.location?.country}</h4>
                                              <h4 style={{margin: "5px"}}>Dates: {moment(item?.location?.date_from).format('ll')} - {moment(item?.location?.date_to).format('ll')}</h4>
                                              <img style={{maxWidth: "80px", margin: "5px", borderRadius: "12px"}} src={user?.avatar_url} alt={"avatgar"}/>
                                          </FUI.UserOnMap>
                                      </Popup>
                                  </Marker>
                              )
                          })}
                      </div>
                  )
              })}
              <MapView/>
          </MapContainer>
        </FUI.MapsContent>
    )
}