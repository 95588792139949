import React, {createRef, Dispatch, useEffect, useRef, useState} from 'react';
import * as PUI from "./ProfileStyles";
import {Container, MainBLock, SendMessage, Content, ImagePreview, ImagePreviewInMessages} from "./ProfileStyles";
import {useNavigate} from "react-router-dom";
import {userApi} from "../../services/UserService";
import {pusher} from "../../app/Pusher";
import Cookies from "js-cookie";
import {ChatItem} from "../../features/Messages/ChatItem";
import {useForm} from "react-hook-form";
import EmojiPicker from "emoji-picker-react";
import {keyboardKey} from "@testing-library/user-event";
import moment from "moment";
import {Message} from "./Message";
import {ImageInSlider} from "../../features/Messages/ImageInSlider";
import {Spinner} from "../Spinner";

interface IChat {
    setSelectedTab: Dispatch<any>,
    setCurrentActive: any,
    profile: any,
}

interface IMessage {
    id: number,
    user_id: number,
    room_id: number,
    content: string,
    time: string
}
interface IChats {
    content: string,
    room: {
        id: number,
        name: any,
        members: any
    },
    id: number
}
export const Messages = ({setSelectedTab, setCurrentActive, profile}: IChat) => {
    const ref = useRef(null)
    const userId = Cookies.get("userId") || 0
    const navigate = useNavigate()
    const [ selectedChat, setSelectedChat ] = useState<any>(Cookies.get(`last_chat_mle_${userId}`) || -1)
    const [ searchText, setSearchText ] = useState<any>('')
    const [ selectedUser, setSelectedUser ] = useState<any>()
    const [ showEmoji, setShowEmoji ] = useState<boolean>(false)

    const [ disable, setDisable ] = useState<boolean>(false)
    const { register, setValue, watch } = useForm()
    const [ allMessages, setAllMessages ] = useState<any>([])
    const [ upLastMessage, setUpLastMessage ] = useState<any>()
    const [ closeSlider, setCloseSlider ] = useState<boolean>(false)
    const [ selectedImage, setSelectedImage ] = useState('')
    const { data: chatList, isLoading, isError } =  userApi.useGetChatListQuery(undefined, {
        refetchOnMountOrArgChange: true
    })
    const [ readChat ] = userApi.useGetReadChatMutation()
    const { data: messages, refetch, isFetching } = userApi.useGetChatQuery(selectedChat, {
        refetchOnMountOrArgChange: true
    })

    const [ file, setFile ] = useState<any>()
    const [ fileUrl, setFileUrl ] = useState<any>('')
    const [ sendMessage ] = userApi.useSendMessageMutation()
    const [ deleteMessage ] = userApi.useDeleteMessageMutation()

    const refSend = useRef(null)
    const currentMessage = watch('message')

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        // @ts-ignore
        document.scrollingElement.scrollTop = 0;
        // mainContentRef.current.scrollTop = 0;
    }, []);
    const uploadMedia = async (file: any) => {

        const formData = new FormData();
        formData.append("file", file.target.files[0])
        // formData.append("content", 'null')
        formData.append("room_id", selectedChat)
        // await setUploadMedia({userId, formData})
        // refetch()
        setFileUrl(URL.createObjectURL(file.target.files[0]))
        setFile(file.target.files[0])
        const req = {
            formData, room_id: selectedChat, content: null
        }
        const room_id = selectedChat
        const content = null
        // await sendMessage(formData)
        //     .then((r: any) => setAllMessages((prevMessages: any) => {
        //         const newArray = {
        //             date: prevMessages[prevMessages.length - 1].date,
        //             data: [...prevMessages[prevMessages.length - 1].data, r?.data.data]}
        //         setUpLastMessage(r?.data.data)
        //         return prevMessages.map((messagesFromDate: any) => (
        //             messagesFromDate.date === newArray.date ?
        //                 newArray
        //                 :
        //                 messagesFromDate
        //
        //         ));
        //     }))
        //     .then((r: any) => console.log(r))
        //     .then(() => setValue('message', ''))
        //     .then(() => setShowEmoji(false))
    }
    const sendNewMessage = async () => {
        const formData = new FormData();
        formData.append("content", currentMessage)
        formData.append("room_id", selectedChat)

        if (file) formData.append("file", file)
        if (currentMessage.length > 0) {
            setDisable(true)
            await sendMessage(formData)
                .then((r: any) => setAllMessages((prevMessages: any) => {
                    const newArray = {
                        date: prevMessages[prevMessages.length - 1].date,
                        data: [...prevMessages[prevMessages.length - 1].data, r?.data.data]}
                    setUpLastMessage(r?.data.data)
                    return prevMessages.map((messagesFromDate: any) => (
                        messagesFromDate.date === newArray.date ?
                            newArray
                            :
                            messagesFromDate

                    ));
                }))
                .then(() => setValue('message', ''))
                .then(() => setShowEmoji(false))
                .then(() => setDisable(false))
                .then(() => setFile(null))
                .then(() => setFileUrl(null))
        }
    }

    useEffect(() => {
        readChat(selectedChat)
    }, [selectedChat])
    const onDelete = async (item: any) => {
        await deleteMessage({room_id: item.room_id, message_id: item.id})
            .then((r: any) => setAllMessages((prevMessages: any) => {
                const newArray = {
                    date: prevMessages[prevMessages.length - 1].date,
                    data: [...prevMessages[prevMessages.length - 1].data.filter((message: any) => JSON.stringify(message) !== JSON.stringify(item))]
                }
                setUpLastMessage(newArray.data[newArray.data.length - 1])
                return prevMessages.map((messagesFromDate: any) => (
                    messagesFromDate.date === newArray.date ?
                        newArray
                        :
                        messagesFromDate

                ));
            }))
            .then(() => setShowEmoji(false))
    }
    // local search
    const getSearch = (e: any) => {
        setSearchText(e.target.value)
    }
    const goToUser = async (id: number) => {
        setCurrentActive(2)
        localStorage.setItem('profile_tab', "My profile")
        localStorage.setItem("profile_tab_index", "2")
        setSelectedTab("My profile")
        navigate(`/users/${id}`)
    }
    // go to search page
    const goToSearch = () => {
        setSelectedTab('Search')
        setCurrentActive(1)
        localStorage.setItem("profile_tab_index", '1')
        navigate("/profile")
        localStorage.setItem('profile_tab', 'Search')
    }
    const checkOnline = () => {
        const dateUtc = moment(selectedUser?.online).unix()
        const currentDate = Math.floor(Date.now() / 1000)
        if (currentDate - dateUtc > 12000) {
            const messageFromTimeZone = moment.utc(selectedUser?.online).local()
            // return `Last seen at ${messageFromTimeZone.format("D MMMM HH:mm")}`
            return messageFromTimeZone.fromNow()
        } else if (selectedUser?.online === null) {
            return 'Offline'
        } else {
            return 'Online'
        }
    }
    const [ showMessages, setShowMessages ] = useState(true)

    const goToInput = () => {
        // @ts-ignore
        refSend.current?.scrollIntoView({ behavior: "auto" });
    }
    const handleKeyPress = async (event: keyboardKey) => {
        if (event.key === 'Enter') {
            await sendMessage({room_id: selectedChat, content: currentMessage})
                .then((r: any) => setAllMessages((prevMessages: any) => {
                    const newArray = {
                        date: prevMessages[prevMessages.length - 1].date,
                        data: [...prevMessages[prevMessages.length - 1].data, r?.data.data]}
                    return prevMessages.map((messagesFromDate: any) => (
                        messagesFromDate.date === newArray.date ?
                            newArray
                            :
                            messagesFromDate

                    ));
                }))
                .then(() => setValue('message', ''))
                .then(() => setShowEmoji(false))
        }
    }
    useEffect(() => {
        if (selectedChat !== -1) {
            if (chatList?.data.length > 0) {
                const findChat = chatList?.data.filter((chat: any) => chat.id === +selectedChat)
                setSelectedUser(findChat[0]?.members)
            }
        }
    }, [selectedChat, chatList]);


    useEffect(() => {
        const channel = pusher.subscribe(`private-chat.${userId}`);
        channel.bind('chat.send', function (data: IMessage) {
            setAllMessages((prevMessages: any) => {
                if (prevMessages[prevMessages.length - 1]?.data[prevMessages[prevMessages.length - 1]?.data.length - 1].id !== data.id) {
                    const newArray = {
                        date: prevMessages[prevMessages.length - 1].date,
                        data: [...prevMessages[prevMessages.length - 1].data, data]}
                    return prevMessages.map((messagesFromDate: any) => (
                        messagesFromDate.date === newArray.date ?
                            newArray
                            :
                            messagesFromDate

                    ));
                } else {
                    return prevMessages
                }
            })
            setUpLastMessage(data)
        })

        channel.bind('chat.message.delete', function (data: IMessage) {
            setAllMessages((prevMessages: any) => {
                const newArray = {
                    date: prevMessages[prevMessages.length - 1].date,
                    data: [...prevMessages[prevMessages.length - 1].data.filter((message: any) => JSON.stringify(message) !== JSON.stringify(data))]
                }
                setUpLastMessage(newArray.data[newArray.data.length - 1])
                return prevMessages.map((messagesFromDate: any) => (
                    messagesFromDate.date === newArray.date ?
                        newArray
                        :
                        messagesFromDate

                ));
            })
        })
        return () => {
            pusher.unsubscribe(`private-chat.${userId}`)
        }
    }, []);

    useEffect(() => {
        if (messages?.data) {
            setAllMessages(messages?.data)
        }
    }, [messages]);

    useEffect(() => {
        // @ts-ignore
        ref.current?.scrollIntoView({ behavior: "auto" });
    }, [allMessages, isFetching, file, setAllMessages]);
    if (isLoading) return <Spinner/>

    if (isError) return <span>Error: {JSON.stringify(isError)}</span>
    return (
        <Content>
            <Container>
                {
                    closeSlider ?
                        <ImageInSlider image={selectedImage} setCloseSlider={setCloseSlider} />
                        :
                        <></>
                }
                <PUI.ChatBlock>
                    <PUI.LeftChatBlock show={showMessages}>
                        <h2>Messages</h2>
                        <PUI.SearchInChat length={searchText.length}>
                            <input type={"text"} onChange={getSearch}/>
                        </PUI.SearchInChat>
                        <PUI.ChatList>
                            {chatList?.data?.length === 0 ?
                                <PUI.IFNoMessages>
                                    <h3>You don't have a conversation started yet</h3>
                                    <h2>
                                        Choose who you want to start a conversation with
                                    </h2>
                                    <SendMessage onClick={goToSearch}>Choose
                                        <img src={"/images/YellowRight.svg"} alt={"Right"}/>
                                    </SendMessage>
                                </PUI.IFNoMessages>
                                :
                                <>
                                    {chatList?.data?.map((item: any, index: number) => {
                                        return (
                                            <ChatItem chat={item} key={index}
                                                      upLastMessage={upLastMessage}
                                                      setSelectedChat={setSelectedChat}
                                            selectedChat={selectedChat}
                                                      allMessages={allMessages}
                                            setShowMessages={setShowMessages}/>
                                        )
                                    })
                                    }
                                </>
                            }
                        </PUI.ChatList>
                    </PUI.LeftChatBlock>
                    <PUI.RightChatBlock show={showMessages}>
                        {selectedChat === -1 ?
                            <PUI.NotSelectedBlock>
                                <img src={"/images/NotSelected.svg"} alt={"Not Selected"}/>
                                <h4>Select a dialogue to start chatting</h4>
                                <h5>Dialog will be shown here</h5>
                            </PUI.NotSelectedBlock>
                            :
                            <PUI.SelectedChat>
                                <PUI.TopPanelSelectedChat>
                                    <PUI.ChatItemUser>
                                        <PUI.GoToMessages
                                            style={{width: "25px"}}
                                            src={"/images/CaretLeft.svg"} alt={"Left"}
                                                          onClick={() => setShowMessages(!showMessages)}/>
                                        <img src={selectedUser?.avatar_url ||"/images/AvatarUser.svg"}
                                             alt={"Avatar"}
                                             onClick={() => goToUser(selectedUser?.id)}
                                        />
                                        <div>
                                            <h4 onClick={() => goToUser(selectedUser?.id)}>{selectedUser?.name}</h4>
                                            <h5>{checkOnline()}</h5>
                                        </div>
                                    </PUI.ChatItemUser>
                                    <img src={"/images/ConfigSelectedChat.svg"} alt={"Config"}/>
                                </PUI.TopPanelSelectedChat>
                                <PUI.Messages>
                                    {allMessages.map((messageInDay: any, index: number) => {
                                        return (
                                            <div style={{display: "flex", flexDirection:"column"}} key={index}>
                                                <h3>{messageInDay.date}</h3>
                                                {messageInDay.data.map((message: any, indexMessage: number) => {
                                                    // moment().format('LT');
                                                    // @ts-ignore
                                                    const timeZone =  moment.utc(`2023-08-30 ${message?.time}:00`)

                                                    return (
                                                       <Message message={message} isFetching={isFetching}
                                                                onDelete={onDelete}
                                                                indexMessage={indexMessage}
                                                       setCloseSlider={setCloseSlider} setSelectedImage={setSelectedImage}/>
                                                    )
                                                })}
                                                {fileUrl &&
                                                    <PUI.BlockImagePreview>
                                                        <ImagePreviewInMessages src={fileUrl} alt={'image'}/>
                                                    </PUI.BlockImagePreview>}
                                            </div>

                                        )
                                    })
                                    }
                                    {showEmoji ?
                                        <PUI.BlockEmoji>
                                            <EmojiPicker
                                                onEmojiClick={(e) => {
                                                    setValue('message', `${currentMessage}${e.emoji}`)
                                                }}/>
                                        </PUI.BlockEmoji>
                                        :
                                        <></>
                                    }
                                    <span ref={ref}></span>
                                </PUI.Messages>
                                <PUI.BottomPanelSelectedChat>
                                    <div>
                                        <input type={"text"}
                                               placeholder={"Start typing..."}
                                               onClick={() => goToInput()}
                                               onKeyPress={(e: keyboardKey) => handleKeyPress(e)}
                                               {...register('message')}/>
                                    </div>
                                    <div style={{justifyContent: "space-between"}}>
                                        <PUI.BottomLeftPanelChat>
                                            <img src={"/images/Smiley.svg"} alt={"smiles"} onClick={() => setShowEmoji(!showEmoji)}/>
                                            <img src={"/images/clip.svg"} alt={"Clip"}/>
                                            <form encType={"multipart/form-data"}>
                                                <input type={"file"} placeholder={""} value={""}
                                                       multiple={false}
                                                       onChange={(image: any) => uploadMedia(image)}
                                                />
                                            </form>
                                            {fileUrl && <PUI.BottomPreview style={{background: `url(${fileUrl})`}}>
                                                <img src={"/images/YellowPlus.svg"} alt={'Plus'} onClick={() => {
                                                    setFile(null)
                                                    setFileUrl(null)
                                                }}/>
                                            </PUI.BottomPreview>
                                            }
                                        </PUI.BottomLeftPanelChat>
                                        <PUI.Send type={"button"} onClick={sendNewMessage} disabled={disable}>
                                            Send
                                            <img src={"/images/YellowRight.svg"} alt={"right"}/></PUI.Send>
                                    </div>
                                    <span ref={refSend}></span>
                                </PUI.BottomPanelSelectedChat>
                            </PUI.SelectedChat>
                        }
                    </PUI.RightChatBlock>
                </PUI.ChatBlock>
            </Container>
        </Content>
    )
}