import React, {Dispatch, useEffect, useState} from 'react';
import * as FUI from "../style";
import * as PUI from "../../components/Profile/ProfileStyles";
import {userApi} from "../../services/UserService";
import Cookies from "js-cookie";
import {Spinner} from "../../components/Spinner";

interface IChatItem {
    chat: any,
    setSelectedChat: Dispatch<any>,
    selectedChat: any,
    upLastMessage: any,
    setShowMessages: any,
    allMessages: any
}
export const ChatItem = ({chat, setSelectedChat, selectedChat, upLastMessage, setShowMessages, allMessages}: IChatItem) => {

    const chatId = chat.id
    const userId = Cookies.get("userId") || 0
    const [ lastMessage, setLastMessage ] = useState<string | undefined | null>('')
    const {data: messages, isLoading, refetch, isFetching } = userApi.useGetChatQuery(chatId, {
        refetchOnMountOrArgChange: true,
    })
    const [ readChat ] = userApi.useGetReadChatMutation()
    const [ countUnread, setCountUnread ] = useState<number>(0)
    useEffect(() => {
        if (messages?.data[messages.data.length - 1].data.length > 0) {
            setLastMessage(messages?.data[messages.data.length - 1].data[messages?.data[messages.data.length - 1].data.length - 1].content)
        }
        // add socket last message
    }, [messages]);

    useEffect(() => {
        // console.log(upLastMessage?.content?.length, chatId, +upLastMessage?.room_id)
        if (upLastMessage?.content?.length > 0 && chatId === +upLastMessage?.room_id) {
            setLastMessage(upLastMessage?.content)
        }
        let count = 0;
        let status = false
        if (messages?.data?.length > 0) {

            for (let day = messages?.data?.length -1; day !== -1; day--) {
                for (let messagesInDay = messages?.data[day].data.length -1; messagesInDay !== -1; messagesInDay--) {
                    if (messages?.data[day].data[messagesInDay].user_id !== +userId) {
                        count++
                        if (messages?.data[day].data[messagesInDay].read) {
                            count--
                            status = true
                            break
                        }
                        if (status) break
                    }
                }
                if (status) break
            }

            setCountUnread(count)
        }
        // console.log(upLastMessage?.content)
        // console.log('delete')
    }, [upLastMessage, isFetching, messages]);

    if (isLoading) return <></>
    return (
        <PUI.ChatItem onClick={() => {
            Cookies.set(`last_chat_mle_${userId}`, chatId, { expires: 3})
            setSelectedChat(chatId)
            // readChat(chatId)
            // refetch()
            setShowMessages((prevState: boolean) => !prevState)
        }}>
            <PUI.ChatItemUser style={{marginTop: "5px", border: chatId === +selectedChat ?
            "1px solid #7B6AF3" : "1px solid #FAFAFA", background: chatId === +selectedChat ?
                    "#FFF" : ""
            }}>
                <img src={chat.members?.avatar_url || "/images/AvatarUser.svg"}
                     alt={"Avatar"}/>
                <PUI.BlockUserMessages>
                    <div>
                        <h4>{chat.members?.name}</h4>
                        {/*<h5>online</h5>*/}
                        <h6>{lastMessage}</h6>
                    </div>
                    {countUnread !== 0 && <span>{countUnread}</span>}
                </PUI.BlockUserMessages>
            </PUI.ChatItemUser>
        </PUI.ChatItem>
    )
}