import React, {Dispatch, useEffect, useState} from 'react';
import * as UI from '../../style'
import {Link, useLocation, useNavigate} from "react-router-dom";
import Cookies from "js-cookie";
import {SuccessNotify} from "../../Notifications";
interface IAuth {
    isAuth: boolean,
    setIsAuth: Dispatch<boolean>
    showHeader: boolean,
    setShowHeader: Dispatch<boolean>
}
export const Header = ({isAuth, setIsAuth, showHeader, setShowHeader}: IAuth) => {
    const search = useLocation();
    const listHiddenHeader: string[] = ['/signin', '/register', '/profile',
        '/users/*', '/blog', '/blogs/*', '/forgot-password', '/enter-code', '/reset-password',
    '/privacy', '/about', '/terms']
    const navigate = useNavigate()

    const [ mobileMenu, setMobileMenu ] = useState<boolean>(false)
    useEffect(() => {
    }, [isAuth])
    useEffect(() => {
        if (listHiddenHeader.includes(search.pathname)) setShowHeader(false)
        else if(search.pathname.slice(1, 6) === 'users') setShowHeader(false)
        else if(search.pathname.slice(1, 6) === 'blogs') setShowHeader(false)
        else setShowHeader(true)
    }, [search])

    const logOut = () => {
        Cookies.remove('token_mle')
        setIsAuth(false)
        SuccessNotify('Bye!')
        navigate('/')
    }
    if (!showHeader) return <></>
    return (
        <UI.Header>
            <UI.Content>
                <UI.MenuItem to={"/"}>
                    <UI.Logo src={"/images/logo.svg"} alt="logo"/>
                </UI.MenuItem>
                <UI.Menu>
                    <UI.MenuItem to={"/blog"}>
                        Blog
                    </UI.MenuItem>
                    {/*<UI.MenuItem to={"#"}>*/}
                    {/*    Choose the language*/}
                    {/*</UI.MenuItem>*/}
                    {/*<UI.MenuItem to={"#"}>*/}
                    {/*    More*/}
                    {/*</UI.MenuItem>*/}
                </UI.Menu>
                {isAuth ?
                    <UI.Menu>
                        <UI.MenuItemBold to={"/profile"}>
                            Profile
                        </UI.MenuItemBold>
                        <UI.MenuItemBold to={"#"}
                            onClick={() => logOut()}>
                            Log out
                        </UI.MenuItemBold>
                    </UI.Menu>
                :
                    <UI.Menu>
                        <UI.MenuItemBold to={"/signin"}>
                            Sign in
                        </UI.MenuItemBold>
                        <UI.MenuItemBoldButton to={"/register"}>
                            Register
                        </UI.MenuItemBoldButton>
                    </UI.Menu>}
                {!mobileMenu ?
                    <UI.MenuOpenClose onClick={() => setMobileMenu(!mobileMenu)}>
                        <hr></hr>
                        <hr></hr>
                        <hr></hr>
                    </UI.MenuOpenClose>
                :
                    <></>
                }
            </UI.Content>
            {mobileMenu ?
                <UI.MobileMenu>
                    <UI.MenuOpenClose onClick={() => setMobileMenu(!mobileMenu)}>
                        <img src={"/images/ClearBorderWhiteClose.svg"} alt={'close'}/>
                    </UI.MenuOpenClose>
                    <UI.MobileNavMenu style={{margin: "0 auto"}}>
                        <UI.MenuItem to={"/blog"} style={{marginRight: "0"}}>
                            Blog
                        </UI.MenuItem>
                        {/*<UI.MenuItem to={"#"}>*/}
                        {/*    Choose the language*/}
                        {/*</UI.MenuItem>*/}
                        {/*<UI.MenuItem to={"#"}>*/}
                        {/*    More*/}
                        {/*</UI.MenuItem>*/}
                    </UI.MobileNavMenu>
                    {isAuth ?
                        <UI.MobileNavMenu style={{margin: "0 auto", marginTop: "100px"}}>
                            <UI.MenuItemBold to={"/profile"} style={{marginRight: 0}}>
                                Profile
                            </UI.MenuItemBold>
                            <UI.MenuItemBold to={"#"} style={{marginRight: 0}}
                                             onClick={() => logOut()}>
                                Log out
                            </UI.MenuItemBold>
                        </UI.MobileNavMenu>
                    :
                        <UI.MobileNavMenu>
                            <UI.SignInButton type={"button"} style={{marginRight: 0}}
                                             onClick={() => navigate('/signin')}>
                                Sign-in
                            </UI.SignInButton>
                            <UI.RegisterButton
                                type={"button"}
                                style={{marginRight: 0}}
                                onClick={() => navigate('/register')}>
                                Register now
                            </UI.RegisterButton>
                        </UI.MobileNavMenu>
                    }
                    <UI.MenuItem to={"/"} style={{margin: "100px 0 50px 30px"}}>
                        <UI.Logo src={"/images/logo.svg"} alt="logo"/>
                    </UI.MenuItem>
                </UI.MobileMenu>
            :
                <></>
            }
        </UI.Header>
    )
}

