import React, {useEffect} from 'react';
import * as FUI from './style';
import {otherApi} from "../services/Other";
import {Spinner} from "../components/Spinner";

export const ReadBlog = ({id, setShowBlog}: any) => {
    console.log('id', id)
    const {data: blog, isLoading } = otherApi.useGetBlogQuery(id, {
        refetchOnMountOrArgChange: true
    })

    useEffect(() => {
        setShowBlog(true)
    }, []);
    if (isLoading) return <Spinner/>

    return (
        <FUI.ReadBlog>
            <div>
                <h1 dangerouslySetInnerHTML={{__html: blog?.data.title}}/>
                <div dangerouslySetInnerHTML={{__html: blog?.data.content}}/>
            </div>
        </FUI.ReadBlog>
    )
}