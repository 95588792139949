import React, {Dispatch} from 'react';
import * as UI from "./style";

interface IShowMedia {
    setShowMedia: Dispatch<boolean>
    profile: any
}

export const PopUpShowMedia = ({setShowMedia, profile}: IShowMedia) => {

    return (
        <UI.ShowMediaBlock>
            <UI.ContentShowMediaBlock>
                <h3>All photos</h3>
                <span onClick={() => setShowMedia(false)}>
                    <img src={"/images/CloseModal.png"} alt={"close"}/>
                </span>
                {profile?.media[0].length > 0 ?
                    <UI.PopUpShowMedia>
                        {profile?.media[0].map((item: any, index: number) => {
                            return (
                                <UI.MediaImage src={item.url} alt={'image'} key={index}/>
                            )
                        })}
                    </UI.PopUpShowMedia>
                    :
                    <UI.NoMediaContent>
                        <h2>NO PHOTOS</h2>
                        {profile?.my ?
                            <h4>You can add a photo using the Media Manager</h4>
                            :
                            <></>}
                        <img src={"/images/NoPhotos.png"} alt={'No photos'}/>
                    </UI.NoMediaContent>
                }
            </UI.ContentShowMediaBlock>
        </UI.ShowMediaBlock>
    )
}