import React from 'react';
import  * as UI from '../components/Profile/ProfileStyles'
export const Select = ({register, options, name, data}: any) => {

    return (
        <UI.BlockProfileData>
            <label>{name.charAt(0).toUpperCase()
                + name.slice(1)}: </label>
            <UI.SelectForm {...register(name)} defaultValue={data}>
                {options.map((value: string, index: number) => (
                    <option  value={value} defaultValue={data}  key={index}>{value}</option>
                ))}
            </UI.SelectForm>
        </UI.BlockProfileData>
    );
}

export const RegisterSelect = ({register, options, name}: any) => {

    return (
        <UI.BlockRegisterData>
            <label>{name.charAt(0).toUpperCase()
                + name.slice(1)}: </label>
            <UI.SelectForm {...register(name)}>
                {options.map((value: string, index: number) => (
                    <option value={value} key={index}>{value}</option>
                ))}
            </UI.SelectForm>
        </UI.BlockRegisterData>
    )
}

export const DateProfileSelect = ({register, options, name, data}: any) => {

    return (
        <UI.BlockRegisterData>
            <label>{name.charAt(0).toUpperCase()
                + name.slice(1)}: </label>
            <UI.SelectForm {...register(name)}>
                <option value={data}>{data || 'no data'}</option>
                {options.map((value: string, index: number) => (
                    <option value={value} key={index}>{value}</option>
                ))}
            </UI.SelectForm>
        </UI.BlockRegisterData>
    )
}