import React, {Dispatch, useCallback, useEffect, useState} from 'react';
import * as UI from "./ProfileStyles";
import {profileApi} from "../../services/Profile";
import Cookies from "js-cookie";
import {useLocation} from "react-router-dom";
import moment from "moment/moment";
import {SuccessNotify} from "../../Notifications";
import {LocationList} from "../../style";
import {useForm} from "react-hook-form";
import {LocationListTopPanel} from "./ProfileStyles";


interface IProfile {
    profile: any,
    selectedTab: any,
    showMobileMenu: boolean,
    setShowMobileMenu: Dispatch<boolean>,
    notificationPool: any,
    setNotificationPool: any,
    setSelectedTab: any,
    setCurrentActive: any,
    selectedLocation: any,
    setSelectedLocation: any
}
export const TopPanel = ({profile, selectedTab, showMobileMenu, setShowMobileMenu, notificationPool, setNotificationPool, setSelectedTab, setCurrentActive, selectedLocation, setSelectedLocation}: IProfile) => {
    const userId = Cookies.get("userId")
    const location = useLocation()
    const forViewId = location.pathname.slice(7)
        const [ count, setCount ] = useState(0)
    const { register, setValue, watch } = useForm()
    const [ listLocations, setListLocation ] = useState<any>([])
    const [ isLocation, setIsLocation ] = useState<boolean>(false)

    const [ mobileSearch, setMobileSearch ] = useState(false)
    const watchLocation = watch('location')
    const watchLocationMobile = watch('locationMobile')
    const onChangeInput = async (data: any) => {
        // @ts-ignore
        // const res = cities.filter((elem: any) => elem.name.includes(data))
        // if (res.length > 5) setListLocation(res.slice(0, 5))
        // else setListLocation(res)
        setSelectedLocation(null)
        setIsLocation(false)
        try {
            await fetch(`https://api.teleport.org/api/cities/?search=${data}`)
                .then((r: any) => r.json())
                .then((r: any) => setListLocation(r._embedded["city:search-results"].slice(0, 10)))
        } catch(e: any) {
            console.log(e)
        }
    }
    const onChangeInputMobile = async (data: any) => {
        // @ts-ignore
        // const res = cities.filter((elem: any) => elem.name.includes(data))
        // if (res.length > 5) setListLocation(res.slice(0, 5))
        // else setListLocation(res)
        setSelectedLocation(null)
        setIsLocation(false)
        try {
            await fetch(`https://api.teleport.org/api/cities/?search=${data}`)
                .then((r: any) => r.json())
                .then((r: any) => setListLocation(r._embedded["city:search-results"].slice(0, 10)))
        } catch(e: any) {
            console.log(e)
        }
    }
    const select = async (data: any) => {
        // const country = new Intl.DisplayNames("en", {type: "region"}).of(data.country)
        // setValue('from', `${data.name}, ${country}`)
        // setSelectedLocation(data)
        // onChangeInput(data.name)
        await fetch(data._links["city:item"]?.href)
            .then((r: any) => r.json())
            .then((r: any) => {
                setSelectedLocation(r)

                setValue('location', `${r.full_name}`)
                setValue('locationMobile', `${r.full_name}`)
                setCurrentActive(0)
                localStorage.setItem('profile_tab', "Travels")
                localStorage.setItem("profile_tab_index", "0")
                setSelectedTab("Travels")
            })
        setListLocation([])
    }
    const checkId = () => {
        if (!isNaN(+forViewId) && location.pathname.slice(1, 6) === 'users') {
            return forViewId
        } else {
            return userId
        }
    }
    const formatDate = (date: any) => {
        const dateUtc = moment.utc(date)
        const messageFromTimeZone = dateUtc.local()
        const local = messageFromTimeZone.format("LL")
        return local
    }
    //NOTIFICATIONS
    const {data: listNotifications, isLoading, refetch } = profileApi.useGetNotificationsQuery(userId)
    const [ deleteNotification ] = profileApi.useDeleteNotificationMutation()
    const [ setNotificationRead ] = profileApi.useSetNotificationsMutation()
    useEffect(() => {
        if (listNotifications?.data) {
            setNotificationPool(listNotifications?.data)
        }
    }, [listNotifications]);
    const [currentLang, setCurrentLang] = useState<string>('1')

    const [ notificationStatus, setNotificationStatus ] = useState<boolean>(false)

    const openNotifications = async () => {
        setNotificationStatus(!notificationStatus)
        if (notificationStatus) {
            await setNotificationRead(checkId())
                .then(() => refetch())
        }
    }

    const goToViewers = () => {
        localStorage.setItem('profile_tab', "Guests")
        localStorage.setItem("profile_tab_index", "4")
        setSelectedTab("Guests")
        setCurrentActive(4)
    }
    const deleteAllNotifications = async () => {
        await deleteNotification(checkId())
            .then(() => SuccessNotify('Notifications deleted'))
            .then(() => setNotificationStatus(false))
            .then(() => refetch())
    }
        useEffect(() => {
            setCount(notificationPool?.filter((item: any) => item?.read_at === null).length)
        }, [notificationPool, refetch]);

    useEffect(() => {
        if (watchLocation?.length === 0) {
            setListLocation([])
        }
    }, [watchLocation, setMobileSearch]);
    if (isLoading) return <span></span>
    return (
        <UI.Content>
            <UI.Container style={{marginTop: 0}}>
                <UI.Panel>
                    <UI.SearchModule>
                        <LocationListTopPanel>
                            <input type={"text"} placeholder={"Choose the country..."}
                                   {...register("location")}
                                   onInput={(e: any) => onChangeInput(e.target?.value)}/>
                            {listLocations.map((item: any, index: number) => {
                                return (
                                    <h5 key={index}
                                        onClick={() => select(item)}>{item?.matching_full_name}</h5>
                                )
                            })}
                        </LocationListTopPanel>
                    </UI.SearchModule>
                    <UI.RightBlock>
                        <UI.BlockNotification onClick={() => openNotifications()}>
                            <img src={"/images/ClearNotifications.svg"} alt={"Notifications"}/>
                            <span>{count}</span>
                            {notificationStatus ?
                            <UI.ListNotificationBlock>
                                {notificationPool.length > 0 ?
                                    <ul>
                                        <h5 onClick={() => deleteAllNotifications()}>Delete all</h5>
                                        {notificationPool.map((item: any, index: any) => {
                                            return (
                                                <li key={index} onClick={() => goToViewers()}>
                                                    <h5>
                                                        <img src={item?.avatar_url || "/images/NoAvatar.png"} alt={"ava"}/>
                                                        {item?.text}</h5>
                                                    <h6>{formatDate(item?.created_at)}
                                                        <UI.ReadNotification
                                                            color={item?.read_at ? "white" : "#FEEF34"}>
                                                        </UI.ReadNotification></h6>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                :
                                <ul>
                                    <li>No notifications</li>
                                </ul>}
                            </UI.ListNotificationBlock>
                                :
                                <></>
                            }
                        </UI.BlockNotification>
                        {/*<UI.BlockLanguage current={currentLang}>*/}
                        {/*    <div onClick={() => setCurrentLang('1')}>*/}
                        {/*        <img src={"/images/Usa.svg"} alt={"usa"}/>*/}
                        {/*    </div>*/}
                        {/*    <div onClick={() => setCurrentLang('2')}>*/}
                        {/*        <img src={"/images/Russia.svg"} alt={"ru"}/>*/}
                        {/*    </div>*/}
                        {/*</UI.BlockLanguage>*/}
                    </UI.RightBlock>
                </UI.Panel>
                <UI.MobilePanel>
                    <div>
                        <img src={profile?.data?.user?.avatar} alt={"A"}/>
                        <h4>{selectedTab}</h4>
                    </div>
                    <div>
                        <UI.BlockNotification style={{border: "none"}} onClick={() => openNotifications()}>
                            <img src={"/images/NotificationMobile.svg"} alt={"Notifications"}/>
                            <span>{count}</span>
                            {notificationStatus ?
                                <UI.ListNotificationBlock style={{right: 5, paddingTop: "10px"}}>
                                    {notificationPool.length > 0 ?
                                        <ul>
                                            <h5 onClick={() => deleteAllNotifications()}>Delete all</h5>
                                            {notificationPool.map((item: any, index: any) => {
                                                return (
                                                    <li key={index} onClick={() => goToViewers()}>
                                                        <h5>
                                                            <img src={item?.avatar_url || "/images/NoAvatar.png"} alt={"ava"}/>
                                                            {item?.text}</h5>
                                                        <h6>{formatDate(item?.created_at)}
                                                            <UI.ReadNotification
                                                                color={item?.read_at ? "white" : "#FEEF34"}>
                                                            </UI.ReadNotification></h6>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                        :
                                        <ul>
                                            <li>No notifications</li>
                                        </ul>}
                                </UI.ListNotificationBlock>
                                :
                                <></>
                            }
                        </UI.BlockNotification>
                        <UI.MobileSearch width={mobileSearch} onClick={() => setMobileSearch(!mobileSearch)}>
                            <input type={"text"}
                                   {...register("locationMobile")}
                                   placeholder={""}
                                   onInput={(e: any) => onChangeInputMobile(e.target?.value)}/>
                            {listLocations.map((item: any, index: number) => {
                                return (
                                    <h5 key={index}
                                        onClick={() => select(item)}>{item?.matching_full_name}</h5>
                                )
                            })}
                        </UI.MobileSearch>
                        {mobileSearch ?
                        <></>
                            :
                            <UI.MobileMenu onClick={() => setShowMobileMenu(!showMobileMenu)}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </UI.MobileMenu>
                        }
                    </div>
                </UI.MobilePanel>
            </UI.Container>
        </UI.Content>
    )
}