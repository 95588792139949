import React, {useEffect} from 'react';
import * as UI from '../../style'
import { useForm } from "react-hook-form";
import {userApi} from "../../services/UserService";

import {MenuItem} from "../../style";
import {ErrorNotify, SuccessNotify} from "../../Notifications";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import {IAuth} from "../../components/ProtectedRoute";
export interface ICheckToken extends IAuth {
    setToken: any
}
export const SignIn = ({isAuth, setToken}: ICheckToken) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [ loginAccount ] = userApi.useLoginMutation();
    const navigate = useNavigate()
    const onSubmit = async (data: any) => {
        try {
            loginAccount(data).then((response: any) => {
                Cookies.set("userId", response?.data?.data.id, {expires: 1})
                setToken(Cookies.get('token_mle'))
                if (response.hasOwnProperty('error')) {
                    ErrorNotify(response.error?.data?.message);
                } else {
                    SuccessNotify(`Welcome`);
                    navigate('/profile')
                }
            })
        } catch (e) {
            ErrorNotify(`${e}`)
        }
    }
    useEffect(() => {
        if (isAuth) {
            navigate('/profile')
        }
    }, [isAuth, navigate])
    return (
        <UI.MainBlockSignIn style={{backgroundImage: 'url(/images/BackgroundAuth.png)'}}>
            <UI.ControlContentBlock>
                <img src={"/images/logo.svg"} alt={"logo"} onClick={() => navigate('/')}/>
                <UI.BlockAuth style={{paddingBottom: "50px"}}>
                    <UI.FormAuth onSubmit={handleSubmit(onSubmit)}>
                        <h2>Login now</h2>
                        <h4>or
                            <UI.MenuItem to={"/register"}> Create your account</UI.MenuItem>
                        </h4>
                        <label>Your e-mail</label>
                        {errors.email && <span>This field is required</span>}
                        <input type="text" {...register("email", { required: true })} />
                        <label>Your password</label>
                        {errors.password && <span>This field is required</span>}
                        <input type="password" {...register("password", { required: true })} />
                        <div style={{display: "flex", justifyContent: "end"}}>
                            <input type="submit" value="Login now" />
                        </div>
                    </UI.FormAuth>
                    <MenuItem to={"/forgot-password"}>do you forget your password?</MenuItem>
                </UI.BlockAuth>
            </UI.ControlContentBlock>
        </UI.MainBlockSignIn>
    )
}