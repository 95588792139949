import React, {useState} from 'react';
import * as PUI from "./ProfileStyles";
import Cookies from "js-cookie";
import moment from "moment";
import {ImageInSlider} from "../../features/Messages/ImageInSlider";
import {checkFormat} from "../../features/Stories";

export const Message = ({message, isFetching, onDelete, indexMessage, setCloseSlider, setSelectedImage}: any) => {
    const userId = Cookies.get("userId") || 0
    const dateUtc = moment.utc(message?.time)
    const messageFromTimeZone = dateUtc.local()
    const format = messageFromTimeZone.format("HH:mm")
    return (
        <PUI.Message key={`${message.id*message.room_id*indexMessage}`} my={+userId === message.user_id}>
            {isFetching ?
                <></>
                :
                <div>
                    {message?.content?.length > 0 ? <h5>{message?.content}</h5> : <></>}
                    {message?.file[0] ?
                        checkFormat(message?.file[0]?.file_url) === 'image' ?
                        <img src={message?.file[0]?.file_url} alt={"image"}
                        onClick={() =>{
                            setSelectedImage(message?.file[0]?.file_url)
                            setCloseSlider((prevState: boolean) => !prevState)
                        }}
                        />
                            :
                            <video src={message?.file[0]?.file_url} onClick={() =>{
                                setSelectedImage(message?.file[0]?.file_url)
                                setCloseSlider((prevState: boolean) => !prevState)
                            }}/>
                    :
                    <></>}
                    <h4 onClick={() => onDelete(message)}>Delete</h4>
                    <h6>{format} {message?.read ? <img src={"/images/Checks.svg"} alt={'Image'}/> : <></>}</h6>
                    {/*{*/}
                    {/*    +messageInDay.data.length - 1 === +indexMessage ?*/}
                    {/*        <img src={*/}
                    {/*            +userId === message.user_id ?*/}
                    {/*                profile?.data?.user?.avatar :*/}
                    {/*                selectedUser?.avatar_url ||"/images/AvatarUser.svg"*/}
                    {/*        } alt={"Avatar"}/>*/}
                    {/*        :*/}
                    {/*        <></>*/}
                    {/*}*/}
                    {/*{*/}
                    {/*    closeSlider ?*/}
                    {/*        <ImageInSlider image={message?.file[0]?.file_url} setCloseSlider={setCloseSlider}/>*/}
                    {/*        :*/}
                    {/*        <></>*/}
                    {/*}*/}
                </div>
            }
        </PUI.Message>
    )
}