import React, {useId, useState} from 'react';
import * as UI from './style';
import {profileApi} from "../services/Profile";
import {IPopUpMedia} from "./PopUpMedia";
import {useForm} from "react-hook-form";
import {CustomInput} from "./CustomInput";
import {DateProfileSelect, RegisterSelect} from "./Select";
import {registerData} from "../other/options";
import * as MUI from "../style"
import {CustomButton} from "./style";
import Cookies from "js-cookie";
import {NotificationResponse} from "../modules/NotificationResponse";
import {CustomNotify, SuccessNotify} from "../Notifications";

export const PopUpSettings = ({setShowModal, showModal, profile, refetch}: IPopUpMedia) => {
    const userId = Cookies.get("userId")
    const [ selectedLocation, setSelectedLocation ] = useState<any>('me')
    const [ listLocations, setListLocation ] = useState<any>([])
    const popUpSettingsFormId = useId()
    const changePasswordFormId = useId()
    const {register, handleSubmit, setValue, watch} = useForm()
    const [statusChangePassword, setStatusChangePassword] = useState<boolean>(false)
    const onChangeInput = async (data: any) => {
        try {
            await fetch(`https://api.teleport.org/api/cities/?search=${data}`)
                .then((r: any) => r.json())
                .then((r: any) => setListLocation(r._embedded["city:search-results"].slice(0, 10)))
        } catch(e: any) {
            console.log(e)
        }
    }
    const select = async (data: any) => {
        await fetch(data._links["city:item"]?.href)
            .then((r: any) => r.json())
            .then((r: any) => {
                setSelectedLocation(r)
                setValue('from', `${r?.full_name}`)
            })
        setListLocation([])
    }

    const [ updateProfile ] = profileApi.useUpdateMainDataMutation()
    const dateToSelect = (type: string) => {
        const date = profile.data.user.birthday.slice(0, 10)
        const findMonth = () => {
            let res: string | undefined = ''
            for (const month of registerData.monthData) {
                // @ts-ignore
                if (Object.keys(month).find((key: any) => month[key] === date.slice(5, 7))) {
                    // @ts-ignore
                    res = Object.keys(month).find((key: any) => month[key] === date.slice(5, 7))
                }
            }
            return res
        }
        switch (type) {
            case 'day':
                return date.slice(8, 10)
            case 'month':
                return findMonth()
            case 'year':
                return date.slice(0, 4)
        }

    }
    const [ updatePassword ] = profileApi.useUpdatePasswordMutation()
    const ChangePasswordModal = () => {
        const newPassword = watch('newPassword')
        const reNewPassword = watch('reNewPassword')
        const currentPassword = watch('currentPassword')

        const passwords = {
            current_password: currentPassword,
            new_password: newPassword,
            new_password_confirmation: reNewPassword }
        const change = async () => {
            await updatePassword({userId, passwords})
                .then((r: any) => {
                    if (r?.data?.success === true) {
                        CustomNotify('Password changed', 'success')
                    } else if(r?.error) {
                        CustomNotify(`${r.error?.data.message}`, 'error')
                    }
                })
                .then(() => setStatusChangePassword(!statusChangePassword))
        }
        return (
            <UI.FormProfileSettings id={changePasswordFormId} onSubmit={handleSubmit(change)}>
                <UI.CustomInputElem>
                    <label>Current password</label>
                    <input type={"password"}
                           placeholder={'****'}
                           {...register('currentPassword')}/>
                </UI.CustomInputElem>
                <UI.CustomInputElem>
                    <label>New password</label>
                    <input type={"password"}
                           placeholder={'****'}
                           {...register('newPassword')}/>
                </UI.CustomInputElem>
                <UI.CustomInputElem>
                    <label>Re new password</label>
                    <input type={"password"}
                           placeholder={'****'}
                           {...register('reNewPassword')}/>
                </UI.CustomInputElem>
                <CustomButton color={"#FEEF34"}
                              style={{float: "left", marginRight: "30px", marginBottom: "30px", marginTop: "20px"}}
                              type={"button"}
                onClick={() => setStatusChangePassword(!statusChangePassword)}>Back</CustomButton>
                <CustomButton color={"#FEEF34"}
                              style={{float: "right", marginBottom: "30px", marginTop: "20px"}}
                              type={"submit"}
                              form={changePasswordFormId}>Update Password</CustomButton>
            </UI.FormProfileSettings>
        )
    }
    const updateSettings = async (data: any) => {
        const monthToNumber = () => {
            let res: any = ''
            for (const item of registerData.monthData) {
                if (Object.keys(item)[0] === data.month) {
                    res = Object.values(item)[0]
                }
            }
            return res
        }
        const date = `${data.year}-${monthToNumber()}-${data.day}`
        const findCountry = selectedLocation.full_name.lastIndexOf(',')
        const finalCountry = selectedLocation.full_name.slice(findCountry+2)
        const updateDataReq = {
            email: data.email,
            name: data.name,
            gender: data.gender,
            birthday: date,
            location: {
                city: selectedLocation.name,
                country: finalCountry,
                latitude: selectedLocation.location.latlon.latitude,
                longitude: selectedLocation.location.latlon.longitude,
            }
        }
        await updateProfile({userId, updateDataReq})
            .then(() => SuccessNotify('Profile updated'))
            .then(() => refetch())
    }

    if (!showModal)  return <></>
    return (
        <UI.PopUpBlock>
            {statusChangePassword ?
                <UI.PopUpContent height={'auto'} style={{width: "528px"}}>
                    <UI.ProfileSettingsContent>
                        {profile?.data.user.avatar ?
                            <img src={
                                profile?.data.user.avatar
                            } alt={'Profile'}/>
                            :
                            <img src={
                                '/images/NoAvatar.png'
                            } alt={'Profile'}/>}
                        <div style={{marginTop: "20px"}}>
                            <h1>Update your password</h1>
                            {/*<h4>You can edit your profile information bellow.</h4>*/}
                        </div>
                        <span onClick={() => setShowModal(!showModal)}>
                    <img src={"/images/CloseSVG.svg"} alt={"close"}/>
                </span>
                    </UI.ProfileSettingsContent>
                    {ChangePasswordModal()}
                </UI.PopUpContent>
                :
            <UI.PopUpContent height={'auto'} style={{width: "528px"}}>
                <UI.ProfileSettingsContent>
                    {profile?.data.user.avatar ?
                        <img src={
                            profile?.data.user.avatar
                        } alt={'Profile'}/>
                        :
                        <img src={
                            '/images/NoAvatar.png'
                        } alt={'Profile'}/>}
                    <div>
                        <h1>Update your profile public information</h1>
                        <h4>You can edit your profile information bellow.</h4>
                    </div>
                    <span onClick={() => setShowModal(!showModal)}>
                    <img src={"/images/CloseSVG.svg"} alt={"close"}/>
                </span>
                </UI.ProfileSettingsContent>
                    <UI.FormProfileSettings id={popUpSettingsFormId} onSubmit={handleSubmit(updateSettings)}>
                        <CustomInput register={register}
                                     label={"E-mail:"}
                                     name={'email'}
                                     data={profile?.data?.user.email}/>
                        <CustomInput register={register}
                                     label={"Name:"}
                                     name={'name'}
                                     data={profile?.data?.user.name}/>
                        <UI.TrashLabel>Birthday: </UI.TrashLabel>
                        <MUI.DateInRegister style={{marginTop: "-10px"}}>
                            <DateProfileSelect
                                register={register} options={registerData.date} name={"day"}
                                data={dateToSelect('day') || 'no data'}/>
                            <DateProfileSelect
                                register={register} options={registerData.month} name={"month"}
                                data={dateToSelect('month') || 'no data'}/>
                            <DateProfileSelect
                                register={register} options={registerData.years} name={"year"}
                                data={dateToSelect('year') || 'no data'}/>
                        </MUI.DateInRegister>
                        <UI.CustomInputElem>
                            <label>{"City: "}</label>
                            <input type="textarea"
                                   onInput={(e: any) => onChangeInput(e.target?.value)}
                                   {...register("from", {
                                       value: `${profile?.data.information.location.city}, ${profile?.data.information.location.country}`
                                   })} style={{marginBottom: "0"}}/>
                        </UI.CustomInputElem>
                        <UI.LocationList>
                            {listLocations.map((item: any, index: number) => {
                                return (
                                    <h5 key={index}
                                        onClick={() => select(item)}>{item?.matching_full_name}</h5>
                                )
                            })}
                        </UI.LocationList>
                        <CustomInput register={register}
                                     label={"Gender:"}
                                     name={'gender'}
                                     disabled={true}
                                     data={profile?.data?.user.gender}/>
                    </UI.FormProfileSettings>
                <CustomButton color={"#FEEF34"}
                              style={{float: "right", marginRight: "30px", marginBottom: "30px"}}
                              type={"submit"}
                              form={popUpSettingsFormId}>Save Changes</CustomButton>
                <UI.FlexBetweenBlock>
                    <UI.CustomButtonBottom type={"button"} color={"var(--eef-0-ed, #EEF0ED)"}>
                        <img src={"/images/EmptyDarkTrash.svg"} alt={"img"}/>
                        Delete profile
                    </UI.CustomButtonBottom>
                    <UI.CustomButtonBottom type={"button"} color={"var(--eef-0-ed, #EEF0ED)"}>
                        <img src={"/images/EmptyDarkBlock.svg"} alt={"img"}/>
                        Blocked profiles
                    </UI.CustomButtonBottom>
                    <UI.CustomButtonBottom type={"button"} color={"var(--eef-0-ed, #EEF0ED)"}
                    onClick={() => setStatusChangePassword(!statusChangePassword)}>
                        <img src={"/images/EmptyDarkLock.svg"} alt={"img"}/>
                        Change password
                    </UI.CustomButtonBottom>
                </UI.FlexBetweenBlock>
            </UI.PopUpContent>
}
        </UI.PopUpBlock>
    )
}