import React, {useState} from 'react';
import * as UI from '../components/Profile/ProfileStyles'
import {ButtonGoTo} from "./Buttons";
import {otherApi} from "../services/Other";
import {Spinner} from "../components/Spinner";
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";

export const BlogList = () => {
    const [ size, setSize ] = useState<number>(5)
    const { data: blogList, isLoading } = otherApi.useGetBlogListQuery(size, {
        refetchOnMountOrArgChange: true
    })
    const navigate = useNavigate()
    if (isLoading) return <Spinner/>
    return (
        <>
            <UI.BlogItems>
                {blogList.data.data.slice(0, 5).map((blog: any, index: number) => {
                    return (
                        <UI.BlogItemForTravel color={blog?.category?.color} text={blog?.category?.color_text} key={index}
                        onClick={() => {
                            Cookies.set("blog_slug", blog?.slug)
                            navigate(`/blogs/${blog.id}`)
                        }}>
                            <img src={"/images/type_travel.svg"} alt={"travel"}/>
                            <h4>
                                <strong>
                                    {blog?.title}
                                </strong>
                            </h4>
                        </UI.BlogItemForTravel>
                    )
                })}
            </UI.BlogItems>
        </>
    )
}