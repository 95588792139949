import React, {Dispatch, useEffect, useState} from 'react';
import * as UI from './style';
import {profileApi} from "../services/Profile";
import Cookies from "js-cookie";
import {SuccessNotify} from "../Notifications";
import {checkFormat} from "./Stories";

export interface IPopUpMedia {
    setShowModal: Dispatch<boolean>,
    showModal: boolean,
    profile?: any,
    isFetching?: any,
    refetch: any,
    showSettings?: boolean
    setShowSettings?: Dispatch<boolean>,
    showPlannedTrips?: boolean,
    setShowPlannedTrips?: Dispatch<boolean>,
    showSpecifyTrips?: boolean,
    setShowSpecifyTrips?: Dispatch<boolean>,
    setSelectedTab?: any,
    userId?: any,
    setShowMedia?: any,
    selectedTab?: any,
    showMobileMenu?: any,
    setShowMobileMenu?: any,
    setShowSendMessage?: any,
    needRefetch?: any,
    setNeedRefetch?: any,
    needRefetchTrips?: any,
    isLoading?: any
}
export const PopUpMedia = ({setShowModal, showModal, profile, refetch}: IPopUpMedia) => {
    const userId = Cookies.get("userId")
    const [ setUploadMedia ] = profileApi.useUploadMediaMutation()
    const [currentIndex, setCurrentIndex ] = useState(-1)
    const [ uploadAvatar ] = profileApi.useUploadAvatarMutation()
    const [ deletePhoto ] = profileApi.useLazyDeletePhotoQuery()
    const [ addToStory ] = profileApi.useLazyAddStoryQuery()
    const uploadMedia = async (files: any) => {
        const formData = new FormData();

        for (const item of files.target.files) {
            formData.append("media[]", item)
        }
        await setUploadMedia({userId, formData})
        refetch()
    }

    const updateAvatar = async (id: number) => {
        await uploadAvatar({userId, id})
            .then(() => SuccessNotify('Avatar updated!'))
        refetch()
    }

    const addStory = async (id: any) => {
        await addToStory({userId: userId, media_id: id})
            .then(() => SuccessNotify('Added to stories'))
        refetch()
    }
    const deleteImage = async (id: number) => {
        await deletePhoto({userId, imageId: id})
            .then(() => SuccessNotify('Photo is deleted'))
        refetch()
    }

    if (!showModal)  return <></>
    return (
        <UI.PopUpBlock>
            <UI.PopUpContent height={profile?.data?.media.length[0] > 0 ? 'auto' : 'default'}>
                <UI.ManageContent>
                    <h1>Manage your content</h1>
                </UI.ManageContent>
                <span onClick={() => setShowModal(!showModal)}>
                    <img src={"/images/CloseSVG.svg"} alt={"close"}/>
                </span>
                <UI.ManageContent>
                    <UI.UploadMediaBlock>
                        <form encType={"multipart/form-data"}>
                            <input type={"file"} placeholder={""} value={""}
                                   multiple={true}
                            onChange={(files: any) => uploadMedia(files)}/>
                        </form>
                        <img src={"/images/GrayUploadImage.svg"} alt={'Upload Image'}/>
                        <button type={"button"}>
                            Add media
                            <img src={"/images/YellowRight.svg"} alt={"Right"} />
                        </button>
                        <h5>We accept photos and videos in JPG, PNG, MP4, OGG and WEBM format.Files do not exceed 32 MB.</h5>
                    </UI.UploadMediaBlock>
                </UI.ManageContent>
                <UI.MediaContentInPopUp>
                    {profile?.data?.media[0].map((item: any, index: number) => {
                        return (
                           checkFormat(item.url) === 'image' ?
                               <UI.ImageInMedia
                                   onMouseMove={() => setCurrentIndex(index)}
                                   onMouseLeave={() => setCurrentIndex(-1)}
                                   key={index} style={{background: `url(${item.url}) center / contain no-repeat`}}>
                                   {currentIndex === index ?
                                       <>
                                           <button type={"button"}
                                                   onClick={() => updateAvatar(item.id)}>
                                               <img src={"/images/DarkProfile.svg"} alt={'Profile'}/>
                                               Profile photo
                                           </button>
                                           <button type={"button"}
                                                   onClick={() => addStory(item.id)}>
                                               <img src={"/images/DarkPlus.svg"} style={{transform: item.is_story ? 'rotate(45deg)' : ''}} alt={'Plus'}/>
                                               {item.is_story ? 'Remove story' : 'Add to story'}
                                           </button>
                                           {item.url === profile?.data.user.avatar ?
                                               <></>
                                               :
                                               <button type={"button"}
                                                       onClick={() => deleteImage(item.id)}>
                                                   <img src={"/images/DarkTrash.svg"} alt={'Trash'}/>
                                                   Delete
                                               </button>
                                           }
                                       </>
                                       :
                                       <></>}
                               </UI.ImageInMedia>
                               :
                               <UI.VideoBlock onMouseMove={() => setCurrentIndex(index)}
                                              onMouseLeave={() => setCurrentIndex(-1)}
                                              key={index}>
                                   <video src={item.url}>
                                   </video>
                                   {currentIndex === index ?
                                       <UI.ContentForVideo>
                                           <button type={"button"}
                                                   onClick={() => updateAvatar(item.id)}>
                                               <img src={"/images/DarkProfile.svg"} alt={'Profile'}/>
                                               Profile photo
                                           </button>
                                           <button type={"button"}
                                                   onClick={() => addStory(item.id)}>
                                               <img src={"/images/DarkPlus.svg"} alt={'Plus'}/>
                                               Add to story
                                           </button>
                                           {item.url === profile?.data.user.avatar ?
                                               <></>
                                               :
                                               <button type={"button"}
                                                       onClick={() => deleteImage(item.id)}>
                                                   <img src={"/images/DarkTrash.svg"} alt={'Trash'}/>
                                                   Delete
                                               </button>
                                           }
                                       </UI.ContentForVideo>
                                   :
                                   <></>
                                   }
                               </UI.VideoBlock>
                        )
                    })}
                </UI.MediaContentInPopUp>
            </UI.PopUpContent>
        </UI.PopUpBlock>
    )
}